import React, { useEffect, useState } from "react";
import "../SocialMedia.css";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { userInfo } from "../../../Atoms";
import like from "../../../assets/Social_Media_Icons/like.svg";
import comment from "../../../assets/Social_Media_Icons/comment.svg";
import share from "../../../assets/Social_Media_Icons/share.svg";
import { BACKEND_URL } from "../../../Utilities/Constant";
import { Atom } from "react-loading-indicators";

const SocialMediaQandA = () => {
  const webApi = new WebApimanager();
  const [currentUserInfo] = useRecoilState(userInfo);
  const [questionAndAnswer, setQuestionAndAnswer] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = currentUserInfo?._id;
        if (userId) {
          const response = await webApi.get(
            `socialMedia/postsByUserId/${userId}?type=Text%20Poll`
          );
          setQuestionAndAnswer(response.data.posts);
        } else {
          console.log("User ID is not available");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false once the fetch is complete
      }
    };

    fetchData();
  }, [currentUserInfo]);

  const formatInstagramTime = (timeStamp) => {
    const currentTime = new Date();
    const postTime = new Date(timeStamp);
    const timeDifference = currentTime - postTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return "Just now";
    } else if (minutes < 60) {
      return `${minutes}m ago`;
    } else if (hours < 24) {
      return `${hours}h ago`;
    } else {
      return `${days}d ago`;
    }
  };

  return (
    <>
      <div>
        {loading ? (
          <Atom
            color="#fc8d05"
            size="medium"
            text="Loading Q&A..."
            textColor="#fff"
          />
        ) : questionAndAnswer.length > 0 ? (
          questionAndAnswer.map((QandA, index) => (
            <div
              key={index}
              className="socilamedia-QandA-header"
              style={{ gap: "20px" }}
            >
              <div className="socialmedia-image-text">
                <img
                  src={
                    QandA.userId.profile
                      ? `${BACKEND_URL}${QandA.userId.profile}`
                      : "https://via.placeholder.com/150"
                  }
                  alt="Profile"
                  className="socialmedia-profile-image-QandA"
                />
                <div className="socialmedia-user-info">
                  <div className="socialmedia-user-name">
                    {QandA.userId.name}
                  </div>
                  <div className="socialmedia-user-time">
                    {formatInstagramTime(QandA.timeStamp)}
                  </div>
                </div>
              </div>
              <div className="socialmedia-user-info-dec">
                <span>{QandA.question}</span>
              </div>
              <div>
                {QandA.type === "Text Poll" && (
                  <div className="poll-container">
                    {QandA.option && QandA.option.length > 0 ? (
                      QandA.option.map((opt, idx) => (
                        <div key={idx} className="poll-option">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <input
                              type="radio"
                              name={`poll-${QandA._id}`}
                              id={`option-${idx}`}
                              value={opt}
                              style={{ marginRight: "10px" }}
                            />
                            <label
                              htmlFor={`option-${idx}`}
                              style={{ flex: 1 }}
                            >
                              {opt}
                            </label>
                            <span>60%</span>
                          </div>
                          <div
                            style={{
                              height: "10px",
                              backgroundColor: "#e0e0e0",
                              borderRadius: "5px",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              style={{
                                width: "60%",
                                height: "100%",
                                backgroundColor: "#1FBFC2",
                              }}
                            ></div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No options available for this poll.</p>
                    )}
                  </div>
                )}
              </div>
              <div className="socialmedia-bottom-icons-QandA">
                <div className="socialmedia-left-icons">
                  <div className="icon-with-text">
                    <img src={like} alt="Like" />
                    <p>120k</p>
                  </div>
                  <div className="icon-with-text">
                    <img src={comment} alt="Comment" />
                    <p>Chat</p>
                  </div>
                  <div className="icon-with-text">
                    <img src={share} alt="Share" />
                    <p>Share</p>
                  </div>
                </div>
                <div className="socialmedia-right-icon">
                  <div className="icon-with-text">
                    <p>1.3M</p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-data-message">
            <img
              src="https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F23c38233cf66491c9332b58fc0cced5c"
              alt="Not Pet Avaliable Data"
              style={{
                width: "200px",
                height: "auto",
                objectFit: "cover",
                margin: "0 auto",
              }}
            />
            <p className="text-center">
              No Q&A data available. Please check back later.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default SocialMediaQandA;
