import React, { useState, useEffect } from "react";
import zaanvarlogo from "../../assets/images/ZAANVAR_FINAL LOGO.png";
import bgimg from "../../assets/images/Rectangle 23.png";
import { useLocation } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";
import { useNavigate } from "react-router-dom";


const ResetPassword = () => {
  let webApi = new WebApimanager();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const [email, setEmail] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location]);

  const validatePassword = () => {
    if (password === "" || confirmPassword === "") {
      setError("Both password fields are required.");
      return false;
    }
    if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return false;
    }
    if (!/\d/.test(password) || !/[A-Za-z]/.test(password)) {
      setError("Password must include both letters and numbers.");
      return false;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return false;
    }
    setError("");
    return true;
  };
  const payload = { email: email, newPassword: password,confirmNewPassword: confirmPassword };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validatePassword()) {
        try {
            const response = await webApi.post("users/resetPassword",payload );

           
                alert("Password reset successful!");
                navigate("/passwordsuccess", { state: { email: email } }); 
        } catch (err) {
            console.error("Error:", err);
        }
    }
};


  return (
    <div className="rp-signmainHeader">
      <div className="rp-containerbody rp-containeronly">
        <div className="rp-container">
          <div className="rp-left-side">
            <div className="rp-zaanvar-icon">
              <img
                src={zaanvarlogo}
                style={{ width: "100px", marginLeft: "90px", marginTop: "-20px" }}
                alt="Logo"
              />
            </div>

            <div style={{ position: "relative", left: "70px" }}>
              <form className="rp-form" onSubmit={handleSubmit}>
                <div>
                  <p style={{ fontSize: "13px" }}>Set a Password</p>
                </div>
                <div className="forgetpass-l1">
                  <label>
                    Please set a new password for your account.
                    <label>We'll ask for this password whenever you log in.</label>
                  </label>
                </div>
                <label
                  style={{
                    marginBottom: "10px",
                    marginTop: "25px",
                    fontSize: "11px",
                  }}
                >
                  Create Password
                </label>
                <input
                  type="password"
                  className="fp-field"
                  placeholder="Enter your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div>
                  <label
                    style={{
                      marginBottom: "10px",
                      marginTop: "5px",
                      fontSize: "11px",
                    }}
                  >
                    Re-enter Password
                  </label>
                  <input
                    type="password"
                    className="fp-field"
                    placeholder="Enter your Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      if (password !== e.target.value) {
                        setError("Passwords do not match.");
                      } else {
                        setError("");
                      }
                    }}
                    required
                  />
                  <div
                    style={{
                      marginTop: "-10px",
                      marginLeft: "70px",
                      marginBottom: "15px",
                    }}
                  ></div>
                </div>
                {error && (
                  <p style={{ color: "red", fontSize: "10px",marginBottom:"0px",marginTop:"-20px" }}>{error}</p>
                )}
                <button
                  type="submit"
                  className="login-button"
                  style={{ marginTop: "8px", borderRadius: "5px" }}
                >
                  SET PASSWORD
                </button>
              </form>
              <div>
                <p
                  style={{
                    fontSize: "10px",
                    paddingTop: "25px",
                    marginBottom: "2px",
                    color: "#616161",
                    marginLeft: "54px",
                  }}
                  className="rp-bottom"
                >
                  Secure password tips:
                </p>
                <label
                  style={{
                    fontSize: "9px",
                    width: "60%",
                    color: "#616161",
                    marginLeft: "54px",
                  }}
                  className="rp-bottom"
                >
                  • Use at least 8 characters, a combination of numbers and letters is best.
                  <label>• Do not use the same password you have used with us previously.</label>
                  <label>• Do not use dictionary words, your name, e-mail address, mobile phone number, or other personal information that can be easily obtained.</label>
                  <label>• Do not use the same password for multiple online accounts.</label>
                </label>
              </div>
            </div>
          </div>

          <div className="rp-img">
            <img src={bgimg} className="fp-fixed-bg-image" alt="Background" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
