import React, { useEffect, useState } from 'react'
import "../profile.css"
import { useRecoilState } from 'recoil'
import { userInfo } from '../../../Atoms'
import { BACKEND_URL } from '../../../Utilities/Constant'
import { Alt } from 'react-bootstrap-icons'
const Account = () => {

  const [curretUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    password: '',
    location: '',
  })

  useEffect(() => {
    console.log("current useinfo account details", curretUserInfo);
    if (curretUserInfo) {
      setFormData({
        name: curretUserInfo.name || '',
        email: curretUserInfo.email || '',
        phoneNumber: curretUserInfo.phoneNumber || '',
        password: '', // Avoid pre-filling sensitive data like passwords
        location: curretUserInfo.location || '',
        profile: curretUserInfo.profile || '',
      })
    }

  }, [curretUserInfo])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <div>

        <div>
          <div className="profile-picture-wrapper">
            <img
              src={
                formData.profile
                  ? `${BACKEND_URL}${formData.profile}`
                  : `https://png.pngtree.com/png-clipart/20210129/ourmid/pngtree-default-male-avatar-png-image_2811083.jpg`
              }
              alt="Profile"
              className="profile-picture"
            />
          </div>

          <div className="account-form-field">
            <label htmlFor="Name" className='account-form-lable'>Name</label>
            <div className="account-input-wrapper">
              <input
                className='account-form-input'
                type="text"
                id="Name"
                name="Name"
                placeholder="Enter Name"
                value={formData.name}
                onChange={handleInputChange}

              />
              <span className="account-edit-icon">
                ✏️
              </span>
            </div>
            <label htmlFor="Email" className='account-form-lable'>Email</label>
            <div className="account-input-wrapper">
              <input className='account-form-input'
                type="email"
                id="Email"
                name="Email"
                value={formData.email}
                placeholder="Enter Email"
              />
              <span className="account-edit-icon">
                ✏️
              </span>
            </div>
            <label htmlFor="phoneNumber" className='account-form-lable'>Phone Number</label>
            <div className="account-input-wrapper">
              <input className='account-form-input'
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Enter Phone Number"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
              <span className="account-edit-icon">
                ✏️
              </span>
            </div>
            <label htmlFor="password" className='account-form-lable'>Password</label>
            <div className="account-input-wrapper">
              <input className='account-form-input'
                type="text"
                id="password"
                name="password"
                placeholder="Enter Password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <span className="account-edit-icon">
                ✏️
              </span>
            </div>
            <label htmlFor="location" className='account-form-lable'>Location</label>
            <div className="account-input-wrapper">
              <input className='account-form-input'
                type="location"
                id="location"
                name="location"
                placeholder="Enter Location"
                value={formData.location}
                onChange={handleInputChange}
              />
              <span className="account-edit-icon">
                ✏️
              </span>
            </div>
          </div>

          {/* {errors.Name && <p className="error-messag    e">{errors.Name}</p>} */}
        </div>

      </div>
    </>
  )
}

export default Account