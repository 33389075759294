import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import TinderRoutes from './TinderRoutes';

const Tinder = () => {
  const location = useLocation();

  if (location.pathname.includes('matches') || location.pathname.includes('edit')) {
    return (
      <TinderRoutes />
    );
  } else {
    return (
      <>
        <Header />
        <TinderRoutes />
        <Footer />
      </>
    );
  }
};

export default Tinder;
