import React, { useState, useRef, useEffect } from "react";
import "../SocialMedia.css";
import { WebApimanager } from "../../../WebApiManager";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { FaImages } from "react-icons/fa";
import { IoArrowBackSharp } from "react-icons/io5";
import { FaMapMarkerAlt } from "react-icons/fa";

const ImagePollModal = ({ isOpen, onClose }) => {
  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState();

  const [modalWidth, setModalWidth] = useState("30%"); // Initial width
  //   const handleNextClick = () => {
  //     setShowForm(true);
  //     setModalWidth("50%");
  //   };

  const webApi = new WebApimanager();
  const [thumbnails, setThumbnails] = useState([]);
  const [caption, setCaption] = useState("");
  // const [location, setTags] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Next");
  const thumbnailsInputRef = useRef(null);

  const [location, setLocation] = useState(""); // Full address

  useEffect(() => {
    return () => {
      thumbnails.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [thumbnails]);

  const handleFileChange = (e) => {
    const { files } = e.target;
    const selectedFiles = Array.from(files).slice(0, 10);

    const filesWithPreview = selectedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    setThumbnails((prevFiles) => [...prevFiles, ...filesWithPreview]);

    thumbnailsInputRef.current.value = null;
  };

  const handleSubmit = async () => {
    if (thumbnails.length === 0) {
      toast.error("Please select at least one image.");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    thumbnails.forEach(({ file }) => formData.append("files", file));
    formData.append("caption", caption);
    formData.append("location", location);
    formData.append("type", "Image");

    try {
      const response = await webApi.imagePost(
        "socialMedia/uploadImages",
        formData
      );

      if (response.status === 201) {
        toast.success("Images uploaded successfully!");
        setThumbnails([]);
        setCaption("");
        setLocation("");
        onClose();
      } else {
        toast.error("Failed to upload images.");
      }
    } catch (error) {
      toast.error("An error occurred while uploading the images.");
    } finally {
      setLoading(false);
    }
  };

  const handleNextClick = () => {
    if (buttonText === "Next") {
      setShowForm(true);
      setModalWidth("50%");
      setButtonText("Save");
    } else if (buttonText === "Save") {
      handleSubmit();
    }
  };
  const fetchLocationDetails = (latitude, longitude) => {
    if (!window.google) {
      toast.error("Google Maps API not loaded.");
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    const latLng = { lat: latitude, lng: longitude };

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        const fullAddress = results[0].formatted_address;
        const addressComponents = results[0].address_components;

        let extractedArea = "";
        let extractedPinCode = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            extractedArea = component.long_name; // Area name
          }
          if (component.types.includes("postal_code")) {
            extractedPinCode = component.long_name; // Pin code
          }
        });

        setLocation(fullAddress);

        toast.success("Location fetched successfully!");
      } else {
        toast.error("Failed to fetch location details.");
      }
    });
  };

  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetchLocationDetails(latitude, longitude);
        },
        (error) => {
          console.error("Error fetching location:", error);
          toast.error("Failed to fetch location. Please try again.");
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };

  const thumbnailCarousel = (
    <div
      id="thumbnailCarousel"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      {/* Indicators */}
      <div className="carousel-indicators">
        {thumbnails.map((_, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#thumbnailCarousel"
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-current={index === 0 ? "true" : "false"}
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>

      <div className="carousel-inner">
        {thumbnails.map(({ preview }, index) => (
          <div
            key={index}
            className={`carousel-item ${index === 0 ? "active" : ""}`}
            style={{ position: "relative" }}
          >
            <img
              src={preview}
              className="d-block"
              alt={`thumbnail-${index}`}
              style={{
                width: "400px",
                height: "300px",
                objectFit: "cover",
                objectPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />

            <div
              style={{
                position: "absolute",
                bottom: "10px",
                left: "10px",
                color: "white",
                fontSize: "18px",
                cursor: "pointer",
              }}
              onClick={() => thumbnailsInputRef.current.click()}
            >
              <input
                type="file"
                name="thumbnails"
                className="form-control"
                accept="image/jpeg, image/png, video/mp4, video/webm, video/ogg"
                multiple
                ref={thumbnailsInputRef}
                onChange={handleFileChange}
                style={{ visibility: "hidden" }}
              />
              <FaImages
                style={{
                  backgroundColor: "black",
                  padding: "5px",
                  color: "white",
                  fontSize: "35px",
                }}
              />
            </div>
          </div>
        ))}
      </div>

      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#thumbnailCarousel"
        data-bs-slide="prev"
        style={{
          height: "50px",
        }}
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#thumbnailCarousel"
        data-bs-slide="next"
        style={{
          height: "50px",
        }}
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );

  useEffect(() => {
    if (isOpen) {
      setShowModal(true);
    } else {
      setTimeout(() => setShowModal(false), 300);
    }
  }, [isOpen]);

  if (!showModal) return null;

  return (
    <>
      <>
        <ToastContainer />
      </>
      <div
        className={`socialmedia-ImagePollModal-header ${isOpen ? "open" : ""}`}
      >
        <div
          className={`socialmedia-ImagePollModal-body ${isOpen ? "open" : ""}`}
          style={{ width: modalWidth }}
        >
          <div className="socialmedia-ImagePollModal-button-container">
            <button className="nav-button left">←</button>
            <button className="nav-button next" onClick={handleNextClick}>
              {buttonText}
            </button>
          </div>

          <div className="socialmedia-ImagePollModal-image-form">
            <div>
              <div className="thumbnail-container">
                {thumbnails.length > 0 ? (
                  thumbnailCarousel
                ) : (
                  <>
                    <div style={{ margin: "0 auto" }}>
                      <input
                        type="file"
                        name="thumbnails"
                        className="form-control"
                        accept="image/jpeg, image/png"
                        multiple
                        ref={thumbnailsInputRef}
                        onChange={handleFileChange}
                        style={{ visibility: "hidden" }}
                      />
                      <img
                        src="https://static.thenounproject.com/png/4595376-200.png"
                        alt="default"
                        onClick={() => thumbnailsInputRef.current.click()}
                        style={{
                          cursor: "pointer",
                          width: "200px",
                          height: "auto",
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Form Section, initially hidden */}
            {showForm && (
              <div
                style={{
                  marginLeft: "20px",
                  marginTop: "20px",
                  transition: "width 0.3s ease",
                }}
              >
                {/* Form with dynamic width */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="mb-3">
                      <label htmlFor="captionInput" className="form-label-text">
                        Add Caption
                      </label>
                      <textarea
                        className="form-control w-100"
                        id="captionInput"
                        rows="3"
                        value={caption}
                        onChange={(e) => setCaption(e.target.value)}
                      ></textarea>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="locationInput" className="form-label">
                        Location
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "400px",
                        }}
                      >
                        <input
                          type="text"
                          id="locationInput"
                          placeholder="Add location (e.g., City: New York)"
                          className="form-control w-100"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        />
                        <FaMapMarkerAlt
                          onClick={handleCurrentLocation}
                          style={{
                            fontSize: "24px",
                            marginLeft: "10px",
                            cursor: "pointer",
                            color: "red",
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ImagePollModal;
