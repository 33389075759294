import React, { useEffect, useState } from "react";
import { WebApimanager } from "../../WebApiManager";
import { BACKEND_URL } from "../../Utilities/Constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./SocialMedia.css";

const SocialMediaSuggestions = () => {
  const webApi = new WebApimanager();
  const [suggestionData, setsuggestionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchSuggestionData = async () => {
      try {
        const response = await webApi.get(`socialMedia/suggestions`);
        const suggestions = response.data.suggestions || [];
        setsuggestionData(suggestions);
        setFilteredData(suggestions);
      } catch (error) {
        console.error("Server error:", error);
        toast.error("Failed to fetch suggestions. Please try again.");
      }
    };

    fetchSuggestionData();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query.trim() === "") {
      setFilteredData(suggestionData);
    } else {
      const filtered = suggestionData.filter((suggestion) =>
        suggestion.userId?.name.toLowerCase().includes(query)
      );
      setFilteredData(filtered);
    }
  };

  const addFollower = async (userId) => {
    if (!userId) {
      toast.warning("User ID not found!");
      return;
    }

    try {
      const response = await webApi.put(`socialMedia/addFollower/${userId}`);
      toast.success("Followed successfully!");
      console.log(response, "suggestion");
    } catch (error) {
      console.error("Error following user:", error);
      toast.error("Failed to follow user. Please try again.");
    }
  };

  return (
    <div className="socialmedia-rightbox">
      {/* Search Section */}
      <div className="socialmedia-search-section">
        <div className="socialmedia-search-container">
          <input
            type="text"
            placeholder="Search here..."
            className="search-input"
            value={searchQuery}
            onChange={handleSearch}
          />
          <i className="fas fa-search search-icon"></i>
        </div>
      </div>

      <div className="socialmedia-right-text">
        <p>Suggested for you</p>
      </div>

      <div className="socialmedia-suggested-list">
        {filteredData.length > 0 ? (
          filteredData.map((suggestion, index) => (
            <div className="socialmedia-follow-list" key={index}>
              <div className="suggestion-horizental-display">
                <img
                  src={
                    suggestion.userId?.profile
                      ? `${BACKEND_URL}${suggestion.userId.profile}`
                      : "https://via.placeholder.com/150"
                  }
                  alt="profile"
                  className="socialmedia-follow-profile"
                />
                <div className="socialmedia-suggested-list-name">
                  <p className="suggested-list-primary">
                    {suggestion.userId?.name || "Unknown User"}
                  </p>
                  <p className="suggested-list-secondary">
                    Followed by {suggestion.followedBy?.join(", ") || "No one"}
                  </p>
                </div>
              </div>
              <div>
                <button
                  className="suggested-follow-title"
                  onClick={() => addFollower(suggestion.userId?._id)}
                >
                  Follow
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No suggestions available</p>
        )}
      </div>

      <hr />

      {/* See All Section */}
      <div className="socialmedia-see-all">
        <p>See All</p>
      </div>
    </div>
  );
};

export default SocialMediaSuggestions;
