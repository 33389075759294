import React, { useState } from "react";
import "./SocialMedia.css";
import { BACKEND_URL } from "../../Utilities/Constant";
import { WebApimanager } from "../../WebApiManager";
import { useRecoilState } from "recoil";
import { userInfo } from "../../Atoms";
import commentBtn from "../../assets/Social_Media_Icons/commentButton.svg";
const SocialMediaImageView = ({
  isModalOpen,
  handleClose,
  imageUrl,
  selectedPost,
  refetchData,
}) => {
  const webApi = new WebApimanager();
  const [currentUserInfo] = useRecoilState(userInfo);
  const [commentMessage, setCommentMessage] = useState("");
  const [isFocused, setIsFocused] = useState(false); // Track focus state

  if (!selectedPost) return null;

  console.log(selectedPost, "selectdcomment");

  const onCommentSend = async () => {
    if (!commentMessage.trim()) {
      alert("Comment cannot be empty");
      return;
    }

    const postId = selectedPost?.postId;
    if (!postId) {
      alert("Post not found!");
      return;
    }

    try {
      const payload = { message: commentMessage };
      await webApi.put(`socialMedia/comment/${postId}`, payload);

      setCommentMessage("");
      handleClose();

      if (refetchData) refetchData();
    } catch (error) {
      console.error("Error sending comment:", error);
    }
  };

  const addFollower = async (userId) => {
    if (!userId) {
      alert("User not found!");
      return;
    }

    try {
      console.log("userId", userId);

      await webApi.put(`socialMedia/addFollower/${userId}`);
      // fetchSocialMediaData();
    } catch (error) {
      console.error("Error following user:", error);
      alert("Failed to follow user. Please try again.");
    }
  };

  const removeFollower = async (userId) => {
    if (!userId) {
      alert("User not found!");
      return;
    }

    try {
      await webApi.delete(`socialMedia/removeFollower/${userId}`);
      // fetchSocialMediaData(); // Refresh the data
    } catch (error) {
      console.error("Error unfollowing user:", error);
      alert("Failed to unfollow user. Please try again.");
    }
  };

  const formatInstagramTime = (timeStamp) => {
    const currentTime = new Date();
    const postTime = new Date(timeStamp);
    const timeDifference = currentTime - postTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return "Just now";
    } else if (minutes < 60) {
      return `${minutes}m ago`;
    } else if (hours < 24) {
      return `${hours}h ago`;
    } else {
      return `${days}d ago`;
    }
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div>
      <div
        className={`socialmedia-comment-modal-overlay ${
          isModalOpen ? "active" : ""
        }`}
        onClick={handleClose}
      >
        <div
          className="socialmedia-comment-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="socialmedia-comment-modal-body">
            <div className="socialmedia-comment-modal-image-container">
              <span
                className="socialmedia-comment-close-btn"
                onClick={handleClose}
              >
                &times;
              </span>
              <img
                src={`${BACKEND_URL}${imageUrl}`}
                alt="Selected"
                className="socialmedia-comment-modal-image"
              />
            </div>

            <div className="socialmedia-comment-modal-details">
              <div className="socialmedia-comment-view-row">
                <div className="socialmedia-user-info-comment-row">
                  <div>
                    <img
                      src={`${BACKEND_URL}${
                        selectedPost?.userId?.profile || "/default-profile.png"
                      }`}
                      alt="Profile"
                      style={{
                        width: "50px",
                        height: "50px",
                        border: "1px solid black",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="socialmedia-comment-user-details-column">
                    <span className="socialmedia-comment-user-details-column-text">
                      {selectedPost.userId?.name || "Anonymous"}
                    </span>
                    <span className="socialmedia-comment-user-details-column-date">
                      {formatInstagramTime(selectedPost.timeStamp)}
                    </span>
                  </div>
                </div>
                <div>
                  <button
                    className="socialmedia-comment-follow-btn"
                    onClick={() => {
                      if (
                        selectedPost.followingStatus === "You are following"
                      ) {
                        removeFollower(selectedPost.userId._id);
                      } else {
                        addFollower(selectedPost.userId._id);
                      }
                    }}
                  >
                    {selectedPost.followingStatus === "You are following"
                      ? "Unfollow"
                      : "Follow"}
                  </button>
                </div>
              </div>

              <div></div>

              <div className="socialmedia-comments-section">
                {selectedPost.comments?.map((comment) => (
                  <div key={comment._id} className="socialmedia-comment">
                    <div className="comment-avatar">
                      <img
                        src={`${BACKEND_URL}${
                          comment?.commentedBy?.profile ||
                          "/default-profile.png"
                        }`}
                        // src={`${BACKEND_URL}${
                        //   comment.commentedBy?.avatar || "/default-profile.png"
                        // }`}
                        // src={`${BACKEND_URL}`
                        //   comment.commentedBy?.avatar || "default-avatar.jpg"
                        // }
                        alt={`${comment.commentedBy?.name}'s avatar`}
                      />
                    </div>
                    <div className="comment-content">
                      <span>
                        <strong>{comment.commentedBy?.name}</strong>{" "}
                        {comment.message}{" "}
                      </span>
                      <span>{formatInstagramTime(comment.timeStamp)}</span>
                    </div>
                  </div>
                ))}
              </div>

              <div className="socialmedia-input-section">
                <input
                  type="text"
                  className="socialmedia-comment-input"
                  placeholder="Add a Comment"
                  value={commentMessage}
                  onChange={(e) => setCommentMessage(e.target.value)}
                  onFocus={handleFocus} // Set focus state to true
                  onBlur={handleBlur} // Set focus state to false
                  style={{
                    borderBottom: isFocused
                      ? "2px solid white"
                      : "2px solid white",
                    backgroundColor: isFocused ? "white" : "white",
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    transition:
                      "border-color 0.3s ease, background-color 0.3s ease", 
                  }}
                />
                <button
                  className="socialmedia-comment-send-btn"
                  onClick={onCommentSend}
                >
                  <img src={commentBtn} alt="comment button" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaImageView;
