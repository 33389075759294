import React, { useState, useRef, useEffect } from 'react';
import styles from './ChatPopup.module.css';
import { BackIconBlack,  CrossIconBlack,  MenuIcon, SendIcon, SentIcon } from '../../../../assets/SVG';
import { ChatMenu } from '../../constants';

const ChatPopup = ({ isChatPopupOpen, setIsChatPopupOpen }) => {
    const popupRef = useRef(null);
    const [isChatscreenOpen, setIsChatscreenOpen] = useState(false)
    const [selectedChat, setSelectedChat] = useState(ChatMenu[0])


    useEffect(() => {
        if (isChatPopupOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
        
        return () => document.body.classList.remove('no-scroll');
    }, [isChatPopupOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsChatPopupOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setIsChatPopupOpen]);

    return (
        <>
            {isChatPopupOpen && 
            
                <div className={styles.Background}>
                    <div className={styles.popupContainer} ref={popupRef}>
                        {isChatscreenOpen ? 
                            <>
                                <div className={styles.chat} style={{padding: '25px'}}>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '15px'}}>
                                        <div style={{cursor: 'pointer'}} onClick={() => setIsChatscreenOpen(false)}><BackIconBlack /></div>
                                            <div style={{display: 'flex', gap: '15px'}}>
                                                {selectedChat.image}
                                                <div>
                                                    <p className={styles.name}>{selectedChat.name}</p>
                                                    <p className={styles.message}>{selectedChat.message}</p>
                                                </div>
                                            </div>
                                    </div>
                                    <div style={{ cursor: 'pointer', position: 'relative' }}>
                                        <div>
                                            <MenuIcon />
                                        </div>
                                        
                                    </div>
                                </div>
                            <div className={styles.mainContainer}>
                                {selectedChat.messages.map(message => {
                                    if (message.type === 'recieved') {
                                        return(
                                            <div className={styles.recievedMessageDiv}>
                                                {selectedChat.image}
                                                <div>
                                                    <div className={styles.recievedMessage}>
                                                        {message.content}
                                                    </div>
                                                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '5px'}}>
                                                        <p style = {{fontSize: '8px', fontWeight: 400, marginBottom: '0px'}}>{message.time}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className={styles.sentMessageDiv}>
                                                <div>
                                                    <div className={styles.sentMessage}>
                                                        {message.content}
                                                    </div>
                                                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '5px'}}>
                                                        <p style = {{fontSize: '8px', fontWeight: 400}}>{message.time}</p>
                                                    </div>
                                                </div>
                                                <SentIcon />
                                            </div>
                                        )
                                    }
                                })}
                                <div className={styles.inputElement}>
                                    <input placeholder='Send Message' />
                                    <div style={{cursor: 'pointer'}}><SendIcon /></div>
                                </div>
                            </div>
                            </> :
                        <>
                            <div className={styles.header}>
                            
                                <p style={{ margin: '0px', marginLeft: '25px' }}>Chat</p>
                                <div style={{marginRight: '25px', cursor: 'pointer'}}  onClick={() =>  {setIsChatPopupOpen(false)}} ><CrossIconBlack/></div>
                            </div>
                            <div className={styles.mainContainer}>
                                {ChatMenu.map((item, index) => {
                                    return(
                                        <div className={styles.chat} key={index} style={{cursor: 'pointer'}} onClick={() => {
                                            setSelectedChat(item)
                                            setIsChatscreenOpen(true)
                                        }}>
                                            <div style={{display: 'flex', gap: '15px'}}>
                                                {item.image}
                                                <div>
                                                    <p className={styles.name}>{item.name}</p>
                                                    <p className={styles.message}>{item?.messages[item?.messages?.length - 1]?.content}</p>
                                                </div>
                                            </div>
                                            <p className={styles.time}>{item.lastSeen}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    }
                    </div>
                </div>
            }
        </>
    );
};

export default ChatPopup;
