import React from 'react'
import styles from "./Grooming.module.css"
import { petMenu,faq } from '../Tinder/constants'
import { useNavigate } from 'react-router'
import GroomingImg from "./GroomingImages/GroomingImg.png"
import GroomingCatImg from "./GroomingImages/GroomingCatImg.png"
import GroomingDogImg from "./GroomingImages/GroomingDogImg.png"
import AddPetImg from "./GroomingImages/AddPetImg.png"
import DateAndTimeImg from "./GroomingImages/DateAndTimeImg.png"
import BookAppointmentImg from "./GroomingImages/BookAppointmentImg.png"
import { GoPlus } from "react-icons/go";
import { IoIosStar } from "react-icons/io";

const Grooming = () => {
    const navigate = useNavigate();
    const onClickFaqFunction = () =>{

    }
  return (
    <>
        <section className={styles.hero}>
          <img src={GroomingImg} alt="Hero background" className={styles.heroBackground} />
          <div className={styles.heroDiv}>
              <h1>Grooming</h1>
              <p>Lorem ipsum dolor sit</p>
              <button onClick={() => navigate('/Grooming/services')}>GET STARTED</button>
          </div>
      </section>
      <section className={styles.PetMenuDiv}>
          <div className={styles.petMenuDiv2}>
            {petMenu.length ? petMenu.map((pet, index) => (
              <button key={index} onClick={pet.onClick} className={styles.petMenuButton}>
                <img src={pet.image} alt={pet.name} className={styles.petImage} />
                {pet.name}
              </button>
            )):""}
          </div>
      </section>
      <section className={styles.GrMidSectionContainer}>
        <div className={styles.PetPicMainContainer}>
          <div className={styles.CatImgContainer}>
          <img src={GroomingCatImg} className={styles.CatImg} alt="Cat" />
          </div>
          <div className={styles.DogImgContainer}>
            <img src={GroomingDogImg} className={styles.DogImg} alt="Dog" />
          </div>
        </div>
        <div className={styles.GrWorkContainer}>
            <h1>How It Works</h1>
            <p className={styles.WorkText}>A team of two professional pet groomers equipped with specialized equipment and products come to your doorstep to provide an amazing pet grooming experience. As part of our pet care service, we ensure the hygiene and cleanliness of your pet without disrupting your daily schedule.</p>
            <div className={styles.WorkDetails}>
              <h4>Equipment</h4>
              <GoPlus />

            </div>
            <div className={styles.WorkDetails}>
              <h4>Key Features</h4>
              <GoPlus />

            </div>
            <div className={styles.WorkDetails}>
              <h4>Additional Details</h4>
              <GoPlus />

            </div>
        </div>
      </section>
      <section className={styles.GrMoreDetails}>
        <h2 className={styles.GrZaanvarText}>Zaanvar is as Easy as 1-2-3!</h2>
        <div className={styles.GrMainCardscontainer}>
            <div className={styles.GrCardsContainer}>
              <img style={{marginTop:"20px"}} src={AddPetImg} alt="addPet" />
              <h5 style={{marginTop:"14px"}}>Add Your  Pet</h5>
              <p style={{color:"#727272"}}>Read verified reviews by pet <br/>
                  owners like you and choose a <br/>
                  sitter who’s a great.</p>
            </div>
            <div className={styles.GrCardsContainer}>
              <img src={DateAndTimeImg}  style={{marginTop:"20px"}} alt="Date&time" />
              <h5 style={{marginTop:"14px"}}>Book Date & Time</h5>
              <p style={{color:"#727272"}}>Read verified reviews by pet <br/>
                  owners like you and choose a <br/>
                  sitter who’s a great.</p>
            </div>
            <div className={styles.GrCardsContainer}>
              <img src={BookAppointmentImg} style={{marginTop:"20px"}} alt="book" />
              <h5 style={{marginTop:"14px"}}>Book Appointment</h5>
              <p style={{color:"#727272"}}>Read verified reviews by pet <br/>
                  owners like you and choose a <br/>
                  sitter who’s a great.</p>
            </div>
        </div>
      </section>
      <section className={styles.GrCustomerDetailsMainContainer}>
        <h3 className={styles.GrZaanvarText}>Happy Customers</h3>
        <div className={styles.GrCustomerContainer}>
          <div  style ={{height:"250px",marginTop:"25px"}} className={styles.GrAboutCustomers}>
            <img src={AddPetImg} style={{marginTop:"-80px",borderRadius:"50%"}} alt="" />
            <h3 style={{marginTop:"10px"}}>Jane</h3>
            <p>Parent of Fluffy</p>
            <p> Trust me when I say this; the service was top-notch, and the groomers were extremely friendly.</p>
            <p  style={{display:"flex"}}><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/></p>
          </div>
          <div style ={{height:"300px"}}className={styles.GrAboutCustomers}>
            <img src={AddPetImg} style={{marginTop:"-110px",borderRadius:"50%"}} alt="" />
            <h3 style={{marginTop:"10px"}}>Jane</h3>
            <p>Parent of Fluffy</p>
            <p> Trust me when I say this; the service was top-notch, and the groomers were extremely friendly.</p>
            <p  style={{display:"flex"}}><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/></p>

          </div>
          <div  style ={{height:"250px",marginTop:"25px"}} className={styles.GrAboutCustomers}>
            <img src={AddPetImg} style={{marginTop:"-80px",borderRadius:"50%"}} alt="" />
            <h3 style={{marginTop:"10px"}}>Jane</h3>
            <p>Parent of Fluffy</p>
            <p> Trust me when I say this; the service was top-notch, and the groomers were extremely friendly.</p>
            <p  style={{display:"flex"}}><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/></p>

          </div>
        </div>

      </section>
      <section className={styles.hero2}>
          <img src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F85691eee07cb49d59a0c7fd710020d44" alt="Hero background" className={styles.heroBackground2} />
          <div className={styles.heroDiv2}>
              <h1>More About Grooming</h1>
              <p>Zaanvar offers convenient and cost-effective pet grooming services right at your doorstep in Hyderabad. We provide complete pet care and our packages include a bath with shampoo and conditioner, full body hair trimming, nail cutting, ear & eye cleaning, tick and flea treatment, and much more. We have specialized teams of dog and cat groomers experienced in handling different breeds. Our groomers ensure your cat and dogs will experience tranquility and luxury in its own environment.</p>
              <p>Our use of premium products and specialized tools underscores our dedication to your pet's well-being. We understand that each breed is unique, which is why we offer tailored "dog grooming" and "cat grooming" packages for every type, we have breed specific experts for all breeds like Labrador Retrievers, Golden Retrievers, Beagles, shihtzu and German Shepherds, as well as Persian cats. Our specialized "shih tzu grooming" ensures your pet receives the best care possible. Elevate your pet's grooming experience by booking a session with Zaanvar today - because your pet deserves nothing but the best, right in the comfort of your own home!.</p>
              <p>At Zaanvar, we prioritize comprehensive "pet care" to ensure your pets are happy, healthy, and well-groomed</p>
          </div>
      </section>
      <section className={styles.faq}>
        <h1>Frequently Asked Questions About Zaanvar</h1>
        {faq.map((faqItem) => {
          return (
            <div className={styles.faqDiv} onClick = {() => onClickFaqFunction(faqItem.id)}>
              <p className={styles.faqDivQuestion}>{faqItem.question}</p>
              {faqItem.visible && <div className={styles.answersDiv}>
                {faqItem.answer.map(answer => {
                  return (
                    <div>
                      <p className={styles.answerTitle}>{answer.title}</p>
                      <p className={styles.answerText}>{answer.text}</p>
                    </div>
                  )
                })}
              </div>}
            </div>
          )
        })}
      </section>
      <section className={styles.connectMenu}>
        <div className={styles.GrconnectMenu2}>
          <h3>Our Community</h3>
          <div className={styles.CommunityContainer}>
            <div className={styles.CommunityDetails}>
              <img src={AddPetImg} alt="" />
              <div className={styles.GrCountContainer}>
                <h1>2741</h1>
                <p>Pets Registered</p>
              </div>
            </div>
            <div className={styles.CommunityDetails}>
              <img src={AddPetImg} alt="" />
              <div className={styles.GrCountContainer}>
                <h1>2741</h1>
                <p>Server Animals</p>
              </div>
            </div>
            <div className={styles.CommunityDetails}>
              <img src={AddPetImg} alt="" />
              <div className={styles.GrCountContainer}>
                <h1>2741</h1>
                <p>Groomers</p>
              </div>
            </div>
            <div className={styles.CommunityDetails}>
              <img src={AddPetImg} alt="" />
              <div className={styles.GrCountContainer}>
                <h1>2741</h1>
                <p>Pet Shops</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Grooming