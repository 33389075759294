import React from 'react';
import styles from './Community.module.css';

const CommunityCard = ({ icon, count, label }) => (
  <div className={styles.communityCard}>
    <img src={icon} alt="" className={styles.communityIcon} />
    <div className={styles.communityInfo}>
      <h3 className={styles.communityCount}>{count}</h3>
      <p className={styles.communityLabel}>{label}</p>
    </div>
  </div>
);

const communityData = [
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/87d2adcb90718b7ade3bffb4f81d86000a32e7066a6b30b9be5249e6e5f15378?apiKey=3e99c58a56f84e4cb0d84873c390b13e&", count: "2781", label: "Pets Registered" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e5e4bac5a76088c0e6a89bbc38f81aeadb0cf4608f1c403a95b265102bebe697?apiKey=3e99c58a56f84e4cb0d84873c390b13e&", count: "1032", label: "Server Animals" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3d7511943f786addd10e03d01d2f5af7d6b80dffaa1b723dd77868836dd9630e?apiKey=3e99c58a56f84e4cb0d84873c390b13e&", count: "69", label: "Pet Friendly Places" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/74bfa41487a3f17d8e58504bcecee07ebe7ced0d4cc8f84730326efae5b2c843?apiKey=3e99c58a56f84e4cb0d84873c390b13e&", count: "965", label: "Pet shops" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1d8b5b592d966d44c5200fb87036fc2f332dd9619cd9867b1fe27eeb7d969434?apiKey=3e99c58a56f84e4cb0d84873c390b13e&", count: "271", label: "NGO's" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/fb4f407fbe1ab1d4eacb7f011fc3fd684b26201198ffe9727fde76ddf268bd3c?apiKey=3e99c58a56f84e4cb0d84873c390b13e&", count: "108", label: "Animal Trainers" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0df4e7be9cd1ef05f85a830d0508d08e2f065c44462d3a9ffb64555335fb3813?apiKey=3e99c58a56f84e4cb0d84873c390b13e&", count: "219", label: "Groomers" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5e9aada6bf3cb5f8d7d3da8f826cf33fdf24b480fcf6ed5aa4c50e7545fef848?apiKey=3e99c58a56f84e4cb0d84873c390b13e&", count: "148", label: "Pet Hostels" }
];

const Community = () => (
  <section className={styles.community}>
    <h2 className={styles.sectionTitle}>Our Community</h2>
    <div className={styles.communityGrid}>
      {communityData.map((item, index) => (
        <CommunityCard key={index} {...item} />
      ))}
    </div>
  </section>
);

export default Community;