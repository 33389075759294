import React, { useState, useEffect } from "react";
import styles from "./LostAndFoundHomePage.module.css";
import CustomRadioButton from "../../UI/CustomRadioButton";
import CustomInputElement from "../../UI/CustomInputElement";
import CustomInputElementColor from "../../UI/CustomInputElementColor";
import DropDownV1 from "../../UI/DropDownv2";
import LostAndFoundCards from "./LostAndFoundCards";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import closeimg from "../../../assets/images/close.png";
import msgimg from "../../../assets/images/msgicon-1.png";
import { useNavigate } from "react-router";
import FoundPetDetails from "./FoundPetDetails";
import ChatPage from "./ChatPage";
import SingleChatPage from "./SingleChatPage";
import { WebApimanager } from "../../../WebApiManager";

const LostAndFoundHomePage = () => {
  const webApi = new WebApimanager();
  const [petID, setPetID] = useState("");
  const [selectedRadioOption, setSelectedRadioOption] = useState("");
  const [petType, setPetType] = useState("Select");
  const [petname, setPetname] = useState("Select");

  const [breedType, setBreedType] = useState("Select");
  const [petColor, setPetColor] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedPet, setSelectedPet] = useState(null);
  const closePopup = () => setIsPopupOpen(false);
  const [isChatPageOpen, setIsChatPageOpen] = useState(false);
  const [showMyPosts, setShowMyPosts] = useState(false);
  const [petData, setPetData] = useState([]);
  const [breedNames, setBreedNames] = useState([]);
  const [breedTypes, setBreedTypes] = useState([]);

  const handleCardClick = (pet) => {
    setSelectedPet(pet);
    setIsPopupOpen(true);
  };

  const [filters, setFilters] = useState({
    petType: "Select",
    petname: "Select",
    petID: "",
    breedType: "Select",
    petColor: [],
    selectedRadioOption: "",
  });

  const navigator = useNavigate();
  const handlePetIDChange = (value) => {
    const upperCaseValue = value.toUpperCase();
    setPetID(upperCaseValue);
    setFilters((prevFilters) => ({
      ...prevFilters,
      petID: upperCaseValue,
    }));
  };

  const handleRadioChange = (option) => {
    setSelectedRadioOption(option);
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedRadioOption: option,
    }));
  };

  const clearAllFilters = () => {
    setPetID("");
    setSelectedRadioOption("");
    setPetType("Select");
    setPetname("Select");
    setBreedType("Select");
    setPetColor([]);
    setFilters({
      petType: "Select",
      petname: "Select",
      breedType: "Select",
      petColor: [],
      petID: "",
      selectedRadioOption: "",
    });
  };

  const clearFilter = (filterName) => {
    const updatedFilters = {
      ...filters,
      [filterName]:
        filterName === "selectedRadioOption" || filterName === "petID"
          ? ""
          : "Select",
    };

    if (filterName === "selectedRadioOption") setSelectedRadioOption("");
    if (filterName === "petType") setPetType("Select");
    if (filterName === "petname") setPetname("Select");

    if (filterName === "petID") setPetID("");
    if (filterName === "breedType") setBreedType("Select");
    if (filterName === "petColor") {
      setPetColor([]);
      updatedFilters.petColor = [];
    }

    setFilters(updatedFilters);
  };

  const mypost = () => {
    setShowMyPosts(true);
    navigator("/lost-and-found/HomePage/myPost", {
      state: { showMyPosts: true },
    });
  };

  const hasFilters =
    filters.selectedRadioOption ||
    filters.petType !== "Select" ||
    filters.petname !== "Select" ||
    filters.breedType !== "Select" ||
    filters.petID !== "" ||
    filters.petColor.length > 0;

  const [isChatPopupOpen, setIsChatPopupOpen] = useState(false);

  const closeChatPopup = () => setIsChatPopupOpen(false);

  const handleMessageClick = () => {
    setIsPopupOpen(false);
    setIsChatPopupOpen(true);
  };


  useEffect(() => {
    webApi
      .get("lostAndFound/allPets")
      .then((response) => {
        const responseData = response.data;
        const foundPets = responseData.data.foundPets
        ? responseData.data.foundPets.filter(
            pet => pet.petStatus === "Found" || pet.petStatus === "Reunited"
          )
        : [];
  
      const lostPets = responseData.data.lostPets

        ? responseData.data.lostPets.filter(
            pet => pet.petStatus === "Lost" || pet.petStatus === "Reunited"
          )
        : [];
        const allPets = [...foundPets, ...lostPets];
        console.log(allPets,"found pet dataaaa")
      
        setPetData(allPets);
        const uniquePetTypes = [...new Set(allPets.map((pet) => pet.petType))];
        setBreedTypes(uniquePetTypes);
        setBreedNames(allPets.map((pet) => pet.breed));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleSelectChange = (name, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    if (name === "petname") {
      setPetname(value);
      const filteredBreeds = petData
        .filter((pet) => pet.petType === value)
        .map((pet) => pet.breed);
      const uniqueBreeds = [...new Set(filteredBreeds)];
      setBreedNames(uniqueBreeds);
    } else if (name === "petType") {
      setPetType(value);
    }
  };

  return (
    <>
      <section className={styles.LostAndFoundHomePage}>
        <div>
          <Header />
        </div>
        <div className={styles.sortDiv}>
          <div style={{paddingRight: "60px",borderRight: "1px solid #F0F1F6",}} id="lost-and-found-sort">
            <h1>Sort By</h1>
          </div>
          <div style={{ display: "flex" }}>
            {filters.selectedRadioOption && (
              <div> 
                <p id="lost-found-selecedoption">
                  {filters.selectedRadioOption}
                  <span style={{ marginTop: "9px", marginLeft: "8px" }} onClick={() => clearFilter("selectedRadioOption")}>
                    <img src={closeimg} alt="close" />
                  </span>
                </p>
              </div>
            )}
            {filters.petType !== "Select" && (
              <div>
                <p id="lost-found-selecedoption">
                  {filters.petType}
                  <span style={{ marginTop: "9px", marginLeft: "8px" }} onClick={() => clearFilter("petType")}>
                    <img src={closeimg} alt="close" />
                  </span>
                </p>
              </div>
            )}
            {filters.petID !== "" && (
              <div>
                <p id="lost-found-selecedoption">
                  {filters.petID}
                  <span style={{ marginTop: "9px", marginLeft: "8px" }} onClick={() => clearFilter("petID")}>
                    <img src={closeimg} alt="close" />
                  </span>
                </p>
              </div>
            )}
            {filters.petname !== "Select" && (
              <div>
                <p id="lost-found-selecedoption">
                  {filters.petname}
                  <span style={{ marginTop: "9px", marginLeft: "8px" }} onClick={() => clearFilter("petname")}>
                    <img src={closeimg} alt="close" />
                  </span>
                </p>
              </div>
            )}
            {filters.breedType !== "Select" && (
              <div>
                <p id="lost-found-selecedoption">
                  {filters.breedType}
                  <span style={{ marginTop: "9px", marginLeft: "8px" }} onClick={() => clearFilter("breedType")}>
                    <img src={closeimg} alt="close" />
                  </span>
                </p>
              </div>
            )}
          {petColor.length > 0 && (
  petColor.map((color, index) => (
    <div  key={index}>
      <p id="lost-found-selectedoption" style={{
            width: "auto",
            height: "39px",
            top: "143px",
            left: "245px",
            gap: "0px",
            borderRadius: "25px 25px 25px 25px",
            background: "#e1f4f8",
            textAlign: "center",
            color: "#61677f",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
            paddingTop: "5px",
            display: "fle",
            margin: "10px",
            padding: "10px",
            paddingLeft: "25px",
            paddingRight: "25px",
            paddingBottom: "36px",
            display:"flex"
      }}>
        {color}
        <span
          style={{ marginTop: "9px", marginLeft: "8px" }}
          onClick={() => {
            // Remove this color from petColor
            const updatedColors = petColor.filter((_, i) => i !== index);
            setPetColor(updatedColors);
            handleSelectChange("petColor", updatedColors);
          }}
        >
          <img src={closeimg} alt="close" />
        </span>
      </p>
    </div>
  ))
)}

          </div>

          {hasFilters && (
            <button className={styles.clearButton} onClick={clearAllFilters}>
              Clear All
            </button>
          )}
        </div>

        <div style={{ display: "flex" }}>
          <div className={styles.filtersDiv}>
            <CustomRadioButton question={"Select Lost/Found"} options={["Lost", "Found", "Reunited"]} value={selectedRadioOption} onChange={handleRadioChange} />
            <DropDownV1 question={"Select Pet Type"} options={breedTypes} value={petname} width="100%" onChange={(value) => {  handleSelectChange("petname", value);  }} />
            <DropDownV1 question={"Select Breed"} options={breedNames} value={petType} width="100%" onChange={(value) => { handleSelectChange("petType", value); }} />
            <CustomInputElement question={"Pet ID"} placeholder={"Search here"} value={petID} width="94%" onChange={handlePetIDChange} />
            <CustomInputElementColor  question={"Pet Colour"} value={petColor} width="94%" onChange={(newColors) => { setPetColor(newColors);  handleSelectChange("petColor", newColors); }} />
          </div>
          <div className={styles.cardlistDiv}>
            <div style={{ display: "flex" }}>
              <p className={styles.lostfoundsearch}>
                {selectedRadioOption ? `${selectedRadioOption} Pets in the Locality` : "Lost and Found Pets in the Locality"}
              </p>
              <div className={styles.lostAndFoundNewPost} onClick={mypost}>
                My Post
              </div>
              <div className={styles.lostAndFoundMsg} onClick={() => setIsChatPageOpen(true)}>
                <img src={msgimg} alt="Messages" />
              </div>
            </div>
            <LostAndFoundCards filters={filters} onCardClick={handleCardClick} onChatClick={() => setIsChatPopupOpen(true)} showMyPosts={showMyPosts}/>
            <FoundPetDetails isOpen={isPopupOpen} onClose={closePopup} pet={selectedPet} onMessageClick={handleMessageClick} />
            {isChatPageOpen && (
              <ChatPage isChatPopupOpen={isChatPageOpen} setIsChatPopupOpen={setIsChatPageOpen} onClose={() => setIsChatPageOpen(false)} />
            )}
            <SingleChatPage isOpen={isChatPopupOpen} onClose={closeChatPopup} ></SingleChatPage>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </section>
    </>
  );
};

export default LostAndFoundHomePage;
