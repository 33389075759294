import React, { useState } from "react";
import styles from './ui.module.css';

const CustomInputElementnumber = ({
  question,
  width = '45%',
  backgroundColor = 'rgba(243, 243, 243, 0.50)',
  placeholder,
  value,
  onChange,
  CustomInputElementepdate,
  CustomInputElementmargin,
  maxLength,
  minValue,
  maxValue
}) => {
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Allow only numeric input (block any non-numeric characters)
    if (/^[0-9]*$/.test(inputValue)) {
      // Handle year input: restrict to maxLength (4 digits max for year)
      if (maxLength && inputValue.length > maxLength) {
        setError(`Input should be at most ${maxLength} characters long.`);
        return; // Prevent state change if input exceeds maxLength
      }
      
      // Handle month input: restrict to values between minValue and maxValue (1 to 12 for months)
      if (minValue && Number(inputValue) < minValue) {
        setError(`Input should be greater than or equal to ${minValue}.`);
        return; // Prevent state change if input is less than minValue
      } else if (maxValue && Number(inputValue) > maxValue) {
        setError(`Input should be less than or equal to ${maxValue}.`);
        return; // Prevent state change if input is greater than maxValue
      } else {
        setError(''); // Clear error if input is valid
      }
      
      onChange(inputValue); // Update the value when input is valid
    } else {
      setError("Please enter a valid number.");
      onChange(''); // Optionally clear the input if invalid
    }
  };

  return (
    <div
      className={styles.inputDiv}
      style={{
        flex: width === '94%' ? `1 1 ${width}` : '',
        width: CustomInputElementepdate?.width || width,
        padding: CustomInputElementepdate?.padding || "20px 20px 23px 42px",
        borderBottom: CustomInputElementepdate?.borderBottom || "1px solid #F0F1F6",
        marginTop: CustomInputElementmargin?.marginTop || "0px",
        marginBottom: CustomInputElementepdate?.marginBottom,
      }}
    >
      <p style={{ fontSize: CustomInputElementepdate?.fontSize }}>{question}</p>
      <input
        type="number"
        style={{
          backgroundColor: backgroundColor,
          border: "1px solid #D9D9D9",
          boxShadow: "none", 
          
        }}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
      {error && <div style={{ color: 'red', fontSize: '12px' }}>{error}</div>}
    </div>
  );
};

export default CustomInputElementnumber;
