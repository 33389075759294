import React, { useState, useRef, useEffect } from "react";
import styles from "./NotCompletedPopup.module.css";
import { CrossIconBlack, CameraIcon } from "../../../../assets/SVG";
// import { NC } from "../../constants";
import { WebApimanager } from "../../../../WebApiManager";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomTextareaComponent from "../../../UI/CustomTextAreaComponent";

const NC = [
  {
    heading: "Boost Your Pet’s Profile!",
    text: `Boost your pet's profile by obtaining a "verified" tag through the profile verification process.`, 
    status: "completed",
  },
  {
    heading: "Enable “Pedigree Certified” Tag",
    text: `Boost credibility by uploading your pet's pedigree document.`,
    status: "completed",
  },
  {
    heading: "Enable “DNA Tested” Tag",
    text: `Boost credibility by uploading your pet's DNA test results.`,
    status: "completed",
  },
  {
    heading: "Pet profile Information",
    text: `Share additional information about your pet.`,
    status: "pending",
  },
  {
    heading: "Detailed profile Description",
    text: "Give a detailed description of your pet, including their personality traits, favorite activities, and other key details, in at least 300 characters.",
    status: "pending",
  },
  {
    heading: "Add more photos of your pet",
    text: "Select a photo that best reflects your pet's personality to use as their profile picture.",
    status: "pending",
  },
  {
    heading: "Add more videos of your pet",
    text: "Post a video to offer a vibrant glimpse of your pet's daily routine, special tricks, or playful behavior.",
    status: "pending",
  },
];

const NotCompletedPopup = ({
  isNCPopupOpen,
  setIsNCPopupOpen,
  setIsUpdatePopupOpen,
  setPhotoUrl,
  setClipUrl,
  petData,
}) => {
  const popupRef = useRef(null);
  const [isUploadpopOpen, setIsUploadPopupOpen] = useState(false);
  const webApi = new WebApimanager();

  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const [videoURL, setVideoURL] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [detailedprofile, setDetailedprofile] = useState(false);
  const [addmorephotos, setAddmorephotos] = useState(false);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    webApi
      .get("petProfile/visiblePets")
      .then((response) => {
        const responseData = response.data;
      })
      .catch((error) => {
        console.error("Error fetching My data:", error);
      });
  }, []);

  useEffect(() => {
    if (isNCPopupOpen) {
      console.log("Popup is open, petData:", petData);
      if (petData) {
        console.log("Pet data available:", petData);
      } else {
        console.log("Pet data is not available.");
      }
    }
  }, [isNCPopupOpen, petData]);

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
      videoRef.current
        .play()
        .then(() => console.log("Video playing"))
        .catch((err) => console.error("Error playing video:", err));
    }
  }, [stream]);

  const startCamera = async () => {
    if (isCameraOpen || stream) {
      console.log("Camera is already started.");
      return;
    }
    try {
      resetAfterRecording();
      const userStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setStream(userStream);
      setIsCameraOpen(true);
    } catch (err) {
      console.error("Error accessing webcam:", err);
      alert("Unable to access the camera. Please check your permissions.");
    }
  };

  const captureImage = () => {
    if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current;
      const video = videoRef.current;
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const capturedImage = canvas.toDataURL("image/png");
      console.log(capturedImage, "checking capture image");
      const dataURLtoFile = (dataURL, filename) => {
        const arr = dataURL.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      };
      const imageFile = dataURLtoFile(capturedImage, "captured-image.png");
      console.log(imageFile, "checking image file");
      setImageSrc(imageFile);
      console.log(imageSrc, "checking capture image src");
      stopCamera();
      return imageFile;
    } else {
      console.error("Canvas or video element not available.");
    }
  };

  const recordedChunksRef = useRef([]);
  const startRecording = () => {
    console.log("Starting recording...");

    if (!mediaRecorder && stream) {
      recordedChunksRef.current = [];

      const recorder = new MediaRecorder(stream);

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };
      recorder.onstop = () => {
        console.log("Recording stopped");
        const blob = new Blob(recordedChunksRef.current, { type: "video/mp4" });

        if (blob.size === 0) {
          console.error("Recording failed. Blob is empty.");
          return;
        }

        const url = URL.createObjectURL(blob);
        console.log(url, "Checking the URL");
        setVideoBlob(blob);
        setVideoURL(url);
      };
      console.log(videoURL, "checking videoURL ");
      recorder.start();
      setMediaRecorder(recorder);

      console.log("Recording started");
    } else {
      console.error(
        "MediaRecorder is already running or stream is not available."
      );
    }
  };
  const [cameraStopped, setCameraStopped] = useState(false);

  const stopCamera = () => {
    console.log("Stopping camera...");

    if (stream) {
      stream.getTracks().forEach((track) => {
        console.log(`Stopping track: ${track.kind}, Enabled: ${track.enabled}`);
        track.stop(); 
        console.log(`Track stopped: ${track.readyState}`);
      });

      if (videoRef.current) {
        videoRef.current.srcObject = null; 
        console.log("Video source cleared.");
      }

      setStream(null);
      console.log("Stream reference cleared.");

      setIsCameraOpen(false); 
      console.log("Camera state set to closed.");
      setCameraStopped(true);
    }
  };

  useEffect(() => {
    console.log("Stream updated:", stream);
    console.log("Camera state updated:", isCameraOpen);
  }, [stream, isCameraOpen]);

  const stopRecording = () => {
    console.log("Stopping recording...");
    if (mediaRecorder) {
      mediaRecorder.stop();
      console.log("MediaRecorder stopped");
      setMediaRecorder(null); 
    }

    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
        console.log(`Track stopped: ${track.kind}`);
      });
      setStream(null);
    }

    setIsCameraOpen(false); 
    setRecordedChunks([]); 
  };

  const handleSubmit = async () => {
    console.log("Submitting...");
    if (imageSrc) {
      console.log("Submitted image:", imageSrc);
      setPhotoUrl(imageSrc);
    }
    if (videoBlob) {
      console.log("Submitting video blob...");
    }

    const formData = new FormData();
    formData.append("livePet", videoBlob || imageSrc);

    console.log("Payload being sent:", formData);
    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });

    try {
      const response = await webApi.imagePut(
        `petProfile/updateLivePhoto/${petData._id}`,
        formData
      );

      console.log(response, "response");

      toast.success("vaccineCertificate Successfully!");
    } catch (err) {
      console.error("Error occurred:", err);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }

    stopRecording();

    stopCamera();
    setIsUploadPopupOpen(false);
    setVideoURL(null);
    setImageSrc(null);
    recordedChunksRef.current = [];
  };

  const resetAfterRecording = () => {
    console.log("Resetting after recording...");
    setVideoURL(null);
    setImageSrc(null);
    stopCamera();
    recordedChunksRef.current = [];
    setMediaRecorder(null);
  };

  const [pedigreeCertificate, setpedigreeCertificate] = useState(false);
  const [DNACertificate, setDNACertificate] = useState(false);
  const [livepet, setLivePet] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDNAFile, setSelectedDNAFile] = useState(null);

  console.log(selectedFile, "selected file");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };
  const handleDNAFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedDNAFile(file);
    }
  };

  const PedigreeCertificate = async () => {
    if (!selectedFile) {
      toast.error("Please select a Pedigree Certificate file.");
      return;
    }
    const payloadimg = {
      pedigreeCertificate: selectedFile,
    };
    console.log(payloadimg, "checking payloadimg");

    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });

    try {
      const response = await webApi.imagePut(
        `petProfile/updatePedigreeCertificate/${petData._id}`,
        payloadimg
      );

      console.log(response, "responseresponse");

      toast.success("vaccineCertificate Successfully!");
      window.location.reload();
      setIsUploadPopupOpen(false);
    } catch (err) {
      console.error("Error occurred:", err);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const HandleDNACertificate = async () => {
    if (!selectedDNAFile) {
      toast.error("Please select a Pedigree Certificate file.");
      return;
    }

    const payloadimg = {
      DNACertificate: selectedDNAFile,
    };
    console.log(payloadimg, "checking payloadimg");

    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });

    try {
      const response = await webApi.imagePut(
        `petProfile/updateDNACertificate/${petData._id}`,
        payloadimg
      );

      console.log(response, "responseresponse");

      toast.success("vaccineCertificate Successfully!");
      setIsUploadPopupOpen(false);
    } catch (err) {
      console.error("Error occurred:", err);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
    setIsUploadPopupOpen(false);
  };

  const [selectedImages, setSelectedImages] = useState([]);

  const [images, setImages] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    const newImages = files.map((file) => URL.createObjectURL(file));

    setImages((prevImages) => [...prevImages, ...newImages]);
    setFileNames((prevFileNames) => [...prevFileNames, ...files]);
  };

  const handleCancel = (index) => {
    setImages((prevImages) => {
      const updatedImages = prevImages.filter((_, i) => i !== index);
      console.log("Updated Images after cancel:", updatedImages);
      return updatedImages;
    });
  };

  const handlepostimg = async () => {
    const imgpayload = new FormData();

    fileNames.forEach((file) => {
      imgpayload.append("morePhotos", file);
    });

    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });

    try {
      if (fileNames.length > 0) {
        const imageUploadResponse = await webApi.imagePut(
          `petProfile/updateMorePhotos/${petData._id}`,
          imgpayload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (imageUploadResponse.status === 400) {
          if (imageUploadResponse.data && imageUploadResponse.data.message) {
            setErrorMessage(imageUploadResponse.data.message);
          }
          setApiProcessing({ loader: false, message: "" });
        } else {
          setErrorMessage("An unexpected error occurred.");
          setApiProcessing({ loader: false, message: "" });
        }

        toast.success("Successfully!");
        setIsUploadPopupOpen(false);
        setpedigreeCertificate(false);
        setLivePet(false);
        setDetailedprofile(false);
        setAddmorephotos(false);

        setDNACertificate(false);
      } else {
        setErrorMessage("No files selected.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (err) {
      console.error("Error occurred:", err);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  const [addMoreVideos, setAddMoreVideos] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [fileNamess, setFileNamess] = useState([]);
  const [videoNames, setVideoNames] = useState([]);
  const handleVideoChange = (e) => {
    const files = Array.from(e.target.files);
    const newVideos = files.map((file) => URL.createObjectURL(file));

    setVideos((prevVideos) => [...prevVideos, ...newVideos]);
    setVideoNames((prevVideoNames) => [...prevVideoNames, ...files]);
  };

  const handleCancel2 = (index) => {
    setVideos((prevVideos) => {
      const updatedvideo = prevVideos.filter((_, i) => i !== index);
      console.log("Updated Images after cancel:", updatedvideo);
      return updatedvideo;
    });
  };

  const handlepostvideo = async () => {
    console.log("sonu cliked");
    const imgpayload = new FormData();

    videoNames.forEach((file) => {
      imgpayload.append("moreVideos", file);
    });

    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });

    try {
      if (videoNames.length > 0) {
        const imageUploadResponse = await webApi.imagePut(
          `petProfile/updateMoreVideos/${petData._id}`,
          imgpayload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (imageUploadResponse.status === 400) {
          if (imageUploadResponse.data && imageUploadResponse.data.message) {
            setErrorMessage(imageUploadResponse.data.message);
          }
          setApiProcessing({ loader: false, message: "" });
        } else {
          setErrorMessage("An unexpected error occurred.");
          setApiProcessing({ loader: false, message: "" });
        }

        toast.success("Successfully!");
        setIsUploadPopupOpen(false);
        setpedigreeCertificate(false);
        setLivePet(false);
        setDetailedprofile(false);
        setAddmorephotos(false);

        setDNACertificate(false);
      } else {
        setErrorMessage("No files selected.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (err) {
      console.error("Error occurred:", err);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const fieldsToCheck = [
    petData.petNeed,
    petData.typeOfBreed,
    petData.pedigreeCertified,
    petData.vaccinated,
    petData.DNATested,
    petData.breedingForTheFirstTime,
    petData.isThePetMicroChipped,
    petData.size,
    petData.isYourPetGoodWithKids,
    petData.isYourPetGoodWithCats,
    petData.isYourPetGoodWithDogs,
    petData.isYourPetTrained,
    petData.instagramLink,
    petData.aboutYourPet,
    petData.willingToTravel,
    petData.breedingTerms,
    petData.accommadationResponsibility,
    petData.haveYouConsultedWithVeterinationAboutBreeding,
    petData.doYouHavePlanForCaringAndPlacingPuppies,
    petData.doesYourPetHasAnyHealthIssues,
    petData.hasYourPetBeenTestedForAnyBreedSpecificHealthConditionsOrGeneticDisOrders,
    ,
    petData.areYouFamiliarWithTheBreedStandardAndAnyGeneticHealthIssuesAssosiatedWithThisBreed,
    petData.isYourPetUpToDateOnVaccinationsAndPreventiveCare,
    petData.areYouWillingToSignABreedingContractWithTheOtherPetOwner,
    petData.morePhotos,
  ];

  const result = fieldsToCheck.every((value) => value) ? "ok" : null;

  const calculatePercentage = (fields) => {
    const totalFields = fields.length;
    const weightPerField = 100 / totalFields;

    const validFields = fields.filter((field) => {
      if (Array.isArray(field)) {
        return field.length > 0;
      }
      return field && field !== "";
    }).length;

    const totalPercentage = validFields * weightPerField;

    return totalPercentage.toFixed(2);
  };
  const fields = [
    petData.livePet,
    petData.pedigreeCertificate,
    petData.DNACertificate,
    petData.morePhotos,
    petData.moreVideos,
    petData.description,
    petData.petNeed,
    result,
  ];

  const percentage = calculatePercentage(fields);
  const [description, setDescription] = useState("");
  const handleSubmitdescription = async () => {
    console.log(description);
    const payload = {
      description: description,
    };

    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });

    try {
      const response = await webApi.put(
        `petProfile/update/${petData._id}`,
        payload
      );

      console.log(response, "responseresponse");

      toast.success("Successfully!");
      setIsUploadPopupOpen(false);
    } catch (err) {
      console.error("Error occurred:", err);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
    setIsUploadPopupOpen(false);
  };

  const firstpopupclose = () => {
    setIsNCPopupOpen(false);
  };

  return (
    <>
      <ToastContainer />
      {isNCPopupOpen && (
        <div className={styles.Background}>
          <div className={styles.popupContainer} ref={popupRef}>
            <div className={styles.header}>
              <p></p>
              <p style={{ margin: "0px" }}>{petData.petName} Profile</p>
              <div
                style={{ marginRight: "25px", cursor: "pointer" }}
                onClick={firstpopupclose}
              >
                <CrossIconBlack />
              </div>
            </div>
            <div className={styles.mainContainer}>
              <div className={styles.progressBar1}>
                <div
                  className={styles.progressBar1}
                  style={{
                    width: `${percentage}%`,
                    backgroundColor: "#1FBFC2",
                  }}
                ></div>
              </div>
              <p>
                Providing comprehensive details of your pet enhances your
                chances of connecting with interested members.
              </p>
              <div>
                {NC.filter((_, index) => {
                  if (index === 1) return petData.pedigreeCertificate;
                  if (index === 2) return petData.DNACertificate;
                  return true;
                }).map((nc, index) => {
                  const isCompleted =
                    setPhotoUrl !== null && setClipUrl !== null;

                  return (
                    <div
                      key={index}
                      className={styles.ncdiv}
                      style={{
                        backgroundColor:
                          (index === 0 && petData.livePet) ||
                          (index === 1 && petData.pedigreeCertificate) ||
                          (index === 2 && petData.DNACertificate) ||
                          (index === 3 && result) ||
                          (index === 4 && petData.description) ||
                          (index === 5 &&
                            Array.isArray(petData.morePhotos) &&
                            petData.morePhotos.length > 0) ||
                          (index === 6 &&
                            Array.isArray(petData.moreVideos) &&
                            petData.moreVideos.length > 0)
                            ? "rgba(227, 255, 231, 0.50)"
                            : "#F8EDED",
                      }}
                    >
                      <h1>{nc.heading}</h1>
                      <p>{nc.text}</p>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          onClick={() => {
                            if (index === 1 && !petData.pedigreeCertificate) {
                              console.log("Index 1 behaving like Index 3");
                              setIsUpdatePopupOpen(true);
                              setDetailedprofile(false);
                              setpedigreeCertificate(false);
                              setLivePet(false);
                              setAddmorephotos(false);
                              setIsUploadPopupOpen(false);
                              setDNACertificate(false);
                              return;
                            } else if (index === 2 && !petData.DNACertificate) {
                              console.log("Index 2 behaving like Index 4");
                              setIsUploadPopupOpen(true);
                              setpedigreeCertificate(false);
                              setLivePet(false);
                              setDetailedprofile(true);
                              setDNACertificate(false);
                              setAddmorephotos(false);
                              return;
                            } else if (index === 3 && !result) {
                              console.log("Index 3 behaving like Index 5");
                              setIsUploadPopupOpen(true);
                              setpedigreeCertificate(false);
                              setLivePet(false);
                              setDetailedprofile(false);
                              setAddmorephotos(true);
                              setDNACertificate(false);
                              return;
                            } else if (
                              index === 4 &&
                              (!petData.morePhotos ||
                                petData.morePhotos.length === 0)
                            ) {
                              console.log("Index 4 behaving like Index 6");
                              setIsUploadPopupOpen(true);
                              setpedigreeCertificate(false);
                              setLivePet(false);
                              setDetailedprofile(false);
                              setAddMoreVideos(true);
                              setAddmorephotos(false);
                              setDNACertificate(false);
                              return;
                            }

                            if (index === 0) {
                              console.log("Index 0 clicked");
                              setIsUploadPopupOpen(true);
                              setLivePet(true);
                              setpedigreeCertificate(false);
                              setDNACertificate(false);
                              setDetailedprofile(false);
                              setAddmorephotos(false);
                            } else if (index === 1) {
                              console.log("Index 1 clicked");
                              setIsUploadPopupOpen(true);
                              setLivePet(false);
                              setpedigreeCertificate(true);
                              setDetailedprofile(false);
                              setAddmorephotos(false);
                            } else if (index === 2) {
                              console.log("Index 2 clicked");
                              setIsUploadPopupOpen(true);
                              setpedigreeCertificate(false);
                              setLivePet(false);
                              setDetailedprofile(false);
                              setAddmorephotos(false);
                              setDNACertificate(true);
                            } else if (index === 3) {
                              console.log("Index 3 clicked");
                              setIsUpdatePopupOpen(true);
                              setDetailedprofile(false);
                              setpedigreeCertificate(false);
                              setLivePet(false);
                              setAddmorephotos(false);
                              setIsUploadPopupOpen(false);

                              setDNACertificate(false);
                            } else if (index === 4) {
                              console.log("Index 4 clicked");
                              setIsUploadPopupOpen(true);
                              setpedigreeCertificate(false);
                              setLivePet(false);
                              setDetailedprofile(true);
                              setDNACertificate(false);
                              setAddmorephotos(false);
                            } else if (index === 5) {
                              console.log("Index 5 clicked");
                              setIsUploadPopupOpen(true);
                              setpedigreeCertificate(false);
                              setLivePet(false);
                              setDetailedprofile(false);
                              setAddmorephotos(true);
                              setDNACertificate(false);
                            } else if (index === 6) {
                              console.log("Index 6 clicked");
                              setIsUploadPopupOpen(true);
                              setpedigreeCertificate(false);
                              setLivePet(false);
                              setDetailedprofile(false);
                              setAddMoreVideos(true);
                              setAddmorephotos(false);
                              setDNACertificate(false);
                            }
                          }}
                          style={{
                            backgroundColor:
                              (index === 0 && petData.livePet) ||
                              (index === 1 && petData.pedigreeCertificate) ||
                              (index === 2 && petData.DNACertificate) ||
                              (index === 3 && result) ||
                              (index === 4 && petData.description) ||
                              (index === 5 &&
                                Array.isArray(petData.morePhotos) &&
                                petData.morePhotos.length > 0) ||
                              (index === 6 &&
                                Array.isArray(petData.moreVideos) &&
                                petData.moreVideos.length > 0)
                                ? "#209832"
                                : "#BF0C0C",
                            opacity: index === 0 && petData.livePet ? 0.5 : 1,
                            cursor:
                              index === 0 && petData.livePet
                                ? "not-allowed"
                                : "pointer",
                          }}
                          disabled={index === 0 && petData.livePet}
                        >
                          {(index === 1 && petData.pedigreeCertificate) ||
                          (index === 2 && petData.DNACertificate) ||
                          (index === 3 && result) ||
                          (index === 4 && petData.description) ||
                          (index === 5 &&
                            Array.isArray(petData.morePhotos) &&
                            petData.morePhotos.length > 0) ||
                          (index === 6 &&
                            Array.isArray(petData.moreVideos) &&
                            petData.moreVideos.length > 0)
                            ? "Updated"
                            : "Verify"}
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {isUploadpopOpen && (
            <div className={styles.popupContainer} ref={popupRef}>
              <div className={styles.header}>
                <p></p>
                <p style={{ margin: "0px" }}>{petData.petName} Profile</p>
                <div
                  style={{ marginRight: "25px", cursor: "pointer" }}
                  onClick={() => {
                    setIsUploadPopupOpen(false);
                  }}
                >
                  <CrossIconBlack />
                </div>
              </div>
              <div className={styles.mainContainer}>
                {/* <p style={{ marginTop: "30px" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur sed efficitur sem, ac iaculis eros.
                </p>
                <p style={{ marginTop: "30px" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur sed efficitur sem, ac iaculis eros. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit.
                </p>
                <div
                  className={styles.ncdiv}
                  style={{
                    backgroundColor: "#F8EDED",
                    marginTop: "40px",
                    marginBottom: "50px",
                  }}
                >
                  <h1>Important</h1>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitursed efficitur sem, ac iaculis eros. 
                  </p>
                </div> */}

                {livepet ? (
                    
                  <div>
                     <p style={{ marginTop: "30px" }}>
                     To verify that the pet is currently under your care, we kindly ask that you provide a video featuring your pet. The video should be between 10 to 30 seconds long and include a piece of paper clearly displaying today’s date.
                </p>
                <p style={{ marginTop: "30px" }}>
                Please be assured that the video will remain private and will only be used for verification purposes. If you choose to discontinue our services, the video will be deleted along with your account.
                </p>
                <div
                  className={styles.ncdiv}
                  style={{
                    backgroundColor: "#F8EDED",
                    marginTop: "40px",
                    marginBottom: "50px",
                  }}
                >
                  <h1>Important</h1>
                  <p>
                  In the video, you must show a piece of paper with today’s date clearly visible alongside your pet; failure to do so will result in the video being rejected.
                  </p>
                </div>
                    <h1>Profile Video/Photo</h1>
                    <div className={styles.cameraDiv} onClick={startCamera}>
                      {!isCameraOpen && !imageSrc && !videoURL && (
                        <CameraIcon />
                      )}
                      {isCameraOpen && !imageSrc && !videoURL && (
                        <>
                          <div
                            style={{
                              display: "block",
                              marginTop: "87px",
                              marginLeft: "6px",
                              position: "relative",
                              left: "130px",
                            }}
                          >
                            <video
                              ref={videoRef}
                              autoPlay
                              style={{ width: "52.5%", height: "auto" }}
                            ></video>
                            <canvas
                              ref={canvasRef}
                              style={{ display: "none" }}
                            ></canvas>
                            <div style={{ marginTop: "10px" }}>
                              {!mediaRecorder && (
                                <button
                                  onClick={captureImage}
                                  style={{
                                    width: "140px",
                                    height: "40px",
                                    background: "#F5790C",
                                    color: "#FFFFFF",
                                    marginRight: "5px",
                                  }}
                                >
                                  Capture Image
                                </button>
                              )}
                              {!mediaRecorder && (
                                <button
                                  onClick={startRecording}
                                  className={styles.submitButton}
                                >
                                  Start Recording
                                </button>
                              )}
                              {mediaRecorder && (
                                <button
                                  onClick={stopRecording}
                                  style={{ color: "red", marginLeft: "61px" }}
                                  className={styles.submitButton}
                                >
                                  Stop Recording
                                </button>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      {imageSrc && (
                        <div
                          style={{
                            position: "relative",
                            top: "38px",
                            left: "145px",
                          }}
                        >
                          <p>Captured Image:</p>
                          <img
                            src={URL.createObjectURL(imageSrc)}
                            alt="Captured"
                            style={{ width: "50%", marginBottom: "10px" }}
                          />
                          <button
                            onClick={() => {
                              startCamera();
                              resetAfterRecording();
                            }}
                            className={styles.submitButton}
                            style={{ marginLeft: "61px" }}
                          >
                            Retake Image
                          </button>
                        </div>
                      )}

                      {videoURL && (
                        <div
                          style={{
                            position: "relative",
                            top: "38px",
                            left: "145px",
                          }}
                        >
                          <p style={{ marginLeft: "60px" }}>Recorded Video:</p>
                          <video
                            src={videoURL}
                            controls
                            style={{ width: "50%", marginBottom: "10px" }}
                          ></video>
                          <button
                            onClick={() => {
                              setVideoURL(null);
                              setMediaRecorder(null);
                              startCamera();
                            }}
                            className={styles.submitButton}
                            style={{ marginLeft: "61px" }}
                          >
                            Retake Video
                          </button>
                        </div>
                      )}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        className={styles.submitButton}
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ) : pedigreeCertificate ? (
                  <>
                   <p style={{ marginTop: "30px" }}>
                   A pet profile is a detailed summary or representation of a pet’s characteristics, background, and personality, often created on social media or pet-specific platforms.
                </p>
                <p style={{ marginTop: "30px" }}>
                A Pedigree Certificate is an official document that confirms the lineage or ancestry of a pet, typically used for purebred animals like dogs, cats, and horses.
                </p>
                <div
                  className={styles.ncdiv}
                  style={{
                    backgroundColor: "#F8EDED",
                    marginTop: "40px",
                    marginBottom: "50px",
                  }}
                >
                  <h1>Important</h1>
                  <p>
                  Pedigree certificates are essential for verifying a pet’s breed history, and they play an important role in breeding, showing, and ensuring that the pet is a true representative of its breed. 
                  </p>
                </div>
                    <h1 style={{marginBottom:"18px"}}>Pedigree Certificate</h1>
                    <div
  className={styles.cameraDiv}
  style={{ height: "180px", cursor: "pointer", position: "relative" }} 
>
  <input
    type="file"
    style={{
      opacity: 0,
      cursor: "pointer",
      width: "100%",
      height: "100%",
      zIndex: 2,
      position: "absolute",
      top: 0,
      left: 0,
    }}
    onChange={handleFileChange}
  />
  <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1 }}>
    {selectedFile ? (
      <label
        style={{
          width: "94%",
          fontSize: "18px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "inline-block",
        }}
      >
        File Name: {selectedFile.name}
      </label>
    ) : (
      <CameraIcon /> // Now this should display correctly
    )}
  </div>
</div>
<div style={{ display: "flex", justifyContent: "center" }}>
  <button
    className={styles.submitButton}
    onClick={PedigreeCertificate}
    style={{ marginBottom: "0px" }}
  >
    Submit
  </button>
</div>

                  </>
                ) : DNACertificate ? (
                  <div>
                        <p style={{ marginTop: "30px" }}>
                        Enable 'DNA Tested' Tag" refers to activating a certification or label that indicates the pet has undergone genetic testing to confirm its ancestry, breed, and health status
                </p>
                <p style={{ marginTop: "30px" }}>
                This tag serves as a verified marker, confirming that the pet has been tested for specific genetic markers, traits, and potential hereditary health conditions. Here’s a full description of what this tag represents:                </p>
                <div
                  className={styles.ncdiv}
                  style={{
                    backgroundColor: "#F8EDED",
                    marginTop: "40px",
                    marginBottom: "50px",
                  }}
                >
                  <h1>Important</h1>
                  <p>
                  Enabling the "DNA Tested" tag is an important step in ensuring that the pet's genetic background is verified, its health is monitored, and its lineage is authentic. It offers peace of mind to potential owners or breeders, promoting transparency and trust.                  </p>
                </div>
                    <h1>DNACertificate</h1>
                    <div
                      className={styles.cameraDiv}
                      style={{ height: "180px", cursor: "pointer", position: "relative" }} 
                      >
                      {" "}
                      <input
                        type="file"
                        style={{
                            opacity: 0,
                            cursor: "pointer",
                            width: "100%",
                            height: "100%",
                            zIndex: 2,
                            position: "absolute",
                            top: 0,
                            left: 0,
                        }}
                        onChange={handleDNAFileChange}
                      />{" "}
  <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1 }}>
  {selectedDNAFile ? (
                          <label style={{   width: "94%",
                            fontSize: "18px",
                            whiteSpace: "nowrap",
                            overflow: "hidden", 
                            textOverflow: "ellipsis",
                            display: "inline-block", marginLeft:"10px"}}>
                           File Name: {selectedDNAFile.name}
                          </label>
                        ) : (
                          <CameraIcon />
                        )}
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        className={styles.submitButton}
                        onClick={HandleDNACertificate}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ) : detailedprofile ? (
                  <div>
                        <p style={{ marginTop: "30px" }}>
                        A detailed pet profile description is a comprehensive overview of a pet's characteristics, personality, background, and other important information that helps others understand and connect with the pet.                </p>
                <p style={{ marginTop: "30px" }}>
                It’s especially useful for showcasing a pet on social media, adoption platforms, or pet-related websites. Here’s what a detailed pet profile description typically includes.                   </p>
                <div
                  className={styles.ncdiv}
                  style={{
                    backgroundColor: "#F8EDED",
                    marginTop: "40px",
                    marginBottom: "50px",
                  }}
                >
                  <h1>Important</h1>
                  <p>
                
                  In essence, a detailed profile description plays a pivotal role in building trust, showcasing the pet’s personality, and ensuring that the pet is placed in the right home or environment.                         </p>
                </div>
                    <h1>Detailed profile Description</h1>
                    <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                      <textarea
                        placeHolder="Your Detailed profile Description..."
                        style={{
                          width: "100%",
                          borderColor: "#D9D9D9",
                          color: "#4C4C4C",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          padding: "10px",
                        }}
                        rows={5}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        className={styles.submitButton}
                        onClick={handleSubmitdescription}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ) : addmorephotos ? (
                  <div>
                        <p style={{ marginTop: "30px" }}>
                        Adding more photos to a pet's description is a powerful way to enhance its profile and help potential adopters, buyers, or followers connect with the pet visually.
                                                              </p>
                <p style={{ marginTop: "30px" }}>
                High-quality, diverse photos provide a fuller picture of the pet's personality, appearance, and behavior. Here’s why adding more photos is important in a pet's description.                         </p>
                <div
                  className={styles.ncdiv}
                  style={{
                    backgroundColor: "#F8EDED",
                    marginTop: "40px",
                    marginBottom: "50px",
                  }}
                >
                  <h1>Important</h1>
                  <p>
                
                  Adding more photos to a pet's profile is essential to showcase its personality, health, and behavior. High-quality, diverse images help build trust, attract potential adopters, and provide a complete visual understanding.                                     </p>
                </div>
                    <h1>Add more photos of your pet</h1>
                    <div style={{ display: "flex" }}>
                      <div className={styles.imagePreviewContainer}>
                        {images.map((image, index) => (
                          <div className={styles.imagePreview} key={index}>
                            <img
                              src={image}
                              alt={`Profile ${index}`}
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "50%",
                                border: "1px soild green",
                              }}
                            />
                            <button
                              className={styles.cancelButton}
                              onClick={() => handleCancel(index)}
                            >
                              X
                            </button>
                          </div>
                        ))}
                      </div>

                      <div style={{ display: "block" }}>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          id="file-input"
                          multiple
                        />

                        <div
                          className={styles.addButtonDiv}
                          onClick={() =>
                            document.getElementById("file-input").click()
                          }
                        >
                          <p>+</p>
                        </div>

                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: 500,
                            color: "#A6ACB8",
                          }}
                        >
                          Profile Photos
                        </p>
                      </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        className={styles.submitButton}
                        onClick={handlepostimg}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ) : addMoreVideos ? (
                  <>
                    <div>
                    <p style={{ marginTop: "30px" }}>
                    Adding more videos to a pet's full description is an effective way to highlight its personality, behavior, and unique traits.                                                              </p>
                <p style={{ marginTop: "30px" }}>
                Videos bring the pet to life, offering potential adopters or viewers a dynamic, engaging view of the pet's daily activities. Here’s why adding more videos is important.                              </p>
                <div
                  className={styles.ncdiv}
                  style={{
                    backgroundColor: "#F8EDED",
                    marginTop: "40px",
                    marginBottom: "50px",
                  }}
                >
                  <h1>Important</h1>
                  <p>
                
                  Adding more videos to a pet's profile showcases its personality, behavior, and special skills. Videos engage potential adopters, enhance transparency, and create an emotional connection, increasing the likelihood of adoption or interest.                  </p>
                </div>
                      <h1>Add more videos of your pet</h1>
                      <div style={{ display: "flex" }}>
                        {/* Video Preview Section */}
                        <div className={styles.videoPreviewContainer}>
                          {videos.map((video, index) => (
                            <div className={styles.videoPreview} key={index}>
                              <video
                                src={video}
                                alt={`Video ${index}`}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                }}
                                controls
                              />
                              <button
                                className={styles.cancelButton}
                                onClick={() => handleCancel2(index, "video")}
                              >
                                X
                              </button>
                            </div>
                          ))}
                        </div>

                        <div style={{ display: "block" }}>
                          {/* Video Upload */}
                          <input
                            type="file"
                            accept="video/*"
                            onChange={handleVideoChange}
                            style={{ display: "none" }}
                            id="video-input"
                            multiple
                          />
                          <div
                            className={styles.addButtonDiv}
                            onClick={() =>
                              document.getElementById("video-input").click()
                            }
                          >
                            <p>+</p>
                          </div>

                          <p
                            style={{
                              fontSize: "13px",
                              fontWeight: 500,
                              color: "#A6ACB8",
                            }}
                          >
                            Profile Photos/Videos
                          </p>
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          className={styles.submitButton}
                          onClick={handlepostvideo}
                        >
                          Submit
                        </button>
                      </div>
                    </div>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default NotCompletedPopup;