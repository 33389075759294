import React, { useState, useRef, useEffect } from 'react';
import styles from './FiltersPopup.module.css';
import { CrossIconBlack } from '../../../../assets/SVG';
import DropDownV1 from '../../../UI/DropDownV1';
import ToggleSwitch from '../../../UI/ToggleSwitch';

const FiltersPopup = ({ isFiltersPopupOpen, setIsFiltersPopupOpen, updateFilters }) => {
    const popupRef = useRef(null);

    const [distance, setDistance] = useState(1370);  
    const [size, setSize] = useState('Select Size');
    const [pureBreedOnly, setPureBreedOnly] = useState(false);
    const [pedigreeCertifiedOnly, setPedigreeCertifiedOnly] = useState(false);
    const [dnaCertifiedOnly, setDnaCertifiedOnly] = useState(false);

    const handleDistanceChange = (e) => {
        setDistance(e.target.value);
        updateFilters({ distance: e.target.value });
    };

    const handleSizeChange = (value) => {
        setSize(value);
        updateFilters({ size: value });
    };

    const handlePureBreedToggle = () => {
        setPureBreedOnly(prevState => !prevState);
        updateFilters({ pureBreedOnly: !pureBreedOnly });
    };

    const handlePedigreeToggle = () => {
        setPedigreeCertifiedOnly(prevState => !prevState);
        updateFilters({ pedigreeCertifiedOnly: !pedigreeCertifiedOnly });
    };

    const handleDnaToggle = () => {
        setDnaCertifiedOnly(prevState => !prevState);
        updateFilters({ dnaCertifiedOnly: !dnaCertifiedOnly });
    };

    useEffect(() => {
        if (isFiltersPopupOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => document.body.classList.remove('no-scroll');
    }, [isFiltersPopupOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsFiltersPopupOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setIsFiltersPopupOpen]);

    return (
        <>
            {isFiltersPopupOpen && (
                <div className={styles.Background}>
                    <div className={styles.popupContainer} ref={popupRef}>
                        <div className={styles.header}>
                            <p></p>
                            <p style={{ margin: '0px' }}>Filters</p>
                            <div style={{ marginRight: '25px', cursor: 'pointer' }} onClick={() => {
                                setIsFiltersPopupOpen(false)
                            }}><CrossIconBlack /></div>
                        </div>
                        <div className={styles.mainContainer}>
                            <p>Pets within a distance of {distance} Miles</p>
                            <input
                                type='range'
                                min='0'
                                max='5000'
                                value={distance}
                                onChange={handleDistanceChange}
                                style={{ backgroundColor: '#FFFFFF', border: 'none' }}
                            />
                            <DropDownV1
                                question={'Size'}
                                options={['Select Size', 'Small', 'Large', 'Medium']}
                                width='100%'
                                value={size}
                                onChange={handleSizeChange}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '25px' }}>
                                <p>Pure Breed Pets Only</p>
                                <ToggleSwitch checked={pureBreedOnly} onChange={handlePureBreedToggle} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '25px' }}>
                                <p>Pedigree Certified Only</p>
                                <ToggleSwitch checked={pedigreeCertifiedOnly} onChange={handlePedigreeToggle} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '25px' }}>
                                <p>DNA Certified Only</p>
                                <ToggleSwitch checked={dnaCertifiedOnly} onChange={handleDnaToggle} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FiltersPopup;
