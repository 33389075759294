import React from "react";
import styles from './ui.module.css';

const CustomInputElement = ({
  question,
  width = '45%',
  backgroundColor = 'rgba(243, 243, 243, 0.50)',
  placeholder,
  value,
  onChange,
  CustomInputElementepdate,
  CustomInputElementmargin,
  CustomInputElement
}) => {

    
  return (
    <div
      className={styles.inputDiv}
      style={{
        flex: width === '94%' ? `1 1 ${width}` : '',
        width: CustomInputElementepdate?.width || width,
        padding: CustomInputElementepdate?.padding || "20px 20px 23px 42px", 
        borderBottom: CustomInputElementepdate?.borderBottom||"1px solid #F0F1F6",
marginTop:CustomInputElementmargin?.marginTop||"0px",
marginBottom:CustomInputElementepdate?.marginBottom
      }}
    >
      <p style={{fontSize:CustomInputElementepdate?.fontSize,
                      color:CustomInputElement?.color


      }}>{question}</p>
      <input
        type="text"
        style={{
          backgroundColor: backgroundColor,
          border: "1px solid #D9D9D9",
          boxShadow: "none", 
        }}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default CustomInputElement;
