import React, { useState, useEffect, useRef } from 'react';
import styles from './Header.module.css';
import ServiceFeatures from './ServiceFeatures';
import { useNavigate } from 'react-router';
import { useLocation } from "react-router-dom";
import dropdownSvg from "../../assets/images/Vector.svg";
import ProfileSvg from '../../assets/images/Group.svg';
import bucketSvg from '../../assets/images/bucket.svg';
import { useRecoilState } from 'recoil';
import { userInfo } from '../../Atoms';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);

  const [currentUserInfo, setCurrentUser] = useRecoilState(userInfo);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectCountry, setSelectCountry] = useState(false);

  const userLoggedIn = !!currentUserInfo?.email;

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 0);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setSelectCountry(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const toggleCityDropdown = () => setSelectCountry(!selectCountry);
  const toggleProfileDropdown = () => setProfileDropdownOpen(!profileDropdownOpen);

  const handleLogin = () => navigate("/login");
  const handleLogout = () => {
    setCurrentUser({});
    navigate('/');
  };

  const handleEdit =() => {
    navigate('/edit-profile')
  }

  return (
    <>
      <header className={`${styles.header} ${isScrolled ? styles.scrolled : ''}`}>
        <div className={styles.headerContent}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/36cfcad6b36251f6cfd91438fce91cf0e6ab5c0d9f5bedc73498f634519828cd?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
            alt="Logo"
            className={styles.logo}
          />
          <button className={styles.menuButton} onClick={toggleMenu}>
            <span className={`${styles.menuIcon} ${isMenuOpen ? styles.open : ''}`}></span>
          </button>
          <nav className={`${styles.navigation} ${isMenuOpen ? styles.open : ''}`}>
            <a href="#services" className={styles.navLink} onClick={toggleDropdown}>
              Services
            </a>
            <a href="book-a-vet" className={styles.navLink}>Book a Vet</a>
            <a href="#shopping" className={styles.navLink}>Shopping</a>
            <a href="#about" className={styles.navLink}>About us</a>
            {userLoggedIn ? (
              <div className={styles.loggedInMenu}>
             
                <div className={styles.userMenu}>
                  <div onClick={toggleCityDropdown} className={styles.userMenuDropdown}>
                    <span className={styles.userMenuTrigger}>
                      Select Your City
                      <img src={dropdownSvg} alt="Dropdown" className={styles.dropDownSvg} />
                    </span>
                  </div>
                  {selectCountry && (
                    <ul ref={dropdownRef} className={styles.userDropdown}>
                      <li className={styles.dropdownItem}>City 1</li>
                      <li className={styles.dropdownItem}>City 2</li>
                      <li className={styles.dropdownItem}>City 3</li>
                    </ul>
                  )}
                </div>

                <div className={styles.userMenu2} onClick={toggleProfileDropdown}>
                  <div className={styles.userMenuDropdown2}>
                    <img src={ProfileSvg} alt="Profile" className={styles.profileIcon} />
                  </div>
                  {profileDropdownOpen && (
                    <ul ref={dropdownRef} className={styles.userDropdown}>
                      <li className={styles.dropdownItem} onClick={handleEdit}>Profile</li>
                      <li className={styles.dropdownItem} onClick={handleLogout}>Logout</li>
                    </ul>
                  )}
                </div>
                <div>
                  <img src={bucketSvg} alt="Bucket" />
                </div>
              </div>
            ) : (
              <button className={styles.loginButton} onClick={handleLogin}>
                LOG IN
              </button>
            )}
          </nav>
        </div>
      </header>

      {isDropdownOpen && (
        <div ref={dropdownRef} className={styles.dropDownMain}>
          <ServiceFeatures />
        </div>
      )}
    </>
  );
};

export default Header;
