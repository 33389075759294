import React from "react";
import "./ScrollingLists.css";

const ScrollingLists = () => {
  const bestSellingProducts = [
    { id: 1, name: "Product A", sales: 200 },
    { id: 2, name: "Product B", sales: 150 },
    { id: 3, name: "Product C", sales: 120 },
    { id: 4, name: "Product D", sales: 100 },
    { id: 5, name: "Product E", sales: 80 },
    { id: 6, name: "Product F", sales: 70 },
  ];

  const badSellingProducts = [
    { id: 7, name: "Product G", sales: 20 },
    { id: 8, name: "Product H", sales: 15 },
    { id: 9, name: "Product I", sales: 10 },
    { id: 10, name: "Product J", sales: 5 },
  ];

  return (
    <div className="scrolling-lists-container">
      <div className="list-container">
        <h4>Best Selling Products</h4>
        <marquee direction="up">
          <ul className="list">
            {bestSellingProducts.map((product) => (
              <li key={product.id}>{product.name}</li>
            ))}
          </ul>
        </marquee>
      </div>

      <div className="list-container">
        <h4>Bad Selling Products</h4>
        <marquee direction="up">
          <ul className="list">
            {badSellingProducts.map((product) => (
              <li key={product.id}>{product.name}</li>
            ))}
          </ul>
        </marquee>
      </div>
    </div>
  );
};

export default ScrollingLists;
