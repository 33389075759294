import React, { useState } from "react";
import "./Forgotpassword.css";
import zaanvarlogo from "../../assets/images/ZAANVAR_FINAL LOGO.png";
import bgimg from "../../assets/images/Rectangle 20.png";
import { WebApimanager } from "../../WebApiManager";
import { useNavigate } from "react-router";

const Forgotpassword = () => {
  let webApi = new WebApimanager();
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  
  const handleInputChange = (e) => {
    setEmailOrPhone(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!emailOrPhone) {
      setError("Please enter your email or phone number.");
      return;
    }

    setLoading(true);
    setError("");
    setSuccess("");
    const payload = { email: emailOrPhone };

    try {
      const response = await webApi.post(
        "users/requestForPassword",
        payload
      );

      // Pass the email to OTP page
      setSuccess("Check your email for the OTP.");
      navigate("/otp", { state: { email: emailOrPhone } });

    } catch (err) {
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fp-signmainHeader">
      <div className="fp-containerbody fp-containeronly">
        <div className="fp-container">
          <div className="fp-left-side">
            <div className="fp-zaanvar-icon">
              <img
                src={zaanvarlogo}
                style={{ width: "100px" }}
                alt="Zaanvar Logo"
              />
            </div>

            <div>
              <form className="login-form" onSubmit={handleSubmit}>
                <div className="fp-welcome">
                  <p style={{ fontSize: "13px" }}>Forgot your password</p>
                </div>
                <div className="forgetpass-l1">
                  <label>
                    Don’t worry, happens to all of us.
                    <label>
                      Enter your email ID or phone number below to recover your
                      password
                    </label>
                  </label>
                </div>
                <label
                  style={{
                    marginBottom: "10px",
                    marginTop: "25px",
                    fontSize: "11px",
                  }}
                >
                  Enter your email or phone number
                </label>
                <input
                  type="text"
                  className="fp-field"
                  placeholder="Enter your email"
                  value={emailOrPhone}
                  onChange={handleInputChange}
                />

                {/* Show error message if validation fails */}
                {error && (
                  <div
                    className="error-message"
                    style={{ color: "red", fontSize: "10px" }}
                  >
                    {error}
                  </div>
                )}

                {success && (
                  <div className="success-message" style={{ color: "green" }}>
                    {success}
                  </div>
                )}

                <button
                  className="login-button"
                  style={{ marginTop: "20px", borderRadius: "5px" }}
                  disabled={loading} // Disable button if request is in progress
                >
                  {loading ? "Please wait..." : "CONTINUE"}
                </button>
              </form>
            </div>
          </div>

          <div className="fp-img">
            <img src={bgimg} className="fp-fixed-bg-image" alt="Background" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;
