import React, { useState } from 'react';
import Account from './SettingPages/Account';
import MembershipPage from './SettingPages/MembershipPage';
import NotificationsPage from './SettingPages/NotificationsPage';
import Archived_MessagesPage from './SettingPages/Archived_MessagesPage';
import Blocked_MembersPage from './SettingPages/Blocked_MembersPage';

const Profile_Settings = () => {
  // Set an initial active tab, for example, 'account'
  const [activeTab, setActiveTab] = useState('account');

  const rendersettingPages = () => {
    switch (activeTab) {
      case 'account':
        return <Account />;
      case 'Membership':
        return <MembershipPage />;
      case 'Notifications':
        return <NotificationsPage />;
      case 'Archived-Messages':
        return <Archived_MessagesPage />;
      case 'Blocked_Members':
        return <Blocked_MembersPage />;
      default:
        return <Account />;  
    }
  }

  return (
    <>
      <div>
        <div className="profile-settings-container">
          <div
            className={`profile-section ${activeTab === 'account' ? 'active' : ''}`}
            onClick={() => setActiveTab('account')}
          >
            Account
          </div>
          <div
            className={`profile-section ${activeTab === 'Membership' ? 'active' : ''}`}
            onClick={() => setActiveTab('Membership')}
          >
            Membership
          </div>
          <div
            className={`profile-section ${activeTab === 'Notifications' ? 'active' : ''}`}
            onClick={() => setActiveTab('Notifications')}
          >
            Notifications
          </div>
          <div
            className={`profile-section ${activeTab === 'Archived-Messages' ? 'active' : ''}`}
            onClick={() => setActiveTab('Archived-Messages')}
          >
            Archived Messages
          </div>
          <div
            className={`profile-section ${activeTab === 'Blocked_Members' ? 'active' : ''}`}
            onClick={() => setActiveTab('Blocked_Members')}
          >
            Blocked Members
          </div>
        </div>
        <div>
          {rendersettingPages()}
        </div>
      </div>
    </>
  )
}

export default Profile_Settings;
