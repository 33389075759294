import React, { useState } from "react";
import {
  Flex,
  Box,
  Select,
  Button,
  Input,
  Stack,
  Image,
  Text,
  Grid,
  GridItem,
  Card,
  Center,
  CardBody,
} from "@chakra-ui/react";
import CustomerNavbar from "./Components/CustomerNavbar";

const mallsData = {
  Hyderabad: [
    {
      name: "Forum Sujana Mall",
      image: "https://via.placeholder.com/150",
      description: "A popular shopping destination in Hyderabad.",
    },
    {
      name: "Inorbit Mall",
      image: "https://via.placeholder.com/150",
      description: "One of the largest malls in Hyderabad.",
    },
  ],
  Bangalore: [
    {
      name: "Phoenix Marketcity",
      image: "https://via.placeholder.com/150",
      description:
        "A vibrant mall with a wide range of stores and dining options.",
    },
    {
      name: "UB City",
      image: "https://via.placeholder.com/150",
      description: "An upscale mall known for its luxury brands.",
    },
  ],
  Chennai: [
    {
      name: "Express Avenue",
      image: "https://via.placeholder.com/150",
      description: "A spacious mall with entertainment and dining options.",
    },
    {
      name: "Phoenix Marketcity",
      image: "https://via.placeholder.com/150",
      description: "Another branch of the popular mall chain.",
    },
  ],
  Delhi: [
    {
      name: "DLF Mall of India",
      image: "https://via.placeholder.com/150",
      description: "The largest mall in India with a vast selection of stores.",
    },
    {
      name: "Select Citywalk",
      image: "https://via.placeholder.com/150",
      description: "A trendy mall popular among locals and tourists alike.",
    },
  ],
};

export default function LocationsForPets() {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedPet, setSelectedPet] = useState("");

  const handleSearch = () => {
    console.log("Search clicked!");
  };

  return (
    <div className="CustomerDashboard">
      <div className="CustomerNavbar">
        <Card  width="100%">
          <CardBody>
            <CustomerNavbar />
          </CardBody>
        </Card>
      </div>
      <Flex direction="column" alignItems="center" mt={8}>
        <Stack direction={["column", "row"]} spacing={4} align="center" mb={4}>
          <Select
            placeholder="Select Location"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
          >
            {Object.keys(mallsData).map((location) => (
              <option key={location} value={location}>
                {location}
              </option>
            ))}
          </Select>

          <Select
            placeholder="Select Pet"
            value={selectedPet}
            onChange={(e) => setSelectedPet(e.target.value)}
          >
            <option value="Tommy">Tommy</option>
            <option value="Vicky">Vicky</option>
            <option
              value="addNewPet"
              style={{ background: "lightBlue", fontWeight: "bold" }}
            >
              + Add Pet
            </option>
          </Select>

          <Button colorScheme="teal" onClick={handleSearch}>
            Search
          </Button>
        </Stack>

        {selectedLocation && mallsData[selectedLocation] && (
          <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]} gap={6}>
            {mallsData[selectedLocation].map((mall, index) => (
              <GridItem key={index}>
                <Card width="100%">
                  <Center>
                    <Image src={mall.image} alt={mall.name} />
                  </Center>
                  <Box p={4}>
                    <Text fontSize="xl" fontWeight="bold" mb={2}>
                      {mall.name}
                    </Text>
                    <Text>{mall.description}</Text>
                  </Box>
                </Card>
              </GridItem>
            ))}
          </Grid>
        )}
      </Flex>
    </div>
  );
}
