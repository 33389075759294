import React from 'react';

const ArrowIconLeft = ({currentPage}) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.15 0.5L4 6.65C3.9522 6.69489 3.9141 6.74911 3.88803 6.80931C3.86197 6.8695 3.84851 6.9344 3.84851 7C3.84851 7.0656 3.86197 7.1305 3.88803 7.19069C3.9141 7.25089 3.9522 7.30511 4 7.35L10.15 13.5" stroke={currentPage === 0 ?  '#000000' : `#FFFFFF`} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default ArrowIconLeft;
