import React, { useEffect, useState } from "react";
import "../SocialMedia.css";
import SocialMediaPosts from "./SocialMediaPosts";
import SocialMediaQandA from "./SocialMediaQandA";
import SocialMediaPoll from "./SocialMediaPoll";
import { useRecoilState } from "recoil";
import { userInfo } from "../../../Atoms";
import { BACKEND_URL } from "../../../Utilities/Constant";
import { WebApimanager } from "../../../WebApiManager";

const SocialMediaSettings = () => {
  const webApi = new WebApimanager();
  const [images, setImages] = useState([]);
  const [socialMediaData, setSocialMediaData] = useState([]);

  const [activeTab, setActiveTab] = useState("post");

  const renderSocialMediaPages = () => {
    switch (activeTab) {
      case "post":
        return <SocialMediaPosts />;
      case "QandA":
        return <SocialMediaQandA />;
      case "poll":
        return <SocialMediaPoll />;
      default:
        return <SocialMediaPosts />;
    }
  };

  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);

  useEffect(() => {
    console.log(currentUserInfo, "user info for profile social media posts");

    const imageresponse = async () => {
      try {
        const userId = currentUserInfo?._id;
        if (userId) {
          const response = await webApi.get(
            `socialMedia/postsByUserId/${userId}?type=Image`
          );

          setImages(response.data.posts);
          console.log(response.data.posts, "Extracted images for the ");
          console.log(images, "cdjsbhfvbfv");
        } else {
          console.log("User ID is not available");
        }
      } catch (error) {
        console.log(error);
      }
    };

    imageresponse();
  }, [currentUserInfo]);

  const fetchSocialMediaData = async () => {
    const userId = currentUserInfo?._id;

    try {
      if (userId) {
        const response = await webApi.get(
          `socialMedia/postsByUserId/${userId}`
        );
        setSocialMediaData(response.data.posts);
        console.log("social media particulat use account", response.data.posts);
      } else {
      }
    } catch (error) {
      console.error("Error fetching social media data:", error);
    }
  };

  useEffect(() => {
    fetchSocialMediaData();
  }, []);

  const formatNumber = (num) => {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + "B";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + "M";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + "K";
    }
    return num.toString();
  };

  return (
    <>
      <div>
        <div className="socialmadia-profile">
          <div className="socialmedia-profile-left-side">
            <div>
              <img
                src={`${BACKEND_URL}${
                  socialMediaData[0]?.userId?.profile || "/default-profile.png"
                }`}
                alt="Profile"
                className="socialmedia-profile-image-home"
              />
            </div>
            <div className="socialmedia-profile-text">
              <div className="socialmedia-profile-name">
                {images[0]?.userId?.name || "User Name"}
              </div>
              <div className="socialmedia-profile-description">
                Hello! I’m a proud pawrents of
              </div>
            </div>
          </div>

          <div className="socialmedia-profile-right-side">
            <div className="top-buttons">
              {/* <button className="socialmedia-button">Follow</button>
              <button className="socialmedia-button">Share</button> */}
            </div>
            <div className="bottom-buttons">
              <p className="socialmedia-account-details">
                {formatNumber(images.length)} Posts
              </p>
              <p className="socialmedia-account-details">
                {formatNumber(
                  socialMediaData[0]?.socialMedia?.followers?.length || 0
                )}{" "}
                followers
              </p>
              <p className="socialmedia-account-details">
                {formatNumber(
                  socialMediaData[0]?.socialMedia?.following?.length || 0
                )}{" "}
                following
              </p>
            </div>
          </div>
        </div>

        <div className="socialmedia-menu-container">
          <div
            className={`socialmedia-account-item-tabs ${
              activeTab === "post" ? "active" : ""
            }`}
            onClick={() => setActiveTab("post")}
          >
            POSTS
          </div>
          <div
            className={`socialmedia-account-item-tabs ${
              activeTab === "QandA" ? "active" : ""
            }`}
            onClick={() => setActiveTab("QandA")}
          >
            Q/A
          </div>
          <div
            className={`socialmedia-account-item-tabs ${
              activeTab === "poll" ? "active" : ""
            }`}
            onClick={() => setActiveTab("poll")}
          >
            POLL
          </div>
        </div>

        <div>{renderSocialMediaPages()}</div>
      </div>
    </>
  );
};

export default SocialMediaSettings;
