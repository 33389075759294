import React from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Card,
  CardBody,
  IconButton,
  TableContainer,
  Wrap,
  WrapItem,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { UilEdit, UilTrashAlt } from "@iconscout/react-unicons";
import "./../../styles/GroomingAppointmentPageStyles.css";
import dogIcon from "./../../../../assets/service provider/dog.png";
import catIcon from "./../../../../assets/service provider/cat.png";
import { useState } from "react";

const AppointmentTable = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointmentIndex, setSelectedAppointmentIndex] =
    useState(null);
  const [editedAppointment, setEditedAppointment] = useState(null);

  const handleEditClick = (appointment, index) => {
    setSelectedAppointmentIndex(index);
    setEditedAppointment({ ...appointment });
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
    setSelectedAppointmentIndex(null);
    setEditedAppointment(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedAppointment({ ...editedAppointment, [name]: value });
  };

  const handleSave = () => {
    const updatedAppointments = [...appointments];
    updatedAppointments[selectedAppointmentIndex] = editedAppointment;
    setAppointments(updatedAppointments);
    handleCloseModal();
  };

  const initialAppointments = Array.from({ length: 20 }, (_, index) => ({
    tokenNo: index + 1,
    petName: index % 2 === 0 ? "Cat" : "Dog",
    petId: `PET-${index + 1}`,
    appointmentDateTime: `2024-04-${index + 1} ${
      index % 2 === 0 ? "09:00 AM" : "02:00 PM"
    }`,
    customerName: `Customer ${index + 1}`,
    service: index % 2 === 0 ? "Grooming" : "Nails Cutting",
    status: index % 3 === 0 ? "Completed" : "Pending",
  }));

  if (appointments.length === 0) {
    setAppointments(initialAppointments);
  }

  return (
    <>
      <Card width={"82vw"} bg={"#005555"} height={"75vh"}>
        <CardBody>
          <TableContainer
            style={{
              maxHeight: "72vh",
              overflowY: "scroll",
              fontWeight: "bold",
            }}
          >
            <Table
              variant="striped"
              colorScheme="#F9F54B"
              bg={"white"}
              height={"75vh"}
            >
              <Thead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: "100",
                }}
              >
                <Tr>
                  <Th>Token No</Th>
                  <Th>Pet Name</Th>
                  <Th>Pet ID</Th>
                  <Th>Appointment Date and Time</Th>
                  <Th>Customer Name</Th>
                  <Th>Service</Th>
                  <Th>Status</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {appointments.map((appointment, index) => (
                  <Tr
                    key={index}
                    bg={index % 2 !== 0 ? "#005555" : "#F9F54B"}
                    color={index % 2 !== 0 ? "#F9F54B" : "#005555"}
                  >
                    <Td>{appointment.tokenNo}</Td>
                    <Td className="d-flex align-items-center justify-content-between">
                      {appointment.petName}
                      <Wrap>
                        <WrapItem>
                          <Avatar
                            name="Dan Abrahmov"
                            src={index % 2 !== 0 ? dogIcon : catIcon}
                          />
                        </WrapItem>
                      </Wrap>
                    </Td>
                    <Td>{appointment.petId}</Td>
                    <Td>{appointment.appointmentDateTime}</Td>
                    <Td>{appointment.customerName}</Td>
                    <Td>{appointment.service}</Td>
                    <Td>{appointment.status}</Td>
                    <Td>
                      <IconButton
                        aria-label="Edit"
                        icon={<UilEdit />}
                        colorScheme={index % 2 === 0 ? "blue" : "white"}
                        variant="outline"
                        size="sm"
                        mr={2}
                        onClick={() => handleEditClick(appointment, index)}
                      />
                      <IconButton
                        aria-label="Delete"
                        icon={<UilTrashAlt />}
                        colorScheme={index % 2 === 0 ? "red" : "white"}
                        variant="outline"
                        size="sm"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>

      <Modal isOpen={showEditModal} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Appointment Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Token No</FormLabel>
              <Input
                type="text"
                name="tokenNo"
                value={editedAppointment?.tokenNo}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Pet Name</FormLabel>
              <Input
                type="text"
                name="petName"
                value={editedAppointment?.petName}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Pet ID</FormLabel>
              <Input
                type="text"
                name="petId"
                value={editedAppointment?.petId}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Appointment Date and Time</FormLabel>
              <Input
                type="text"
                name="appointmentDateTime"
                value={editedAppointment?.appointmentDateTime}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Customer Name</FormLabel>
              <Input
                type="text"
                name="customerName"
                value={editedAppointment?.customerName}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Service</FormLabel>
              <Input
                type="text"
                name="service"
                value={editedAppointment?.service}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Status</FormLabel>
              <Input
                type="text"
                name="status"
                value={editedAppointment?.status}
                onChange={handleInputChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSave}>
              Save
            </Button>
            <Button colorScheme="red" onClick={handleCloseModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AppointmentTable;
