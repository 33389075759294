import React from 'react';
import styles from './ServiceAboutSection.module.css';

const ServiceAboutSection = () => {
  return (
    <section className={styles.about}>
      <div className={styles.aboutContent}>
        <div className={styles.imageColumn}>
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/32906c30eeec1e1e546189f256c92f28aa67448c0523ed6850050d3c46a3e864?apiKey=860967788028437b8a0095d5a96bdd20&&apiKey=860967788028437b8a0095d5a96bdd20" alt="Pet clinic interior" className={styles.clinicImage} />
        </div>
        <div className={styles.textColumn}>
          <h2 className={styles.sectionTitle}>About</h2>
          <p className={styles.description}>
            Pet clinics are healthcare facilities dedicated to the medical care and treatment of pets. They offer a range of services, including routine check-ups, vaccinations, dental care, and surgical procedures. Experienced veterinarians and staff provide personalized care to ensure the health and well-being of pets. Many clinics also offer emergency services and advanced diagnostics. Additionally, pet clinics often provide pet owners with valuable guidance on nutrition, behavior, and preventive care.
          </p>
          <h3 className={styles.servicesTitle}>Services</h3>
          <ul className={styles.servicesList}>
            <li>General Health Check-ups</li>
            <li>Vaccinations</li>
            <li>Surgical Services</li>
            <li>Dental Care</li>
            <li>Pharmacy</li>
            <li>Basic Emergency Care</li>
            <li>Basic Nutrition Diet</li>
            <li>Home Visits</li>
            <li>Health Certificates for Travel</li>
            <li>Microchipping for Identification</li>
          </ul>
          <button className={styles.appointmentButton}>Book an Appointment</button>
        </div>
      </div>
    </section>
  );
};

export default ServiceAboutSection;