import React from 'react';
import styles from './LostAndFoundLandingPage.module.css';

const Modal = ({ isOpen, onClose, onConfirm, }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <p className={styles.modalContentText}>Please sign in to proceed and access all features of the application</p>
        <div className={styles.buttonGroup}>
          <button className={styles.closeButton} onClick={onClose}>Close</button>
          <button className={styles.confirmButton} onClick={onConfirm}>Log in</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
