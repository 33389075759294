import React, { useEffect, useRef, useState } from "react";
import "./Signupin.css";
import Scooby_doo_filled from "../assets/images/ZAANVAR_FINAL LOGO.png";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogBody,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const DoctorSignupin = () => {
  const [show, setShow] = React.useState(false);
  const [confirmshow, setConfirmShow] = React.useState(false);
  const [loginpwdshow, setLoginpwdshow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleconfirmClick = () => setConfirmShow(!confirmshow);
  const handleloginpwdClick = () => setLoginpwdshow(!loginpwdshow);
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = React.useRef();
  const containerRef = useRef(null);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [passwordResetSent, setPasswordResetSent] = useState(false);
  const [formDataSignUp, setFormDataSignUp] = useState({
    full_name: "",
    email: "",
    gender: "",
    phone_number: "",
    address: "",
    veterinary_license_number: "",
    specialty: "",
    education: "",
    work_experience: "",
    documents: [],
  });

  const [formDataSignIn, setFormDataSignIn] = useState({
    signInEmail: "",
    signInPassword: "",
  });

  const [resetForm, setResetForm] = useState({
    email: "",
  });

  const [resetFormErrors, setResetFormErrors] = useState({});

  const [isEmailValid, setIsEmailValid] = useState(false);
  const handleEmailChange = (e) => {
    const email = e.target.value;

    const isValid = /\S+@\S+\.\S+/.test(email);
    setIsEmailValid(isValid);
  };

  const [errorsSignUp, setErrorsSignUp] = useState({});
  const [errorsSignIn, setErrorsSignIn] = useState({});
  const [resetEmail, setResetEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const handleInputChangeSignUp = (e) => {
    const { name, value } = e.target;
    setFormDataSignUp({
      ...formDataSignUp,
      [name]: value,
    });
  };

  const handleInputChangeSignIn = (e) => {
    const { name, value } = e.target;
    setFormDataSignIn({
      ...formDataSignIn,
      [name]: value,
    });
  };

  const handleInputChangeReset = (e) => {
    const { name, value } = e.target;
    setResetForm({
      ...resetForm,
      [name]: value,
    });
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    const errors = {};
    const nameRegex = /^[a-zA-Z]+$/;

    if (!formDataSignUp.full_name.trim()) {
      errors.full_name = "Full Name is required";
    } else if (!nameRegex.test(formDataSignUp.full_name.trim())) {
      errors.full_name = "Full Name should contain only letters";
    }

    if (!formDataSignUp.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formDataSignUp.email)) {
      errors.email = "Email is invalid";
    }

    if (Object.keys(errors).length > 0) {
      setErrorsSignUp(errors);
    } else {
      console.log("Form submitted:", formDataSignUp);
      setErrorsSignUp({});
      setFormDataSignUp({
        full_name: "",
        email: "",
        gender: "",
        phone_number: "",
        address: "",
        veterinary_license_number: "",
        specialty: "",
        education: "",
        work_experience: "",
        documents: [],
      });
    }
  };

  // const handleSignUp = (e) => {
  //   e.preventDefault();
  //   const errors = {};

  //   if (!formDataSignUp.first_name.trim()) {
  //     errors.first_name = 'First Name is required';
  //   }
  //   if (!formDataSignUp.last_name.trim()) {
  //     errors.last_name = 'Last Name is required';
  //   }

  //   if (!formDataSignUp.email.trim()) {
  //     errors.email = 'Email is required';
  //   } else if (!/\S+@\S+\.\S+/.test(formDataSignUp.email)) {
  //     errors.email = 'Email is invalid';
  //   }

  //   if (!formDataSignUp.password.trim()) {
  //     errors.password = 'Password is required';
  //   }
  //   if (!formDataSignUp.password_confirmation.trim()) {
  //     errors.password_confirmation = 'Password Confirmation is required'
  //   }

  //   if (Object.keys(errors).length > 0) {
  //     setErrorsSignUp(errors);
  //   } else {
  //     console.log('Form submitted:', formDataSignUp);
  //     setErrorsSignUp({});
  //     setFormDataSignUp({
  //       first_name: '',
  //       last_name: '',
  //       email: '',
  //       password: '',
  //       password_confirmation: ''

  //     });

  //   }
  // };

  const handleSignIn = (e) => {
    e.preventDefault();
    const errors = {};

    if (!formDataSignIn.signInEmail.trim()) {
      errors.signInEmail = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formDataSignIn.signInEmail)) {
      errors.signInEmail = "Email is invalid";
    }

    if (!formDataSignIn.signInPassword.trim()) {
      errors.signInPassword = "Password is required";
    }

    if (Object.keys(errors).length > 0) {
      setErrorsSignIn(errors);
    } else {
      console.log("Form submitted:", formDataSignIn);
      setErrorsSignIn({});
      setFormDataSignIn({
        signInEmail: "",
        signInPassword: "",
      });
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (isEmailValid) {
      setPasswordResetSent(true);
      setShowAlert(true);
      setResetEmail("");
      setIsEmailValid(false);
    } else {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 2000);
    }
  };

  useEffect(() => {
    const signUpButton = document.getElementById("signUp");
    const signInButton = document.getElementById("signIn");

    const handleSignUpClick = () => {
      containerRef.current.classList.add("right-panel-active");
    };

    const handleSignInClick = () => {
      containerRef.current.classList.remove("right-panel-active");
    };

    signUpButton.addEventListener("click", handleSignUpClick);
    signInButton.addEventListener("click", handleSignInClick);

    return () => {
      signUpButton.removeEventListener("click", handleSignUpClick);
      signInButton.removeEventListener("click", handleSignInClick);
    };
  }, []);

  return (
    <div className="signmainHeader">
      <div className="containerbody containeronly" ref={containerRef}>
        <div className="form-container sign-up-container">
          <form onSubmit={handleSignUp} className="signform">
            <h1 className="signuph1">Register</h1>
            <input
              className="textinput"
              type="text"
              name="full_name"
              placeholder="Full Name"
              value={formDataSignUp.full_name}
              onChange={handleInputChangeSignUp}
            />
            {errorsSignUp.full_name && (
              <span className="error errortext">{errorsSignUp.full_name}</span>
            )}

            <input
              className="textinput"
              type="email"
              name="email"
              placeholder="Email"
              value={formDataSignUp.email}
              onChange={handleInputChangeSignUp}
            />
            {errorsSignUp.email && (
              <span className="error errortext">{errorsSignUp.email}</span>
            )}

            <input
              className="textinput"
              type="text"
              name="gender"
              placeholder="Gender"
              value={formDataSignUp.gender}
              onChange={handleInputChangeSignUp}
            />
            <input
              className="textinput"
              type="text"
              name="phone_number"
              placeholder="Phone Number"
              value={formDataSignUp.phone_number}
              onChange={handleInputChangeSignUp}
            />
            <input
              className="textinput"
              name="address"
              placeholder="Address"
              value={formDataSignUp.address}
              onChange={handleInputChangeSignUp}
            />
            <input
              className="textinput"
              type="text"
              name="veterinary_license_number"
              placeholder="Veterinary License Number"
              value={formDataSignUp.veterinary_license_number}
              onChange={handleInputChangeSignUp}
            />
            <input
              className="textinput"
              type="text"
              name="specialty"
              placeholder="Specialty"
              value={formDataSignUp.specialty}
              onChange={handleInputChangeSignUp}
            />
            <input
              className="textinput"
              type="text"
              name="education"
              placeholder="Education"
              value={formDataSignUp.education}
              onChange={handleInputChangeSignUp}
            />
            <input
              className="textinput"
              type="text"
              name="work_experience"
              placeholder="Work Experience"
              value={formDataSignUp.work_experience}
              onChange={handleInputChangeSignUp}
            />
            <input
              type="file"
              name="documents"
              onChange={(e) =>
                setFormDataSignUp({
                  ...formDataSignUp,
                  documents: e.target.files,
                })
              }
              multiple
            />

            <button className="buttons" onClick={() => setIsOpen(true)}>
              Register
            </button>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef}>
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Thank you Doc!
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    We will get back to you in some time
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
                      Cancel
                    </Button>
                    <Link to="/">
                      <Button
                        colorScheme="red"
                        onClick={() => setIsOpen(false)}
                        ml={3}
                      >
                        Back to home
                      </Button>
                    </Link>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </form>
        </div>
        <div className="form-container sign-in-container">
          {showForgotPassword ? (
            <form onSubmit={handleForgotPassword} className="signform">
              <h1 className="signuph1">Reset Password</h1>
              {showAlert && !isEmailValid && (
                <Alert
                  status="error"
                  className="alert"
                  style={{
                    color: "green",
                    backgroundColor: "#d3f2d3",
                    marginBottom: "0",
                    marginTop: "10px",
                  }}
                >
                  <AlertIcon style={{ height: "20px" }} />
                  <AlertTitle
                    mr={5}
                    style={{ fontSize: "11px", width: "100%", marginRight: 0 }}
                  >
                    A Password Rest has sent to your email
                  </AlertTitle>
                </Alert>
              )}
              <input
                className="textinput"
                type="email"
                name="resetEmail"
                placeholder="Email"
                value={resetEmail}
                onChange={(e) => {
                  handleEmailChange(e);
                  setResetEmail(e.target.value);
                }}
              />
              {resetFormErrors.resetEmail && (
                <span className="error errortext">
                  {resetFormErrors.resetEmail}
                </span>
              )}

              <button
                className="buttons"
                type="submit"
                disabled={!isEmailValid}
              >
                Send
              </button>
            </form>
          ) : (
            <form onSubmit={handleSignIn} className="signform">
              <h1 className="signuph1">Sign in</h1>
              <input
                className="textinput"
                type="email"
                name="signInEmail"
                placeholder="Email"
                value={formDataSignIn.signInEmail}
                onChange={handleInputChangeSignIn}
              />
              {errorsSignIn.signInEmail && (
                <span className="error errortext">
                  {errorsSignIn.signInEmail}
                </span>
              )}

              <InputGroup size="md">
                <Input
                  className="textinput"
                  pr="4.5rem"
                  name="signInPassword"
                  type={loginpwdshow ? "text" : "password"}
                  placeholder="Password"
                  value={formDataSignIn.signInPassword}
                  onChange={handleInputChangeSignIn}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleloginpwdClick}>
                    {loginpwdshow ? (
                      <i class="bi bi-eye eye-icon-stl"></i>
                    ) : (
                      <i class="bi bi-eye-slash eye-icon-stl"></i>
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errorsSignIn.signInPassword && (
                <span className="error errortext errortextmargin">
                  {errorsSignIn.signInPassword}
                </span>
              )}
              <a
                href="#"
                className="a"
                onClick={() => setShowForgotPassword(true)}
              >
                Forgot your password?
              </a>
              <Link to="/DoctorDashboard">
                <button className="buttons" type="submit">
                  Sign In
                </button>
              </Link>
            </form>
          )}
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <img
                src={Scooby_doo_filled}
                style={{ width: "120px", marginTop: "-30px" }}
                alt="Scooby Doo"
                loading="lazy"
              />
              <h1 className="signuph1">Welcome Back!</h1>
              <p className="signupp">
                To keep connected and receive instant services, please login
                with your personal info
              </p>
              <button className="ghost buttons" id="signIn">
                Sign In
              </button>
            </div>
            <div className="overlay-panel overlay-right">
              <img
                src={Scooby_doo_filled}
                style={{ width: "120px", marginTop: "-30px" }}
                alt="Scooby Doo"
              />
              <h1 className="signuph1">Hello, Friend!</h1>
              <p className="signupp">
                Enter your personal details and start journey with us to get
                assistance for your pet
              </p>
              <button className="ghost buttons" id="signUp">
                Register
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorSignupin;
