import React from "react";
import { Card, CardBody } from "@chakra-ui/react";
import Chart from "react-apexcharts";

const AppointmentStats = () => {
  const data = [
    { date: "Jan", online: 50, offline: 30 },
    { date: "Feb", online: 60, offline: 35 },
    { date: "Mar", online: 70, offline: 40 },
    { date: "Apr", online: 80, offline: 45 },
    { date: "May", online: 90, offline: 50 },
    { date: "Jun", online: 100, offline: 55 },
    { date: "Jul", online: 110, offline: 60 },
  ];

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: data.map((day) => day.date),
      labels: {
        style: {
          colors: "#495057",
          fontSize: "14px",
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      title: {
        text: "Number of Appointments",
        style: {
          color: "#495057",
          fontSize: "14px",
          fontWeight: 500,
        },
      },
      labels: {
        style: {
          colors: "#495057",
          fontSize: "14px",
          fontWeight: 400,
        },
      },
    },
    colors: ["#4CAF50", "#F44336"],
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
      labels: {
        colors: "#495057",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
  };

  const series = [
    { name: "Online", data: data.map((day) => day.online) },
    { name: "Offline", data: data.map((day) => day.offline) },
  ];

  return (
    <Card>
      <CardBody>
        <h4>Appointment Stats</h4>
        <Chart
          options={options}
          series={series}
          type="bar"
          width="100%"
          height="300"
        />
      </CardBody>
    </Card>
  );
};

export default AppointmentStats;
