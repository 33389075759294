import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "recoil-persist", // this key is using to store data in local storage
  storage: sessionStorage, // configurate which stroage will be used to store the data
});

export const userInfo = atom({
  key: "userInfo",
  default: {
    firstName: "",
    lastName: "",
    contactNumber: "",
    ID: "",
    role: "",
    department: "",
    permittedSites: [],
  },
  effects_UNSTABLE: [persistAtom],
});

// Selector for isAuthenticated
export const isAuthenticated = selector({
  key: "isAuthenticated",
  get: ({ get }) => {
    const userData = get(userInfo);
    return Boolean(userData && userData.ID);
  },
});

const jwtToken = atom({
  key: "jwtToken",
  default: "",
  effects_UNSTABLE: [persistAtom],
});
const idToken = atom({
  key: "idToken",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export { jwtToken, idToken };

export const refreshToken = atom({
  key: "refreshToken",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

// export const checkList = atom({
//   key: "checkList",
//   default: {},
//   effects_UNSTABLE: [persistAtom],
// });

