import React, {useEffect, useState} from 'react';
import styles from './Tinder.module.css';
import { petMenu, infoMenu, faq } from './constants';
import BookingSteps from '../BookingSteps/BookingSteps';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { userInfo } from '../../Atoms';
import TinderModalLogin from "../Tinder/TinderModalLogin"

const LandingPage = () => {

  const [faqMenu, setFaqMenu] = useState(faq);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo)

  useEffect(() => {
    console.log(currentUserInfo, "Tender user details");
  }, [currentUserInfo]);

  const onClickFaqFunction = (id) => {
    const updatedMenu = faqMenu.map(tempFaq =>
      tempFaq.id === id ? { ...tempFaq, visible: !tempFaq.visible } : tempFaq
    );
    setFaqMenu(updatedMenu);
  };
  const userLoggedIn = !!currentUserInfo?.email;

  const handleGetStarted = () => {
    if(userLoggedIn){
      navigate('/Tinder/home')
  }else{
          setIsModalOpen(true);
  }
 
  };

  // Tinder/home
  const handleLoginRedirect = () => {
    setIsModalOpen(false); 
    navigate('/login', { state: { from: '/Tinder/home' } });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); 
  };

  return (
    <>
      <section className={styles.hero}>
          <img src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2Fd73d8d81f40b4e9f9eead98a03831248" alt="Hero background" className={styles.heroBackground} />
          <div className={styles.heroDiv}>
              <h1>CONNECTING PETS</h1>
              <p>The #1 Network for Pet Lovers</p>
              <button onClick={(handleGetStarted)}>GET STARTED</button>
          </div>
      </section>
      <section className={styles.PetMenuDiv}>
          <div className={styles.petMenuDiv2}>
            {petMenu.map((pet, index) => (
              <button key={index} onClick={pet.onClick} className={styles.petMenuButton}>
                <img src={pet.image} alt={pet.name} className={styles.petImage} />
                {pet.name}
              </button>
            ))}
          </div>
      </section>
      <section className={styles.connectMenu}>
        <div className={styles.connectMenu2}>
          <h1>Connect & Meet Pets on Zaanvar</h1>
          <p>Whether you’re looking for a new pet, want to breed your furry friend, or need to find a forever home for your pet, we have you covered.</p>
          <p>Zaanavr is your one-stop solution for pet adoption, pet breeding, and pet for sale.</p>
          <p>We provide a safe and secure platform for pet owners to connect, chat, and meet up with their furry friends. Our goal is to make sure that every pet owner has the opportunity to meet their pet’s needs and provide them with the best possible experience.</p>
          <button onClick={(handleGetStarted)}>GET STARTED</button>
          </div>
      </section>
      <section className={styles.info}>
            {infoMenu.map(info => {
              return(
                <div className={styles.infoItem}>
                  <h1>{info.title}</h1>
                  <p>{info.text}</p>
                </div>
              )
            })}
      </section>
      <BookingSteps />
      <section className={styles.hero2}>
          <img src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F85691eee07cb49d59a0c7fd710020d44" alt="Hero background" className={styles.heroBackground2} />
          <div className={styles.heroDiv2}>
              <h1>Responsible Pet Ownership</h1>
              <p>Pet ownership is a major commitment that calls for a great deal of time, energy, and focus. It is essential to offer the correct diet, care, and attention to your pet in order for him or her to be happy and healthy.
              We are dedicated to providing our clients with the knowledge and tools they need to be successful pet owners.</p>
          </div>
      </section>
      <section className={styles.faq}>
        <h1>Frequently Asked Questions About Zaanvar</h1>
        {faqMenu.map((faqItem) => {
          return (
            <div className={styles.faqDiv} onClick = {() => onClickFaqFunction(faqItem.id)}>
              <p className={styles.faqDivQuestion}>{faqItem.question}</p>
              {faqItem.visible && <div className={styles.answersDiv}>
                {faqItem.answer.map(answer => {
                  return (
                    <div>
                      <p className={styles.answerTitle}>{answer.title}</p>
                      <p className={styles.answerText}>{answer.text}</p>
                    </div>
                  )
                })}
              </div>}
            </div>
          )
        })}


      </section>

      <TinderModalLogin
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleLoginRedirect}
        message="Please log in to continue."
        confirmText="Log in"
      />
    </>
  );
};

export default LandingPage;