import React, { useState } from 'react'
import { WebApimanager } from '../../WebApiManager';
import { ThreeDots } from 'react-bootstrap-icons';
import DogCard from "./GroomingImages/DogCard.png"
import { MdLocationPin } from "react-icons/md";
import { IoIosStar } from "react-icons/io";
import { useNavigate } from 'react-router';

const GroomingCard = ({
    filters,
    onCardClick,
    onChatClick,
    showMyPosts,
  }) => {
    const webApi = new WebApimanager();
    const navigate = useNavigate()
    const [petData, setPetData] = useState([]);
    const [showPetDetailsDialog, setShowPetDetailsDialog] = useState(false)
  
    const [saveStates, setSaveStates] = useState(
      petData.reduce((acc, pet) => {
        acc[pet.id] = false;
        return acc;
      }, {})
    );
  
    // useEffect(() => {
    //   webApi
    //     .get("lostAndFound/allPets")
    //     .then((response) => {
    //       const responseData = response.data;
    //       const foundPets = responseData.data.foundPets || [];
    //       const lostPets = responseData.data.lostPets || [];
  
    //       const allPets = [...foundPets, ...lostPets];
  
    //       setPetData(allPets);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching My Posts data:", error);
    //     });
    // }, [showMyPosts]);
  
    const filteredData = petData.filter((pet) => {
      const filterStatus = filters.selectedRadioOption;
      const filterPetType = filters.petType;
      const filterBreedType = filters.breedType;
      const filterPetColor = filters.petColor;
      const filterPetname = filters.petname;
      const filterPetid = filters.petID?.trim();
  
      const matchesStatus =
        !filters?.selectedRadioOption ||
        filterStatus === "Select" ||
        pet.petStatus === filterStatus;
      const matchesPetType =
        filterPetType === "Select" || pet.breed === filterPetType;
  
      const matchesPetColor =
        filters.petColor.length === 0 || filters.petColor.includes(pet.color);
      const matchesPetname =
        filterPetname === "Select" || pet.petType === filterPetname;
      const matchesPetId = !filterPetid || pet.petId.includes(filterPetid);
  
      return (
        matchesStatus &&
        matchesPetType &&
        matchesPetColor &&
        matchesPetname &&
        matchesPetId
      );
    });
  
    const handleIconClick = (id) => {
      setSaveStates((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
    };
  
    return (
      <>
      <div style={{ display: "flex",marginTop:"40px" }}>
        <section className="petProfileCard">
            <div  style={{height:"286px"}} className="petProfileCard2">
              <img
                // src={pet.petImage ? `${BACKEND_URL}${pet.petImage}` : img}
                src={DogCard}
                alt=""
                onClick={() => onCardClick()}
                style={{ cursor: "pointer", width: "100%", height: "60%" }}
              />
              <div style={{ margin: "15px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "6px",
                  }}
                >
                <div style={{ display: "flex",flexDirection:"column", gap: "5px" }}>
                    <h1 style={{color:"#727272"}}>Pet</h1>
                  <div
                    style={{
                    
                      display:"flex",
                      gap:'5px'
                    }}
                  >
                    <MdLocationPin style={{fontSize:"16px",color:"#727272"}}/>
                  <p style={{color:"#727272",fontSize:"14px"}} >Hyderabad</p>
                  </div>
                </div>

                <div>
                <p  style={{display:"flex"}}><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/></p>
                <button style={{backgroundColor:"#e1f4f8",color:"#1fbfc2",fontWeight:"500",fontSize:"16px"}} onClick={()=>navigate('/Grooming/bookings')}>Book Now</button>
                </div>
                 
                 </div>
               
               
                
              </div>
            </div>
            <div style={{height:"286px"}} className="petProfileCard2">
              <img
                // src={pet.petImage ? `${BACKEND_URL}${pet.petImage}` : img}
                src={DogCard}
                alt=""
                onClick={() => onCardClick()}
                style={{ cursor: "pointer", width: "100%", height:"60%" }}
              />
              <div style={{ margin: "15px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "6px",
                  }}
                >
                <div style={{ display: "flex",flexDirection:"column", gap: "5px" }}>
                    <h1 style={{color:"#727272"}}>Pet</h1>
                  <div
                    style={{
                    
                      display:"flex",
                      gap:'5px'
                    }}
                  >
                    <MdLocationPin style={{fontSize:"16px",color:"#727272"}}/>
                  <p style={{color:"#727272",fontSize:"14px"}} >Hyderabad</p>
                  </div>
                </div>

                <div>
                <p  style={{display:"flex"}}><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/></p>
                <button style={{backgroundColor:"#e1f4f8",color:"#1fbfc2",fontWeight:"500",fontSize:"16px"}} onClick={()=>navigate('/Grooming/bookings')}>Book Now</button>
                </div>
                 
                 </div>
               
               
                
              </div>
            </div>
            <div  style={{height:"286px"}} className="petProfileCard2">
              <img
                // src={pet.petImage ? `${BACKEND_URL}${pet.petImage}` : img}
                src={DogCard}
                alt=""
                onClick={() => onCardClick()}
                style={{ cursor: "pointer", width: "100%", height: "60%" }}
              />
              <div style={{ margin: "15px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "6px",
                  }}
                >
                <div style={{ display: "flex",flexDirection:"column", gap: "5px" }}>
                    <h1 style={{color:"#727272"}}>Pet</h1>
                  <div
                    style={{
                    
                      display:"flex",
                      gap:'5px'
                    }}
                  >
                    <MdLocationPin style={{fontSize:"16px",color:"#727272"}}/>
                  <p style={{color:"#727272",fontSize:"14px"}} >Hyderabad</p>
                  </div>
                </div>

                <div>
                <p  style={{display:"flex"}}><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/></p>
                <button style={{backgroundColor:"#e1f4f8",color:"#1fbfc2",fontWeight:"500",fontSize:"16px"}} onClick={()=>navigate('/Grooming/bookings')}>Book Now</button>
                </div>
                 
                 </div>
               
               
                
              </div>
            </div>
            
            {/* <div
            style={{
                display: "flex",
                justifyContent: "center",  
                alignItems: "center",      
                height: "100vh",
                position:"fixed",
                top:"10%",left:"50%"           
            }}
            >
            
            <ThreeDots
                color="#1bc1e9"
                height={100}
                width={100}
                ariaLabel="loading"
            />
            </div> */}
  
        </section>
      </div>
      
      </>
    );
  };

export default GroomingCard