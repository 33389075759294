import React, { useState } from "react";
import close from "../../../assets/images/close.png";
import back from "../../../assets/images/backb1.png";
import img from "../../../assets/images/changestatus.png";
import popupimg3 from "../../../assets/images/gallary.png";
import { WebApimanager } from "../../../WebApiManager";

const ChangeStatusPage2 = ({ statusValue, pet, onClose }) => {
  const webApi = new WebApimanager();
  const [error, setError] = useState(null);
  const [helpedByZaanvar, setHelpedByZaanvar] = useState(null);
  const [foundFromPost, setFoundFromPost] = useState(null);
  const [foundPetId, setFoundPetId] = useState("");
  const [recommendation, setRecommendation] = useState(null);
  const [thankYouMessage, setThankYouMessage] = useState("");
  const [petImage, setPetImage] = useState(null);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleHelpedByZaanvarChange = (e) => {
    setHelpedByZaanvar(e.target.value);
  };

  const handleFoundFromPostChange = (e) => {
    setFoundFromPost(e.target.value);
  };

  const handleFoundPetIdChange = (e) => {
    setFoundPetId(e.target.value);
  };

  const handleRecommendationChange = (e) => {
    setRecommendation(e.target.value);
  };

  const handleThankYouMessageChange = (e) => {
    setThankYouMessage(e.target.value);
  };

  const handlePetImageChange = (e) => {
    setPetImage(e.target.files[0]);
  };
  const petStatus = statusValue;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const faq = [
      {
        question: "Did Zaanvar directly help reunite your pet?",
        answer: helpedByZaanvar,
      },
      {
        question: "Did you find your pet from any post?",
        answer: foundFromPost,
      },
      {
        question: "Enter Found Pet ID",
        answer: foundPetId,
      },
      {
        question: "Would you recommend Zaanvar to your Friend",
        answer: recommendation,
      },
      {
        question:
          "Want to thank the rescues, shelters & volunteers who spread the word about your pet?",
        answer: thankYouMessage,
      },
    ];

    const payload = {
      petStatus: statusValue,
      faq: faq,
    };

    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });

    try {
      const response = await webApi.put(
        `lostAndFound/lostPetupdate/${pet._id}`,
        payload
      );

      if (response.data.status === "success") {
        alert("Pet Found Edit Successfully");
        window.location.reload();
      } else {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
      }
    } catch (err) {
      console.error("Error occurred:", err);
      setErrorMessage("Something went wrong. Please try again later.");
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleFoundSubmit = async (e) => {
    e.preventDefault();

    const faq = [
      {
        question: "Did Zaanvar help you to reunite your pet?",
        answer: helpedByZaanvar,
      },
      {
        question: "Did you found from any post?",
        answer: foundFromPost,
      },
      {
        question: "Enter Found Pet ID",
        answer: foundPetId,
      },
      {
        question: "Would you recommend Zaanvar to your Friend",
        answer: recommendation,
      },
      {
        question: "Would you recommend Zaanvar to your Friend",
        answer: thankYouMessage,
      },
    ];

    const payload = {
      petStatus: statusValue,
      faq: faq,
    };

    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });

    try {
      const response = await webApi.put(
        `lostAndFound/foundPetupdate/${pet._id}`,
        payload
      );

      if (response.data.status === "success") {
        alert("Pet Found Edit Successfully");
        window.location.reload();
      } else {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
      }
    } catch (err) {
      console.error("Error occurred:", err);
      setErrorMessage("Something went wrong. Please try again later.");
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  return (
    <>
      {pet && pet.petStatus === "Lost" && (
        <div className="lost-and-found-post-popup-overlay">
          <div className="lost-and-found-change2-popup-content">
            <button
              className="lost-and-found-post-changes-close"
              onClick={onClose}
            >
              <img src={back} alt="Back" />
            </button>
            <div className="lost-and-found-popup-hearder"></div>
            <div className="lost-and-found-changes2-body">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <img src={img} alt="" />
                </div>
              </div>

              <div className="changesstatus2-div1">
                Did Zaanvar directly help reunite your pet?
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <input
                  type="radio"
                  id="yes"
                  name="reunionStatus"
                  value="yes"
                  checked={helpedByZaanvar === "yes"}
                  onChange={handleHelpedByZaanvarChange}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginTop: "0px",
                    marginRight: "8px",
                  }}
                />
                <label htmlFor="yes" className="changestatus2-p">
                  Yes, Zaanvar directly helped with this reunion
                </label>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="radio"
                  id="no"
                  name="reunionStatus"
                  value="no"
                  checked={helpedByZaanvar === "no"}
                  onChange={handleHelpedByZaanvarChange}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginTop: "0px",
                    marginRight: "8px",
                  }}
                />
                <label htmlFor="no" className="changestatus2-p">
                  No, Zaanvar did not directly help
                </label>
              </div>

              {helpedByZaanvar === "yes" && (
                <>
                  <div className="changesstatus2-div1">
                    Did you find your pet from any post?
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <input
                      type="radio"
                      id="yes"
                      name="foundFromPost"
                      value="yes"
                      checked={foundFromPost === "yes"}
                      onChange={handleFoundFromPostChange}
                      style={{
                        width: "15px",
                        height: "15px",
                        marginTop: "0px",
                        marginRight: "8px",
                      }}
                    />
                    <label htmlFor="yes" className="changestatus2-p">
                      Yes
                    </label>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      id="no"
                      name="foundFromPost"
                      value="no"
                      checked={foundFromPost === "no"}
                      onChange={handleFoundFromPostChange}
                      style={{
                        width: "15px",
                        height: "15px",
                        marginTop: "0px",
                        marginRight: "8px",
                      }}
                    />
                    <label htmlFor="no" className="changestatus2-p">
                      No
                    </label>
                  </div>

                  {foundFromPost === "yes" && (
                    <>
                      <div className="changesstatus2-div1">
                        Enter Found Pet ID
                      </div>
                      <div>
                        <input
                          type="text"
                          value={foundPetId}
                          onChange={handleFoundPetIdChange}
                          style={{ width: "100%" }}
                          className="changesstatus2-textbox"
                          placeholder="Enter ID here"
                        />
                      </div>
                    </>
                  )}
                </>
              )}

              <p className="changestatuspage2-p2">
                Would you recommend Zaanvar to your Friend
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <input
                  type="radio"
                  id="yes"
                  name="recommendation"
                  value="yes"
                  checked={recommendation === "yes"}
                  onChange={handleRecommendationChange}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginTop: "6px",
                    marginRight: "8px",
                  }}
                />
                <label htmlFor="yes" className="changestatus2-p">
                  Yes, I would recommend Zaanvar to a friend
                </label>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="radio"
                  id="no"
                  name="recommendation"
                  value="no"
                  checked={recommendation === "no"}
                  onChange={handleRecommendationChange}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginTop: "6px",
                    marginRight: "8px",
                  }}
                />
                <label htmlFor="no" className="changestatus2-p">
                  No, I would not recommend Zaanvar to a friend
                </label>
              </div>

              <div className="changestatus-div2">
                Want to thank the rescues, shelters & volunteers who spread the
                word about your pet?
              </div>
              <div style={{ width: "100%", marginBottom: "10px" }}>
                <textarea
                  value={thankYouMessage}
                  onChange={handleThankYouMessageChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    boxSizing: "border-box",
                    resize: "vertical",
                    backgroundColor: "#F3F3F380",
                    borderColor: "#D9D9D9",
                  }}
                />
              </div>

              <div>
                <p className="changestatus2-p2">
                  Have a picture of your pet, home safe and sound? Include it
                  here.
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <input
                  type="file"
                  name="petImage"
                  onChange={handlePetImageChange}
                  style={{
                    width: "100%",
                    height: "80px",
                    backgroundColor: "#F3F3F3",
                    border: "1px solid #D9D9D9",
                    position: "relative",
                    backgroundImage: `url(${popupimg3})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    cursor: "pointer",
                  }}
                  id="fileInput"
                />
              </div>

              <div className="parent-container-changestatus">
                <div className="changestatusbutton" onClick={handleSubmit}>
                  Submit
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/*----------------------------------------------Found page------------------------------------------------------------*/}

      {pet && pet.petStatus === "Found" && (
        <div className="lost-and-found-post-popup-overlay">
          <div className="lost-and-found-change2-popup-content">
            <button
              className="lost-and-found-post-changes-close"
              onClick={onClose}
            >
              <img src={back} alt="Back" />
            </button>
            <button
              className="lost-and-found-post-popup-close"
              onClick={onClose}
            >
              <img src={close} alt="Close" />
            </button>
            <div className="lost-and-found-popup-hearder"></div>
            <div className="lost-and-found-changes2-body">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <img src={img} alt="" />
                </div>
              </div>

              <div className="changesstatus2-div1">
                Did Zaanvar directly help reunite your pet?
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <input
                  type="radio"
                  id="yes"
                  name="reunionStatus"
                  value="yes"
                  checked={helpedByZaanvar === "yes"}
                  onChange={handleHelpedByZaanvarChange}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginTop: "0px",
                    marginRight: "8px",
                  }}
                />
                <label htmlFor="yes" className="changestatus2-p">
                  Yes, Zaanvar directly helped with this reunion
                </label>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="radio"
                  id="no"
                  name="reunionStatus"
                  value="no"
                  checked={helpedByZaanvar === "no"}
                  onChange={handleHelpedByZaanvarChange}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginTop: "0px",
                    marginRight: "8px",
                  }}
                />
                <label htmlFor="no" className="changestatus2-p">
                  No, Zaanvar did not directly help
                </label>
              </div>

              {/* Conditionally render the next question only if "Yes" is selected */}
              {helpedByZaanvar === "yes" && (
                <>
                  <div className="changesstatus2-div1">
                    Did you found from any post?
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <input
                      type="radio"
                      id="yes"
                      name="foundFromPost"
                      value="yes"
                      checked={foundFromPost === "yes"}
                      onChange={handleFoundFromPostChange}
                      style={{
                        width: "15px",
                        height: "15px",
                        marginTop: "0px",
                        marginRight: "8px",
                      }}
                    />
                    <label htmlFor="yes" className="changestatus2-p">
                      Yes
                    </label>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      id="no"
                      name="foundFromPost"
                      value="no"
                      checked={foundFromPost === "no"}
                      onChange={handleFoundFromPostChange}
                      style={{
                        width: "15px",
                        height: "15px",
                        marginTop: "0px",
                        marginRight: "8px",
                      }}
                    />
                    <label htmlFor="no" className="changestatus2-p">
                      No
                    </label>
                  </div>

                  {foundFromPost === "yes" && (
                    <>
                      <div className="changesstatus2-div1">
                        Enter Found Pet ID
                      </div>
                      <div>
                        <input
                          type="text"
                          value={foundPetId}
                          onChange={handleFoundPetIdChange}
                          style={{ width: "100%" }}
                          className="changesstatus2-textbox"
                          placeholder="Enter ID here"
                        />{" "}
                      </div>
                    </>
                  )}
                </>
              )}

              <p className="changestatuspage2-p2">
                Would you recommend Zaanvar to your Friend
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <input
                  type="radio"
                  id="yes"
                  name="recommendation"
                  value="yes"
                  checked={recommendation === "yes"}
                  onChange={handleRecommendationChange}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginTop: "6px",
                    marginRight: "8px",
                  }}
                />
                <label htmlFor="yes" className="changestatus2-p">
                  Yes, I would recommend Zaanvar to a friend
                </label>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="radio"
                  id="no"
                  name="recommendation"
                  value="no"
                  checked={recommendation === "no"}
                  onChange={handleRecommendationChange}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginTop: "6px",
                    marginRight: "8px",
                  }}
                />
                <label htmlFor="no" className="changestatus2-p">
                  No, I would not recommend Zaanvar to a friend
                </label>
              </div>

              <div className="changestatus-div2">
                Describe anything about Zaanvar?
              </div>
              <div style={{ width: "100%", marginBottom: "10px" }}>
                <textarea
                  value={thankYouMessage}
                  onChange={handleThankYouMessageChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    boxSizing: "border-box",
                    resize: "vertical",
                    backgroundColor: "#F3F3F380",
                    borderColor: "#D9D9D9",
                  }}
                />
              </div>

              <div className="parent-container-changestatus">
                <div className="changestatusbutton" onClick={handleFoundSubmit}>
                  Submit
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChangeStatusPage2;
