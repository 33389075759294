import React, { useState } from 'react';
import styles from './OptionsPopup.module.css';
import { EditIconPen, HideIcon, ViewIcon } from '../../../../assets/SVG';
import { useNavigate } from 'react-router';
import OptionsViewPopup from '../OptionsViewPopup/OptionsViewPopup';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WebApimanager } from "../../../../WebApiManager";

const OptionsPopup = ({ onClose ,petData}) => {
  const [showViewPopup, setShowViewPopup] = useState(false);
const[showViewPopup1,setShowViewPopup1]=useState(true)
const [currentStatus, setCurrentStatus] = useState(petData.petStatus); // Local state for UI update
const webApi = new WebApimanager();
const [errorMessage, setErrorMessage] = useState("");
const [error, setError] = useState(null);
const [loading, setLoading] = useState(false);
const [apiProcessing, setApiProcessing] = useState({
  loader: false,
  message: "Loading...",
});

  const handleView = () => {
    setShowViewPopup(true);
  };

  const closeViewPopup = () => {
    setShowViewPopup(false);
    setShowViewPopup1(false)
  };

  const navigate = useNavigate();
  
    const handleToggleVisibility = async () => {
      const newStatus = currentStatus === "Hide" ? "Visible" : "Hide";
      setCurrentStatus(newStatus); 
  
      const payload = {
        petStatus: newStatus,
      };
  
      setApiProcessing({
        loader: true,
        message: "Requesting...",
      });
  
      try {
        const response = await webApi.put(
          `petProfile/update/${petData._id}`,
          payload
        );
        console.log(response, "responseresponse");
        toast.success("Update Successfully!");
        setShowViewPopup(false);
        setShowViewPopup1(false)
        window.location.reload()

      } catch (err) {
        console.error("Error occurred:", err);
        setError("Something went wrong. Please try again later.");
        toast.error("This is an error message!");
  
      } finally {
        setLoading(false);
      }
    };

  return (
    <>
   
       {showViewPopup1 && 
        <div className={styles.optionsMenu}>
          <ul>
            <li onClick={handleView}>
              <div style={{ marginTop: '3px' }}><ViewIcon /></div>
              <div style={{ marginLeft: '17px' }}>
                <p>View</p>
                <p style={{ fontSize: '10px', color: '#727271' }}>View your Pet Details</p>
              </div>
            </li>
            <li  onClick={() => navigate('/tinder/edit', { state: { petData: petData }, }) }>
              <div style={{ marginTop: '3px' }}><EditIconPen /></div>
              <div style={{ marginLeft: '17px' }}>
                <p>Edit</p>
                <p style={{ fontSize: '10px', color: '#727271' }}>Update your Pet’s details and photos</p>
              </div>
            </li>
            <li onClick={() => { handleToggleVisibility() }}>
      <div style={{ marginTop: "3px" }}>
        {petData.petStatus==="Hide" ? <ViewIcon /> :   <HideIcon /> }
      </div>
      <div style={{ marginLeft: "17px" }} >
        <p>   {petData.petStatus==="Hide"? "Visible" : "Hide"}</p>
        <p style={{ fontSize: "10px", color: "#727271" }}>
          {petData.petStatus==="Hide"
            ? "Make your pet’s profile visible"
            : "Keep your pet’s profile hidden"}
        </p>
      </div>
    </li>
          </ul>
        </div>
}
      {showViewPopup && <OptionsViewPopup onClose={closeViewPopup} petData={petData}/>}
    </>
  );
};

export default OptionsPopup;
