import React, { useEffect, useState } from 'react';
import { WebApimanager } from '../../WebApiManager';
import { FaEdit, FaSave } from 'react-icons/fa';
import "../Edit-Profile/profile.css";
import { BACKEND_URL } from '../../Utilities/Constant';
import { FaRegThumbsUp } from "react-icons/fa";
import Profile_Post_Pets from './Profile_Post_Pets';


const Profile_Register = () => {
  const webApi = new WebApimanager();
  const [pets, setPets] = useState([]);
  const [petsuser, setPetsuser] = useState([]);
  const [progress, setProgress] = useState(30);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchPetData = async () => {
      try {
        const petResponse = await webApi.get('petProfile/ByPetOwner');
        setPets(petResponse.data.data.pets);
      } catch (error) {
        console.error('Error fetching pet details:', error);
      }
    };

    fetchPetData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const petResponse = await webApi.get('users/userDetails');
        setPetsuser(petResponse.data);
      } catch (error) {
        console.error('Error fetching pet details:', error);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (index) => {
    const updatedPets = [...pets];
    updatedPets[index].isEditing = true;
    setPets(updatedPets);
  };

  const handleSave = (index, newName) => {
    const updatedPets = [...pets];
    updatedPets[index].petName = newName;
    updatedPets[index].isEditing = false;
    setPets(updatedPets);


  };

  return (
    <>


      <div>
        <div className="register-container">
          <div className="register-left">
            <div className='register-row'>
              {pets.map((pet, index) => (
                <div key={pet._id} className="register_box-card">
                  <img
                    src={
                      pet.petImage
                        ? `${BACKEND_URL}${pet.petImage}`
                        : `No Image`
                    }
                    alt={pet.petName || "Default Pet"}
                    className="register-card-image"
                  />

                  <div className="register-card-content">
                    {pet.isEditing ? (
                      <div className="edit-mode">
                        <input
                          type="text"
                          defaultValue={pet.petName}
                          className="register-edit-input"
                          onBlur={(e) => handleSave(index, e.target.value)}

                        />
                        <button
                          className="save-button"
                          onClick={() =>
                            handleSave(index, document.querySelector('.register-edit-input').value)
                          }
                        >
                          <FaSave />
                        </button>
                      </div>
                    ) : (
                      <div className="display-mode">
                        <p className="register-card-title">{pet.petName}</p>
                        <FaEdit
                          className="edit-icon"
                          onClick={() => handleEdit(index)}
                        />
                      </div>
                    )}
                    <div className='register-month-date'>
                      <p className="-registercard-subtitle">{pet.breed}</p>
                      <p className="register-card-age">{pet.petAge}</p>
                      <p className="register-card-type">{pet.petGender}</p>
                    </div>

                    <div className="register-Profile-Completion">
                      <div className="profile-header">
                        <p>Profile Completion</p>
                        <p>{progress}%</p>
                      </div>
                      <div className="progress-bar-container">
                        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
                      </div>
                    </div>

                    <div className="register-icon-text">
                      <span className="thumb-icon"><FaRegThumbsUp />
                      </span>
                      <p className="matches-text">You Got 69 Matches</p>
                    </div>

                    <div>
                      <button className='register-btn-card'>
                        Find Matches
                      </button>
                    </div>


                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="register-right">
            <div className="plus-box" onClick={openModal}>
              <div className="plus-icon">+</div>
              <div className="register-plus-icon-text">Add Another Pet</div>
            </div>
          </div>
        </div>
      </div>

      <Profile_Post_Pets isAddPopupOpen={isModalOpen} setIsAddPopupOpen={closeModal} />

    </>
  );
};

export default Profile_Register;


// jws exygex
