import React, { useState } from "react";
import close from "../../../assets/images/close2.png";
import img from "../../../assets/images/petdetailsimg.png";
import ownerpic from "../../../assets/images/ownerpic.png";
import { BACKEND_URL, formatDate } from "../../../Utilities/Constant";

import styles from "./LostAndFoundHomePage.module.css";
import {
  ChatIcon,
  LikeIcon,
  LocationIcon,
  CrossIcon,
} from "../../../assets/SVG";

const FoundPetDetails = ({ isOpen, onClose, pet, onMessageClick }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.petdetailspopupoverlay}>
      <div className={styles.petDetailsPopupcontent}>
        <div className={styles.crossButton} onClick={onClose}>
          <CrossIcon />
        </div>
        <div className={styles.petDetaildsPopupHearder}>
          <img
            src={pet.petImage ? `${BACKEND_URL}${pet.petImage}` : img}
            alt="Pet"
            style={{ width: "100%", height: "50%" }}
          />
        </div>
        <div className={styles.petDetailsPopupContentText}>
          <div>
            <h2 className={styles.petDetailsPetName}>{pet.breed}</h2>

            <div style={{ display: "flex", width: "100%" }}>
              <div className={styles.petDetailspetId}>Pet ID: {pet.petId}</div>
              <div className={styles.petDetailsgender}>
                {" "}
                | Gender: {pet.petType}
              </div>
            </div>
            <div className={styles.petDetailsAerafound}>
              Aera Found : {pet.foundLocation}
            </div>
          </div>
          <div className={styles.petDetailsunderLine}></div>
          <div className={styles.petDetailsDetails}>
            <div className={styles.petDetailsDetailstext}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt,
              facere unde sed suscipit a, temporibus voluptas explicabo cumque
              aspernatur obcaecati natus est autem doloribus dolor ipsam animi
              consectetur, voluptatem aliquam!
            </div>
          </div>
          <div className={styles.petDetailsunderLine}></div>
          <div className={styles.petOwnerDetails}>Details</div>
          <div style={{ display: "flex" }} className={styles.petOwnerprofile}>
            <img src={ownerpic} style={{ marginBottom: "10px" }}></img>
            <div style={{ marginLeft: "10px", marginTop: "5px" }}>
              <p className={styles.petDetailProfileName}>
                {pet.createdBy?.name}
              </p>
              <p className={styles.petDetailProfileloction}>{pet.address}</p>
              <p className={styles.petDetailProfileMobile}>
                Mobile No.: {pet.mobileNumber}
              </p>
            </div>
          </div>
          <div className={styles.petDetailsunderLine}></div>
          <div className={styles.petDetailflexContainer}>
            <p className={styles.petDetailFoundDate}>
              Date of Found: 02 Oct 2024
            </p>
            <p className={styles.petDetailTime}>Last Active: 59 sec ago</p>
          </div>
          <div className={styles.petDetailbutton} onClick={onMessageClick}>
            Message
          </div>

          <div></div>
        </div>
      </div>
    </div>
  );
};

export default FoundPetDetails;
