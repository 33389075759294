import React from "react";
import LeftImage from   "../../assets/images/right.png"; 
import RightImage from  "../../assets/images/left.png";
import styles from './Hero.module.css';
import AboutUs from "../About/AboutUs";
// import OurServices from '../OurServices/Services'
import Services from "../OurServices/Services";
import BookingSteps from "../BookingSteps/BookingSteps";
import Testimonials from "../Testimonials/Testimonials";
import Community from "../Community/Community";
import ServiceFeatures from "../Header/ServiceFeatures";

const Hero = () => {
  return (
    <>
      {/* <section className={styles.hero}>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c90b3a81e35eb677cb823dca56a972f6b821ac6db2570e8fe4ab08d65fd11f58?apiKey=3e99c58a56f84e4cb0d84873c390b13e&" alt="Hero background" className={styles.heroImage} />
      </section> */}
      <section className={styles.hero}>
        <div className={styles.heroContent}>
      </div>
    <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/c90b3a81e35eb677cb823dca56a972f6b821ac6db2570e8fe4ab08d65fd11f58?apiKey=3e99c58a56f84e4cb0d84873c390b13e&" alt="Happy pets" className={styles.heroImage} />
  </section>
    <AboutUs/>
    <Services/>
    <BookingSteps/>
    <Testimonials/>
    <Community/>
    </>
  );
};

export default Hero;
