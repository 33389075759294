import React from 'react';
import styles from './Testimonials.module.css';

const TestimonialCard = ({ image, name, petName, content, rating }) => (
  <div className={styles.testimonialCard}>
    <img src={image} alt={name} className={styles.testimonialImage} />
    <div className={styles.testimonialContent}>
      <h3 className={styles.testimonialName}>{name}</h3>
      <p className={styles.testimonialPetName}>Parent of {petName}</p>
      <p className={styles.testimonialText}>{content}</p>
      <div className={styles.testimonialRating}>
        {[...Array(rating)].map((_, i) => (
          <img
            key={i}
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8ae176d9e2df0ffdf227352eb9c0524c96be4914c357af1083cf58d974ed7e09?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
            alt="Star"
            className={styles.starIcon}
          />
        ))}
      </div>
    </div>
  </div>
);

const Testimonials = () => (
  <section className={styles.testimonials}>
    <h2 className={styles.sectionTitle}>Happy Parents</h2>
    <div className={styles.testimonialsContainer}>
      <TestimonialCard
        image="https://cdn.builder.io/api/v1/image/assets/TEMP/4f1b9bded9c39596fd92e63b986a68134a949a376fb6e091761fa3dd04963987?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
        name="Jane"
        petName="Fluffy"
        content="Trust me when I say this; the service was top-notch, and the groomers were extremely friendly."
        rating={5}
      />
      <TestimonialCard
        image="https://cdn.builder.io/api/v1/image/assets/TEMP/f570e215c4357005744eb2d0b03bbbe1e95c884062dd4132bc8fe82839218faa?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
        name="Jane"
        petName="Fluffy"
        content="Fluffy's day out at the Zaanvar Experience Center was exceptional. Trust me when I say this; the service was top-notch, and the groomers were extremely friendly."
        rating={5}
      />
      <TestimonialCard
        image="https://cdn.builder.io/api/v1/image/assets/TEMP/dc40ce333ffab50bc032cc2bae6fd94dc52b8d901ecc70af7382328ed3937ae3?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
        name="Jane"
        petName="Fluffy"
        content="Trust me when I say this; the service was top-notch, and the groomers were extremely friendly."
        rating={5}
      />
      
      
    </div>
  </section>
);

export default Testimonials;
