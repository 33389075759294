import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import home from "../../../assets/Social_Media_Icons/home.svg";
import createIcon from "../../../assets/Social_Media_Icons/create.svg";
import notification from "../../../assets/Social_Media_Icons/notifications.svg";
import profile from "../../../assets/Social_Media_Icons/profile.svg";

import "../SocialMedia.css";
import SocialMedia from "../SocialMedia.js";
import SocialMediaSettings from "./SocialMediaSettings.js";
import Header from "../../Header/Header.jsx";
import ImagePollModal from "../Create/ImagePollModal .js";
import TextPollModal from "../Create/TextPollModal .js";
import ImagePostModal from "../Create/ImagePostModal.js";
import NotificationSocialMedia from "./NotificationSocialMedia";
import SearchSocialMedia from "./SearchSocialMedia.js";
import { WebApimanager } from "../../../WebApiManager.js";

const SocialMediaRoutes = () => {
  const [activeTab, setActiveTab] = useState("home");
  const [searchQuery, setSearchQuery] = useState("");
  const [modalOpen, setModalOpen] = useState({
    imagePoll: false,
    textPoll: false,
    imagePost: false,
  });
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const handleSearchClick = () => {
    setActiveTab("search");
    setIsSearchOpen(!isSearchOpen);
    setIsNotificationOpen(false);
  };

  const renderNotificationPanel = () => (
    <div
      className={`notification-panel ${
        isNotificationOpen || isSearchOpen ? "open" : ""
      }`}
    >
      {isNotificationOpen ? (
        <NotificationSocialMedia notifications={notificationData} />
      ) : isSearchOpen ? (
        <SearchSocialMedia />
      ) : null}
    </div>
  );

  const openModal = (type) => {
    setModalOpen({
      imagePoll: false,
      textPoll: false,
      imagePost: false,
      [type]: true,
    });
  };

  const closeModal = () => {
    setModalOpen({ imagePoll: false, textPoll: false, imagePost: false });
    setActiveTab("home");
  };

  const webApi = new WebApimanager();

  const fetchSocialMediaNotificationData = async () => {
    try {
      const response = await webApi.get(
        "notification/all?department=Social%20Media"
      );
      const notifications = response.data?.data?.notifications || [];
      setNotificationData(notifications);
      const unread = notifications.filter((notif) => !notif.isRead).length;
      setUnreadCount(unread);
    } catch (error) {
      console.log("server error", error);
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchSocialMediaNotificationData();

    // Set interval for fast refetching
    const interval = setInterval(fetchSocialMediaNotificationData, 15000); // 15 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const handleNotificationClick = () => {
    setActiveTab("notification");
    setIsNotificationOpen(!isNotificationOpen);
    setIsSearchOpen(false);

    // Mark notifications as read
    const updatedNotifications = notificationData.map((notif) => ({
      ...notif,
      isRead: true,
    }));
    setNotificationData(updatedNotifications);
    setUnreadCount(0);
  };

  const renderSocialMediaPages = () => {
    switch (activeTab) {
      case "home":
      case "search":
      case "notification":
        return <SocialMedia searchQuery={searchQuery} />;
      case "profile":
        return <SocialMediaSettings />;
      default:
        return <SocialMedia searchQuery={searchQuery} />;
    }
  };

  return (
    <div>
      <Header />

      <div className="socialmedia-main">
        <div
          className={`socialmedia-left-side ${
            (activeTab === "notification" && isNotificationOpen) || isSearchOpen
              ? "collapsed"
              : ""
          }`}
        >
          <div className="socialmedia-sidebar">
            <div
              className={`socialmedia-sidebar-item ${
                activeTab === "home" ? "active" : ""
              }`}
              onClick={() => {
                setActiveTab("home");
                setIsNotificationOpen(false);
                setIsSearchOpen(false);
              }}
            >
              <img src={home} alt="Home" className="socialmedia-sidebar-icon" />
              <span className="socialmedia-sidebar-text">Home</span>
            </div>
            <div
              className={`socialmedia-sidebar-item ${
                activeTab === "search" ? "active" : ""
              }`}
              onClick={handleSearchClick}
            >
              <img
                src={notification}
                alt="notification"
                className="socialmedia-sidebar-icon"
              />
              <span className="socialmedia-sidebar-text">search</span>
            </div>

            <div
              className={`socialmedia-sidebar-item ${
                activeTab === "notification" ? "active" : ""
              }`}
              onClick={handleNotificationClick}
            >
              <img
                src={notification}
                alt="notification"
                className="socialmedia-sidebar-icon"
              />

              <span className="socialmedia-sidebar-text">
                Notifications{" "}
                {unreadCount > 0 && (
                  <span className="notification-badge">{unreadCount}</span>
                )}
              </span>
            </div>
            <div
              className={`socialmedia-sidebar-item ${
                activeTab === "profile" ? "active" : ""
              }`}
              onClick={() => {
                setActiveTab("profile");
                setIsNotificationOpen(false);
                setIsSearchOpen(false);
              }}
            >
              <img
                src={profile}
                alt="Profile"
                className="socialmedia-sidebar-icon"
              />
              <span className="socialmedia-sidebar-text">Profile</span>
            </div>
            <div>
              <div
                className={`socialmedia-sidebar-item ${
                  activeTab === "create" ? "active" : ""
                }`}
                onClick={() => {
                  setActiveTab("create");
                  setIsNotificationOpen(false);
                  setIsSearchOpen(false);
                }}
              >
                <img
                  src={createIcon}
                  alt="Create"
                  className="socialmedia-sidebar-icon"
                />
                <span className="socialmedia-sidebar-text">Create</span>
              </div>
              {activeTab === "create" && (
                <div className="create-bottom-options">
                  <form>
                    <div className="create-option">
                      <input
                        type="radio"
                        id="imagePoll"
                        name="createOption"
                        value="Image Poll"
                        onClick={() => openModal("imagePoll")}
                      />
                      <label htmlFor="imagePoll">Image Post</label>
                    </div>
                    <div className="create-option">
                      <input
                        type="radio"
                        id="textPoll"
                        name="createOption"
                        value="Text Poll"
                        onClick={() => openModal("textPoll")}
                      />
                      <label htmlFor="textPoll">Text Poll</label>
                    </div>
                    <div className="create-option">
                      <input
                        type="radio"
                        id="imagePost"
                        name="createOption"
                        value="Image Post"
                        onClick={() => openModal("imagePost")}
                      />
                      <label htmlFor="imagePost">Image Poll</label>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
        {renderNotificationPanel()}

        <div className="socialmedia-right-side">{renderSocialMediaPages()}</div>
      </div>

      <ImagePollModal isOpen={modalOpen.imagePoll} onClose={closeModal} />
      <TextPollModal isOpen={modalOpen.textPoll} onClose={closeModal} />
      <ImagePostModal isOpen={modalOpen.imagePost} onClose={closeModal} />
    </div>
  );
};

export default SocialMediaRoutes;
