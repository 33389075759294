import React, { useState } from 'react';

const ToggleSwitch = ({ checked, onChange }) => {
  const handleToggle = () => {
    onChange(!checked);  
  };

  return (
    <div
    onClick={handleToggle}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: checked ? 'flex-end' : 'flex-start',
        width: '30px',
        height: '17px',
        backgroundColor: 'white',
        borderRadius: '30px',
        padding: '0px 2px',
        cursor: 'pointer',
        transition: 'background-color 0.3s, justify-content 1s',
        border: '1px solid #4D4D4D',
      }}
    >
      <div
        style={{
          width: '12px',
          height: '12px',
          backgroundColor: '#4D4D4D',
          borderRadius: '50%',
        }}
      />
    </div>
  );
};

export default ToggleSwitch;
