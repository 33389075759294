import React from "react";
import InventoryChartsSection from "./Components/Dashboard/InventoryChartsSection";
// import GroomingDashboardTable from "./Components/Dashboard/GroomingDashboardTable";

export default function MedicineInventoryDashboard() {
  return (
    <>
      <div className="d-flex flex-column">
        <InventoryChartsSection />
        {/* <GroomingDashboardTable /> */}
      </div>
    </>
  );
}
