import React from "react";
import Chart from "react-apexcharts";

const PatientOverview = () => {
  const chartData = {
    series: [170, 457, 298, 525],
    options: {
      chart: {
        type: "polarArea",
      },
      labels: ["Child", "Teen", "Adult", "Old"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
      legend: {
        show: true,
        position: "bottom",
      },
    },
  };

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="polarArea"
        width={300}
      />
    </div>
  );
};

export default PatientOverview;
