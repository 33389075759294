import LandingPage from "../../Components/Tinder/LandingPage";
import TinderHomePage from "../../Components/Tinder/TinderHomePage";
import { Routes, Route } from 'react-router-dom';
import TinderMatches from "../../Components/Tinder/TinderMatches";
import EditPetPage from "../../Components/Tinder/EditPetPage/EditPetPage";

const TinderRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="home" element={<TinderHomePage />} />
        <Route path="matches" element={<TinderMatches />} />
        <Route path="edit" element = {<EditPetPage />} />

      </Routes>
    );
  };
  
  export default TinderRoutes;
  