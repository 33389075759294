import React, { useState } from "react";

import back1 from "../../../assets/images/backb1.png";
import warring from "../../../assets/images/warring.png";
import sorryforloss from "../../../assets/images/sorryforloss.png";
import sorryforlosslegs from "../../../assets/images/sorryforlosslegs.png";
import { WebApimanager } from "../../../WebApiManager";
import close from "../../../assets/images/clsoe-popup.png";



const ChangeStatus = ({ onClose, pet, onStatusChange, back }) => {
  const webApi = new WebApimanager();
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedOption, setSelectedOption] = useState("");
  const [showMessage, setShowMessage] = useState(true);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const getDropdownOptions = () => {
    if (pet && pet.petStatus === "Lost") {
      return ["Lost Pet Status", "Reunited", "Rehomed", "Deceased"]; 
    } else if (pet && pet.petStatus === "Found") {
      return [
        "Found Pet Status",
        "Reunited",
        "Rehomed",
        "Deceased",
        "Surrendered",
      ]; 
    } else {
      return []; 
    }
  };

  const dropdownOptions = getDropdownOptions();

  const handleChangeStatus = async () => {
    if (selectedOption === "Deceased") {
      setShowMessage(false);
  
      const payload = {
        petStatus: selectedOption,
      };
  
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
  
      try {
        // Conditionally set the URL based on pet status
        const url =
          pet && pet.petStatus === "Found"
            ? `lostAndFound/foundPetupdate/${pet._id}`
            : `lostAndFound/lostPetupdate/${pet._id}`;
  
        const response = await webApi.put(url, payload);
  
        if (response.data.status === "success") {
          alert("Pet status updated successfully");
        } else {
          if (response.data && response.data.message) {
            setErrorMessage(response.data.message);
          } else {
            setErrorMessage("An unexpected error occurred.");
          }
        }
      } catch (err) {
        console.error("Error occurred:", err);
        setErrorMessage("Something went wrong. Please try again later.");
      } finally {
        setApiProcessing({ loader: false, message: "" });
      }
    } else {
      onStatusChange(selectedOption);
      onClose();
    }
  };
  
  const handleBack = () => {
    back();
  };
  
  const handleClose = () => {
  
    window.location.reload();      
  };

  return (
    <>
      <div className="lost-and-found-post-popup-overlay">
        <div className="lost-and-found-post-changestatus-content">
          <div className="lost-and-found-popup-hearder">
            <div className="lost-and-found-popup-select-lost">
            {showMessage?(<button
                className="lost-and-found-post-changes-close"
                onClick={handleBack}
              >
                <img src={back1} alt="Back" />
              </button>
              ):( <button
                className="lost-and-found-post-popup-close"
                onClick={handleClose}
              >
                <img src={close} alt="Close" />
              </button>)}  
            </div>
          </div>
          <div className="lost-and-found-popup-body2">
            {showMessage ? (
              <div>
                <p className="changes-page-p1">
                  To change pet status, select the appropriate status from the
                  dropdown box below and click the “Change Status” Button
                </p>
                <p className="changes-page-p2">Pet Status *</p>
                <select
                  className="changes-status-dropdown"
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  {dropdownOptions.length > 0 ? (
                    dropdownOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))
                  ) : (
                    <option>No options available</option>
                  )}
                </select>
                <div
                  className="changeStatus-button"
                  onClick={handleChangeStatus}
                >
                  Change Status
                </div>
                <div className="changeStatus-warring">
                  <div className="changeStatus-warring-img">
                    <img
                      src={warring}
                      alt=""
                      style={{ width: "10px", height: "10px" }}
                    />
                  </div>
                  <p className="changeStatus-warring-text">
                  Updates take effect immediately. <br></br><span style={{color:"#727271",fontWeight: "600"}}>Any paid services and subscriptions will be immediately canceled.</span><br></br>

                  Selecting <span style={{color:"#727271",fontWeight: "600"}}>Reunited </span>will notify local shelters, vets & volunteers that Boomer is home safe.


                  </p>
                </div>
              </div>
            ) : (
              <div class="changes-container">
                <img src={sorryforloss} alt="" style={{ width: "240px" }} />
                <div class="legs-container">
                  <div>
                    <img
                      src={sorryforlosslegs}
                      alt=""
                      style={{ width: "75px" }}
                    />
                  </div>
                  <div>
                    <img
                      src={sorryforlosslegs}
                      alt=""
                      style={{
                        marginTop: "31px",
                        marginLeft: "-14px",
                        width: "75px",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeStatus;
