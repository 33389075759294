import React from "react";
import styles from './ui.module.css';

const DropDownv1 = ({ question, options, width = '45%', backgroundColor = 'rgba(243, 243, 243, 0.50)', value, onChange,CustomInputElementAddpet }) => {
    const handleChange = (e) => {
        if (onChange) {
            onChange(e.target.value); 
        }
    };

    return (
        <div
            className={styles.DropDownV1Div}
            style={{ flex: width === '45%' ? `1 1 ${width}` : '', width: width }}
        >
            <p style={{
                color:CustomInputElementAddpet?.color
            }}>{question}</p>
            <select
                style={{ backgroundColor: backgroundColor,marginTop:"14px",padding:".5rem .75rem"                }}
                value={value} 
                onChange={handleChange}
            >
                {options?.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DropDownv1;