import React, { useEffect, useState } from "react";
import "../SocialMedia.css";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { userInfo } from "../../../Atoms";
import { BACKEND_URL } from "../../../Utilities/Constant";

const SocialMediaPosts = () => {
  const webApi = new WebApimanager();
  const [fileArrays, setFileArrays] = useState([]);
  const [currentUserInfo] = useRecoilState(userInfo);
  const [activeIndices, setActiveIndices] = useState([]);

  useEffect(() => {
    const imageresponse = async () => {
      try {
        const userId = currentUserInfo?._id;
        if (userId) {
          const response = await webApi.get(
            `socialMedia/postsByUserId/${userId}?type=Image`
          );
          const posts = response.data.posts || [];
          const fileData = posts.map((post) => post.file || []);
          setFileArrays(fileData);
          setActiveIndices(fileData.map(() => 0));
        } else {
          console.log("User ID is not available");
        }
      } catch (error) {
        console.error(error);
      }
    };

    imageresponse();
  }, [currentUserInfo]);

  const handlePrev = (index) => {
    setActiveIndices((prevIndices) =>
      prevIndices.map((activeIndex, i) =>
        i === index
          ? activeIndex === 0
            ? fileArrays[index].length - 1
            : activeIndex - 1
          : activeIndex
      )
    );
  };

  const handleNext = (index) => {
    setActiveIndices((prevIndices) =>
      prevIndices.map((activeIndex, i) =>
        i === index
          ? activeIndex === fileArrays[index].length - 1
            ? 0
            : activeIndex + 1
          : activeIndex
      )
    );
  };

  return (
    <div className="carousels-container">
      {fileArrays.length > 0 ? (
        fileArrays.map((fileArray, carouselIndex) => (
          <div key={carouselIndex} className="carousel-container">
            <div className="carousel">
              {fileArray.length > 0 ? (
                <div className="carousel-images">
                  {fileArray.map((image, index) => (
                    <img
                      key={index}
                      src={`${BACKEND_URL}${image}`}
                      alt={`Post ${carouselIndex + 1} - Image ${index + 1}`}
                      className={`carousel-image ${
                        index === activeIndices[carouselIndex]
                          ? "active"
                          : "inactive"
                      }`}
                      style={{
                        display:
                          index === activeIndices[carouselIndex]
                            ? "block"
                            : "none",
                        width: "300px",
                        height: "300px",
                      }}
                    />
                  ))}
                </div>
              ) : (
                <p>No images in this carousel</p>
              )}
              <button
                className="carousel-control-prev"
                onClick={() => handlePrev(carouselIndex)}
              >
                {"<"}
              </button>
              <button
                className="carousel-control-next"
                onClick={() => handleNext(carouselIndex)}
              >
                {">"}
              </button>
            </div>
          </div>
        ))
      ) : (
        <p>No carousels to display</p>
      )}
    </div>
  );
};

export default SocialMediaPosts;
