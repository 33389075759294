import React, { useState } from 'react'
import styles from "./GroomingServices.module.css"
import closeimg from "./GroomingImages/close.png"
import { WebApimanager } from '../../WebApiManager';
import { useNavigate } from 'react-router';
import CustomRadioButton from '../UI/CustomRadioButton';
import DropDownV1 from '../UI/DropDownv2';
import CustomInputElement from '../UI/CustomInputElement';
import CustomInputElementColor from '../UI/CustomInputElementColor';
import GroomingCard from './GroomingCard';
import GroomingCardDetails from './GroomingCardDetails';

const GroomingServices = () => {
    const webApi = new WebApimanager();
  const [petID, setPetID] = useState("");
  const [selectedRadioOption, setSelectedRadioOption] = useState("");
  const [petType, setPetType] = useState("Select");
  const [petname, setPetname] = useState("Select");

  const [breedType, setBreedType] = useState("Select");
  const [petColor, setPetColor] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedPet, setSelectedPet] = useState(null);
  const closePopup = () => setIsPopupOpen(false);
  const [isChatPageOpen, setIsChatPageOpen] = useState(false);
  const [showMyPosts, setShowMyPosts] = useState(false);

  const handleCardClick = (pet) => {
    setSelectedPet(pet);
    setIsPopupOpen(true);
  };

  const [filters, setFilters] = useState({
    petType: "Select",
    petname: "Select",
    petID: "",
    breedType: "Select",
    petColor: [],
    selectedRadioOption: "",
  });

  const navigator = useNavigate();
  const handlePetIDChange = (value) => {
    const upperCaseValue = value.toUpperCase();
    setPetID(upperCaseValue);
    setFilters((prevFilters) => ({
      ...prevFilters,
      petID: upperCaseValue,
    }));
  };

  const handleRadioChange = (option) => {
    setSelectedRadioOption(option);
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedRadioOption: option,
    }));
  };

  const handleSelectChange = (name, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const clearAllFilters = () => {
    setPetID("Select");
    setSelectedRadioOption("");
    setPetType("Select");
    setPetname("Select");
    setBreedType("Select");
    setPetColor([]);
    setFilters({
      petType: "Select",
      petname: "Select",
      breedType: "Select",
      petColor: [],
      petID: "",
      selectedRadioOption: "",
    });
  };

  const clearFilter = (filterName) => {
    const updatedFilters = {
      ...filters,
      [filterName]:
        filterName === "selectedRadioOption" || filterName === "petID"
          ? ""
          : "Select", // Reset petID to ""
    };

    if (filterName === "selectedRadioOption") setSelectedRadioOption("");
    if (filterName === "petType") setPetType("Select");
    if (filterName === "petname") setPetname("Select");

    if (filterName === "petID") setPetID("");
    if (filterName === "breedType") setBreedType("Select");
    if (filterName === "petColor") {
      setPetColor([]);
      updatedFilters.petColor = [];
    }

    setFilters(updatedFilters);
  };

  const mypost = () => {
    setShowMyPosts(true);
    // navigator("/lost-and-found/HomePage/myPost", {
    //   state: { showMyPosts: true },
    // });
  };

  const hasFilters =
    filters.selectedRadioOption ||
    filters.petType !== "Select" ||
    filters.petname !== "Select" ||
    filters.breedType !== "Select" ||
    filters.petID !== "" ||
    filters.petColor.length > 0;

  const [isChatPopupOpen, setIsChatPopupOpen] = useState(false);

  const closeChatPopup = () => setIsChatPopupOpen(false);

  const handleMessageClick = () => {
    setIsPopupOpen(false);
    setIsChatPopupOpen(true);
  };

  const [petData, setPetData] = useState([]);
  const [breedNames, setBreedNames] = useState([]);
  const [breedTypes, setBreedTypes] = useState([]);

//   useEffect(() => {
//     webApi
//       .get("lostAndFound/allPets")
//       .then((response) => {
//         const responseData = response.data;
//         const foundPets = responseData.data.foundPets || [];
//         const lostPets = responseData.data.lostPets || [];

//         const allPets = [...foundPets, ...lostPets];

//         setPetData(allPets);
//         const uniqueBreeds = [...new Set(allPets.map((pet) => pet.breed))];
//         setBreedNames(uniqueBreeds);
//         const uniqueBreeds2 = [...new Set(allPets.map((pet) => pet.petType))];
//         setBreedTypes(uniqueBreeds2);
//       })
//       .catch((error) => {
//         console.error("Error fetching My Posts data:", error);
//       });
//   }, [showMyPosts]);
  return (
    <>
        <section className={styles.LostAndFoundHomePage}>
        <div>
          {/* <Header /> */}
        </div>
        <div className={styles.sortDiv}>
          <div
            style={{
              paddingRight: "60px",
              borderRight: "1px solid #F0F1F6",
            }}
            id="lost-and-found-sort"
          >
            <h1>Sort By</h1>
          </div>

          <div style={{ display: "flex" }}>
            {filters.selectedRadioOption && (
              <div>
                <p id="lost-found-selecedoption">
                  {filters.selectedRadioOption}
                  <span
                    style={{ marginTop: "6px", marginLeft: "8px" }}
                    onClick={() => clearFilter("selectedRadioOption")}
                  >
                    <img src={closeimg} alt="close" />
                  </span>
                </p>
              </div>
            )}
            {filters.petType !== "Select" && (
              <div>
                <p id="lost-found-selecedoption">
                  {filters.petType}
                  <span
                    style={{ marginTop: "6px", marginLeft: "8px" }}
                    onClick={() => clearFilter("petType")}
                  >
                    <img src={closeimg} alt="close" />
                  </span>
                </p>
              </div>
            )}
            {filters.petID !== "" && (
              <div>
                <p id="lost-found-selecedoption">
                  {filters.petID}
                  <span
                    style={{ marginTop: "6px", marginLeft: "8px" }}
                    onClick={() => clearFilter("petID")}
                  >
                    <img src={closeimg} alt="close" />
                  </span>
                </p>
              </div>
            )}
            {filters.petname !== "Select" && (
              <div>
                <p id="lost-found-selecedoption">
                  {filters.petname}
                  <span
                    style={{ marginTop: "6px", marginLeft: "8px" }}
                    onClick={() => clearFilter("petname")}
                  >
                    <img src={closeimg} alt="close" />
                  </span>
                </p>
              </div>
            )}
            {filters.breedType !== "Select" && (
              <div>
                <p id="lost-found-selecedoption">
                  {filters.breedType}
                  <span
                    style={{ marginTop: "6px", marginLeft: "8px" }}
                    onClick={() => clearFilter("breedType")}
                  >
                    <img src={closeimg} alt="close" />
                  </span>
                </p>
              </div>
            )}
            {petColor.length > 0 && (
              <div>
                <p id="lost-found-selecedoption">
                  {petColor.join(", ")}
                  <span
                    style={{ marginTop: "6px", marginLeft: "8px" }}
                    onClick={() => clearFilter("petColor")}
                  >
                    <img src={closeimg} alt="close" />
                  </span>
                </p>
              </div>
            )}
          </div>

          {hasFilters && (
            <button className={styles.clearButton} onClick={clearAllFilters}>
              Clear All
            </button>
          )}
        </div>

        <div style={{ display: "flex" }}>
          <div className={styles.filtersDiv}>
            <CustomRadioButton
              question={"Select Pet Type"}
              options={["Dog","Cat","Rabbit","abc","abc","abc","abc","abc"]}
              value={selectedRadioOption}
              onChange={handleRadioChange}
            />
            <CustomRadioButton
              question={"Select Place"}
              options={["Store","Home"]}
              value={selectedRadioOption}
              onChange={handleRadioChange}
            />
            <CustomRadioButton
              question={"Select Service"}
              options={["Grooming","Nail Cut","Bathing"]}
              value={selectedRadioOption}
              onChange={handleRadioChange}
            />
            
          </div>

          <div className={styles.cardlistDiv}>
            
            <GroomingCard
              filters={filters}
              onCardClick={handleCardClick}
              onChatClick={() => setIsChatPopupOpen(true)}
              showMyPosts={showMyPosts}
            />

            <GroomingCardDetails
              isOpen={isPopupOpen}
              onClose={closePopup}
              // pet={selectedPet}
              // onMessageClick={handleMessageClick}
            />
          
          </div>
        </div>

        <div>
          {/* <Footer /> */}
        </div>
      </section>
    </>
  )
}

export default GroomingServices