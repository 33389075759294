import React from 'react';
import styles from './AboutUs.module.css';

const AboutUs = () => (
  <section className={styles.aboutUs}>
    <div className={styles.imageContainer}>
      <div className={styles.imageWrapper}>
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e867030cb55573913ed76c38716629e45525c09002b151896920846882ec4608?apiKey=3e99c58a56f84e4cb0d84873c390b13e&" alt="About us image 2" className={styles.imageOne} />
      </div>
      <div className={styles.imageWrapper}>
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/75aa2133a28f79bdcc3b7ac24e7053c7b75ecf4f75f14ed54f0a5a378c84d2db?apiKey=3e99c58a56f84e4cb0d84873c390b13e&" alt="About us image 1" className={styles.imageTwo} />
      </div>
    </div>
    <div className={styles.content}>
      <h2 className={styles.title}>About us</h2>
      <p className={styles.description}>
        Zaanvar is a chain of pet clinics that provide top-notch quality pet care services in India to solve pet health problems that arise due to appalling management of pets and the wrong treatment given by unqualified and inexperienced persons.
        <br /><br />
        We are one of the most trusted veterinary services providers in India with a motto to make these services accessible not only in urban but rural areas as well and to make it easier and comfortable for pet parents to take care of their pets.
      </p>
      <button className={styles.readMoreButton}>Read more</button>
    </div>
  </section>
);

export default AboutUs;


