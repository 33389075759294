import React, { useState,useEffect } from "react";
import zaanvarlogo from "../../assets/images/ZAANVAR_FINAL LOGO.png";
import bgimg from "../../assets/images/Rectangle 23.png";
import { WebApimanager } from "../../WebApiManager";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const Otp = () => {
  let webApi = new WebApimanager();
  const navigate = useNavigate();

  
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(""); // State to store OTP input
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [error, setError] = useState(null); // State to manage error messages

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location]);



  const handleOtpChange = (e) => {
    setOtp(e.target.value); // Update OTP state on input change
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);
    setError(null);
  
    const payload = { email: email, confirmationCode: otp };
  
    try {
      // Assuming WebApimanager works similarly to axios
      const response = await webApi.post("users/confirmForgotPassword", payload);
  
      // Check if the backend response's "status" is "success"
      if (response.data.status === "success") {
        navigate("/resetpwd", { state: { email: email } }); // Redirect to reset password page
      } else {
        // Handle error message from the response
        setError(response.data.message || "Something went wrong. Please try again.");
      }
    } catch (err) {
      // Handle errors from the API request
      setError(
        err.response
          ? err.response.data.message
          : "Failed to send OTP. Please check your internet connection."
      );
    } finally {
      setLoading(false); // Reset loading state
    }
  };
  

  return (
    <div className="otp-Header">
      <div className="otp-containerbody otp-containeronly">
        <div className="otp-container">
          <div className="otp-left-side">
            <div className="otp-zaanvar-icon">
              <img src={zaanvarlogo} style={{ width: "100px" }} alt="Logo" />
            </div>

            <div style={{ position: "relative", left: "70px" }}>
              <form className="otp-form" onSubmit={handleSubmit}>
                <div>
                  <p style={{ fontSize: "13px" }}>Verify Code </p>
                </div>
                <div className="otp-l1">
                  <label>An authentication code has been sent to your email.</label>
                </div>
                <label
                  style={{
                    marginBottom: "10px",
                    marginTop: "25px",
                    fontSize: "11px",
                  }}
                >
                  Enter code
                </label>
                <input
                  type="text"
                  className="fp-field"
                  placeholder="Enter your Code"
                  value={otp} // Bind OTP input value to state
                  onChange={handleOtpChange} // Update OTP state on input change
                  required
                />
                <div
                  style={{
                    marginTop: "-10px",
                    marginLeft: "70px",
                    marginBottom: "15px",
                  }}
                >
                  <label style={{ fontSize: "8px", marginTop: "-15px" }}>
                    Didn’t Receive a code?{" "}
                    <label style={{ color: "#F5790C" }}>RESEND</label>
                  </label>
                </div>
                <button
                  type="submit"
                  className="login-button"
                  style={{ marginTop: "20px", borderRadius: "5px" }}
                  disabled={loading} // Disable button when loading
                >
                  {loading ? "Verifying..." : "VERIFY"}
                </button>
              </form>
              {error && <p style={{ color: "red", fontSize: "10px" }}>{error}</p>}
              <div>
                <p
                  style={{
                    fontSize: "10px",
                    paddingTop: "33px",
                    marginBottom: "10px",
                  }}
                  className="otp-bottom"
                >
                  Need help?
                </p>
                <label
                  style={{ fontSize: "9px", width: "80%" }}
                  className="otp-bottom"
                >
                  If you cannot receive the code or if you changed your email or
                  phone number,{" "}
                  <span style={{ color: "#F5790C", fontSize: "9px" }}>
                    Try a different way.
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div className="otp-img">
            <img src={bgimg} className="otp-fixed-bg-image" alt="Background" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
