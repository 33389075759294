import React, { useState } from "react";
import close from "../../../assets/images/back-chat.png";
import profilepic from "../../../assets/images/profile-pic2.png";
import menu from "../../../assets/images/menu1.png";
import msgcorrect from "../../../assets/images/msgcorrect.png";
import sendimg from "../../../assets/images/sendimg.png";
import Report from "../../../assets/images/Report.png";
import deleteicon from "../../../assets/images/delete.png";
import block from "../../../assets/images/block.png";
import { BackIconBlack,  CrossIconBlack,  MenuIcon, SendIcon, SentIcon,ProfileIcon } from '../../../assets/SVG';



import styles from "./LostAndFoundHomePage.module.css";
import { Form } from "react-router-dom";

const SingleChatPage = ({ isOpen, onClose }) => {
  const [menuOpen, setMenuOpen] = useState(false); 

  if (!isOpen) return null;

  return (
    <div className={styles.petdetailspopupoverlay}>
    <div className={styles.lostAndFoundChats}>
      {/* Header - Fixed at the Top */}
      <div className={styles.LostAndFoundSingleChathearder}>
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <button
            className={styles.lostAndFondsingleChatclose}
            onClick={onClose}
          >
            <img src={close} alt="Close" className={styles.chatimg} />
          </button>
  
          <img
            src={profilepic}
            alt="Profile"
            style={{ width: "45px", marginRight: "10px" }}
          />
          <div style={{ marginRight: "auto" }}>
            <p
              style={{ marginTop: "-8px", marginBottom: "3px" }}
              className={styles.SingleChatName}
            >
              Rahul
            </p>
            <p style={{ margin: 0 }} className={styles.SingleChatOnline}>
              Online
            </p>
          </div>
  
          <div
            style={{
              marginRight: "10px",
              marginTop: "-5px",
              position: "relative",
            }}
          >
            <img
              src={menu}
              alt="Menu"
              style={{ cursor: "pointer" }}
              onClick={() => setMenuOpen((prev) => !prev)}
            />
            {menuOpen && (
              <div className={styles.SingleChatChatmenuDropdown}>
                <ul>
                  <li onClick={() => console.log("Report clicked")}>
                    <img
                      src={Report}
                      style={{ position: "absolute", top: "13px" }}
                    />
                    Report
                  </li>
                  <li onClick={() => console.log("Delete clicked")}>
                    <img
                      src={deleteicon}
                      style={{
                        display: "flex",
                        position: "absolute",
                        paddingTop: "5px",
                      }}
                    />
                    Delete
                  </li>
                  <li onClick={() => console.log("Block clicked")}>
                    <img
                      src={block}
                      style={{
                        display: "flex",
                        position: "absolute",
                        paddingTop: "5px",
                      }}
                    />
                    Block
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
  
      {/* Chat Body - Scrollable */}
      <div className={styles.lostAndFoundChatPopupBody}>
        <div className={styles.SingleChatChatBody}>
          {/* Messages */}
          <div style={{ display: "flex" }}>
            <div>
              <img src={profilepic} alt="" />
            </div>
            <div className={styles.SingleChatChatbodyDIv}>
              <p className={styles.SingleChatChatmsgs}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <div className={styles.SingleChatChatbodyDIv}>
              <p className={styles.SingleChatChatmsgs}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
            </div>
            <div>
              <img src={msgcorrect} alt="" style={{ paddingTop: "12px" }} />
            </div>
          </div>
          {/* Add more messages as needed */}
        </div>
      </div>
  
      {/* Input - Fixed at the Bottom */}
      <div className={styles.inputElement}>
        <input placeholder="Send Message" />
        <div style={{ cursor: "pointer" }}>
          <SendIcon />
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default SingleChatPage;
