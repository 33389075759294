import React, { useEffect, useState } from "react";
import styles from "./Tinder.module.css";
import { petInfoForMatches } from "./constants";
import PetProfileCard from "./PetProfileCard";
import { BackIcon, Chaticon, SettingsIcon } from "../../assets/SVG";
import { useNavigate, useLocation } from "react-router";
import FiltersPopup from "./Popups/FiltersPopup/FiltersPopup";
import PetInfoPopup from "./Popups/PetInfoPopup/PetInfoPopup";
import ChatPopup from "./Popups/ChatPopup/ChatPopup";
import { WebApimanager } from "../../WebApiManager";

const TinderMatches = () => {
  const navigate = useNavigate();
  const webApi = new WebApimanager();
  const location = useLocation(); 
  const selectedPet = location.state?.selectedPet || null;
  const oldPetId = location.state?.oldpetid || null;
  const [isFiltersPopupOpen, setIsFiltersPopupOpen] = useState(false);
  const [ispetInfoPopupOpen, setIspetInfoPopupOpen] = useState(false);
  const [isChatPopupOpen, setIsChatPopupOpen] = useState(false);
  const [tempPetInfo, setTempPetInfo] = useState([]);
  const [petData, setPetData] = useState([]);
  const [selectedPetInfo,setSelectedPetInfo]=useState(null)
  const [filters, setFilters] = useState({
    distance: 1370,
    size: "Select Size",
    pureBreedOnly: false,
    pedigreeCertifiedOnly: false,
    dnaCertifiedOnly: false,
  });
  const updateFilters = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };
  useEffect(() => {
    if (selectedPet) {
      webApi
        .get(
          `petProfile/visiblePets?petId=${selectedPet._id}&type=lover&petGender=${selectedPet.petGender}&petType=${selectedPet.petType}`
        )
        .then((response) => {
          const pets = response.data.data.petProfiles;
          console.log("pets",pets)
                    const filteredPets = pets.filter((pet) => {
            const matchesSize =
              filters.size === "Select Size" || pet.size === filters.size;
            const matchesPureBreed = !filters.pureBreedOnly || pet.pureBreed;
            const matchesPedigree =
              !filters.pedigreeCertifiedOnly || pet.pedigreeCertified;
            const matchesDNA = !filters.dnaCertifiedOnly || pet.dnaCertified;
  
            return matchesSize && matchesPureBreed && matchesPedigree && matchesDNA;
          });
  
          setTempPetInfo(filteredPets);

        })
        .catch((error) => {
          console.error("Error fetching pet data:", error);
        });
    }
  }, [selectedPet, filters]);
  const handleCardClick = (selectedPetData) => {
    setSelectedPetInfo(selectedPetData)
  };
  return (
    <>
      <section className={styles.TinderHomePage1}>
        <div className={styles.matchesHeaderDiv}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                onClick={() => navigate("/tinder/home")}
                style={{ cursor: "pointer" }}
              >
                <BackIcon />
              </div>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  margin: "0px",
                  marginLeft: "10px",
                }}
              >
                Matches for Victoria
              </p>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setIsFiltersPopupOpen(true)}
            >
              <SettingsIcon />
            </div>
          </div>
          <div style={{ display: "flex", gap: "15px", margin: "20px 0px" }}>
            {[
              `Within ${filters.distance} miles`,
              filters.pureBreedOnly ? "Pure breed" : null,
              filters.pedigreeCertifiedOnly ? "Pedigree Certificate" : null,
              filters.dnaCertifiedOnly ? "DNA Certified" : null,
              filters.size !== "Select Size" ? filters.size : null,
            ].map((item) => {
              return (
                item && (
                  <div className={styles.pillsDiv} key={item}>
                    <p>{item}</p>
                  </div>
                )
              );
            })}
          </div>
        </div>
        <div className={styles.profileCardDiv1}>
          {tempPetInfo.map((petData) => {
            {console.log("checkingpetdata",petData)}
            return (
              <PetProfileCard
                petData={petData}
                key={petData.id}
                type="match"
                setIspetInfoPopupOpen={setIspetInfoPopupOpen}
                setIsChatPopupOpen={setIsChatPopupOpen}
                oldPetId={oldPetId}
                onCardClick={handleCardClick}
              />
            );
          })}
        </div>
        <div
          className={styles.chatButton}
          onClick={() => setIsChatPopupOpen((prev) => !prev)}
        >
          <Chaticon />
        </div>
        <FiltersPopup
          isFiltersPopupOpen={isFiltersPopupOpen}
          setIsFiltersPopupOpen={setIsFiltersPopupOpen}
          updateFilters={updateFilters}
        />
        <PetInfoPopup
          ispetInfoPopupOpen={ispetInfoPopupOpen}
          setIspetInfoPopupOpen={setIspetInfoPopupOpen}
          selectedPetInfo={selectedPetInfo}
          
        />
        <ChatPopup
          isChatPopupOpen={isChatPopupOpen}
          setIsChatPopupOpen={setIsChatPopupOpen}
        />
      </section>
    </>
  );
};

export default TinderMatches;