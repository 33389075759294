import React, { useState, useRef, useEffect } from "react";
 import styles from "./Profile.module.css";
import { CrossIcon } from "../../assets/SVG";
import CustomInputElement from "../UI/CustomInputElement2";
import DropdownV1 from "../UI/DropDownV1";
import { WebApimanager } from "../../WebApiManager";
import { color } from "framer-motion";
import CutstomFileInput from "../UI/CustomFileInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Profile_Post_Pets = ({ isAddPopupOpen, setIsAddPopupOpen }) => {
  const webApi = new WebApimanager();
  const popupRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [image, setImage] = useState(null);
  const [breedOptions, setBreedOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState("No file chosen");
  const [selectedFilekci, setSelectedFilekci] = useState("No file chosen");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); 


  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });


  const [formData, setFormData] = useState({
    petName: "",
    petAge: "",
    petType: "",
    color: "",
    breed: "",
    additionalInfo: "",
    weight: "",
    vaccinated: null,
    kci: "",
    championship: "",
    Events: "",
    skills: "",
    instagramLink: "",
    petGender:"",
    petImage: "",
    vaccineCertificate:"",
    kciCertificate:""
  });
  const handleChange = async (e) => {
    const { name, value, type, files } = e.target;
  
    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
        if (name === "vaccineCertificate" && files[0]) {
        setSelectedFile(files[0].name);
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleChange2 = async (e) => {
    const { name, value, type, files } = e.target;
  

      if (name === "kciCertificate" && files[0]) {
        setSelectedFilekci(files[0].name); 
      
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    if (isAddPopupOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => document.body.classList.remove("no-scroll");
  }, [isAddPopupOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsAddPopupOpen(false);
        setCurrentStep(1);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsAddPopupOpen]);

  const handleInputChange = (field, value) => {
    if (field === "weight") {
        value = value.replace(/[^0-9]/g, ""); 
      }
      if (field === "petAge") {
        value = value.replace(/[^0-9]/g, "");
      }
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const getDogTypes = async () => {
    try {
      const dogResponse = await webApi.get("dogAndCat/dogTypes");
      if (dogResponse.status === 200) {
        const breedNames = dogResponse.data.data.map((breed) => breed.name);
        setBreedOptions(breedNames);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const getcatTypes = async () => {
      try {
          const dogResponse = await webApi.get("dogAndCat/catTypes");
          
          if (dogResponse.status === 200) {
              const breedNames = dogResponse.data.data.map((breed) => breed.name);
        setBreedOptions(breedNames);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
 
  const handleSubmit = async() => {
    console.log("Form Data:", formData,"file v",selectedFile,"file k",selectedFilekci);
    const {
        petName,
        petAge,
        petType,
        color,
        breed,
    additionalInfo,
    weight,
    vaccinated,
    kci ,
    championship ,
    Events ,
    skills ,
    instagramLink ,
    petGender,
    }=formData
    const payload = {
        petAge: petAge,
        petName: petName,
        petType: petType,
        breed: breed,
        additionalInfo:additionalInfo,
        weight:weight,
        vaccinated:vaccinated,
        petGender: petGender,
        kci:kci,
        color:color,
        championship:championship,
        Events:Events,
        skills:skills,
        instagramLink:instagramLink,
        petGender:petGender
      };
      const payloadimg={
        petImage:formData.petImage
      }
      const payloadvaccineCertificate={
        vaccineCertificate:formData.vaccineCertificate
      }
      const payloadkciCertificate={
        kci:formData.kciCertificate
      }
      setApiProcessing({
        loader: true,
        message: "Requesting...",
      });
      console.log(payload,"checking payload")
      try {
        const response = await webApi.post(
          `petProfile`,
          payload
        );
        const petId = response.data.data.petProfile._id;
        if (formData.petImage) {
       
            const imageUploadResponse = await webApi.imagePut(
              `petProfile/updatePetImage/${petId}`,
              payloadimg
            );
          }
          if(formData.vaccineCertificate){
            const imageUploadResponse = await webApi.imagePut(
                `petProfile/updatevaccineCertificate/${petId}`,
                payloadvaccineCertificate
              );

          } if(formData.kciCertificate){
            const imageUploadResponse = await webApi.imagePut(
                `petProfile/updateKci/${petId}`,
                payloadkciCertificate
            );
          }else if (response.status === 400) {
            if (response.data && response.data.message) {
              setErrorMessage(response.data.message);
            }
            setApiProcessing({ loader: false, message: "" });
          } else {
            setErrorMessage("An unexpected error occurred.");
            setApiProcessing({ loader: false, message: "" });
          }
      } catch (err) {
        console.error("Error occurred:", err);
        setError("Something went wrong. Please try again later.");
      } finally {
        setApiProcessing({ loader: false, message: "" });
        window.location.reload();
      }
  };


  return (
    <>
      {isAddPopupOpen && (
          <div className={styles.Background}>
          <div className={styles.popupContainer} ref={popupRef}>
            <div className={styles.header}>
              <p></p>
              <p style={{ margin: "0px" }}>Add New Pet</p>
              <div
                style={{ marginRight: "25px", cursor: "pointer" }}onClick={() => {setIsAddPopupOpen(false);setCurrentStep(1);}}>
                <CrossIcon />
              </div>
            </div>
            <div className={styles.mainContainer}>
              <div className={styles.addButtonDiv} onClick={() => document.getElementById("fileInput").click()}>
                {formData.petImage ? (                   
                  <img src={URL.createObjectURL(formData.petImage)} alt="Selected" className={styles.previewImg}/>
                ) : (
                  <p>+</p>
                )}
              </div>
              <input id="fileInput" type="file" name="petImage"  accept="image/*" style={{ display: "none" }} onChange={(e) => {handleChange(e);   }}/>
              <p style={{ fontSize: "16px", fontWeight: 500, color: "#909AA3" }}>
                Tap to Select Photo
              </p>      
              <CustomInputElement question="Pet Name" width="100%" backgroundColor="#FFFFFF" placeholder="Enter Your Pet Name Here" value={formData.petName} onChange={(e) => handleInputChange("petName", e.target.value)} CustomInputElement1={{ color: "#000000", borderBottom: "0px solid rgb(240, 241, 246)" }}/>
              <div className={styles.formDiv2} style={{borderBottom:"1px dashed #D7D7D7",    marginBottom: "30px"}}>
                <DropdownV1 options={["select", "Male", "Female"]} question={"Gender"} width="30%" backgroundColor="#FFFFFF" value={formData.petGender} onChange={(value) => handleInputChange("petGender", value)} CustomInputElementAddpet={{color:"#000000"}} />
                <CustomInputElement question={"Pet Age"} width="30%" backgroundColor="#FFFFFF" value={formData.petAge} onChange={(e) =>{     const numericValue = e.target.value.replace(/[^0-9]/g, ""); handleInputChange("petAge", numericValue)}} />
                <DropdownV1 options={["select", "Dog", "Cat"]} question={"Pet Type"} width="30%" backgroundColor="#FFFFFF" value={formData.petType} onChange={(value) => {
                    handleInputChange("petType", value);
                    if (value === "Dog") {
                      getDogTypes();
                    } else if (value === "Cat") {
                      getcatTypes();
                    } else {
                      setBreedOptions([]);
                    }
                  }}CustomInputElementAddpet={{color:"#000000"}}/>
                <CustomInputElement question={"Color"} width="30%" backgroundColor="#FFFFFF" value={formData.color} onChange={(e) => handleInputChange("color", e.target.value)} />
                <DropdownV1 options={["select", ...breedOptions]} question={"Breed"} width="30%" backgroundColor="#FFFFFF" value={formData.breed} onChange={(value) => handleInputChange("breed", value)} CustomInputElementAddpet={{color:"#000000"}}/>
                  <CustomInputElement question={"Weight"} width="30%" backgroundColor="#FFFFFF" value={formData.weight} onChange={(e) =>   {  const numericValue = e.target.value.replace(/[^0-9]/g, "");  handleInputChange("weight", numericValue)}}/>
                 </div>
                 <div style={{display: "flex",flexDirection: "column", alignItems: "flex-start", textAlign: "start", width: "100%", marginBottom:"40px"}}>
                       <label style={{color: "#000",fontFamily: "Poppins",fontSize: "24px",fontStyle: "normal",fontWeight: "500",lineHeight: "normal",textAlign: "start", display: "block", }}>
                            Additional Info
                        </label>
                 </div>
                 <div className={styles.formDiv2}>
                     <DropdownV1 options={["select","Yes","No"]} question={"Vaccine Certificate"} width="30%" backgroundColor="#FFFFFF" value={formData.vaccinated} onChange={(value) => handleInputChange("vaccinated", value)} CustomInputElementAddpet={{color:"#000000"}}/>
                     <DropdownV1 options={["select","Yes","No"]} question={"KCI"} width="30%" backgroundColor="#FFFFFF" value={formData.kci} onChange={(value) => handleInputChange("kci", value)} CustomInputElementAddpet={{color:"#000000"}}/>
                     <CustomInputElement question={"Skills"} width="30%" backgroundColor="#FFFFFF" value={formData.skills} onChange={(e) => handleInputChange("skills", e.target.value)}/>
                     <CustomInputElement question={"Championship"} width="30%" backgroundColor="#FFFFFF" value={formData.championship} onChange={(e) =>handleInputChange("championship", e.target.value)}/>
                     <CustomInputElement question={"Events"} width="30%" backgroundColor="#FFFFFF" value={formData.Events} onChange={(e) => handleInputChange("Events", e.target.value)}/>
                     <CustomInputElement question={"Instagram Link"} width="30%" backgroundColor="#FFFFFF" value={formData.instagramLink} onChange={(e) =>handleInputChange("instagramLink", e.target.value)}/>
                     <CustomInputElement question="Additional Information" width="100%" backgroundColor="#FFFFFF" placeholder="URL" value={formData.additionalInfo}  onChange={(e) =>handleInputChange("additionalInfo", e.target.value)}  />
                 {formData.vaccinated === "Yes" && (
                                                  <>
                                                <div style={{ display: "flex", alignItems: "center",  width: "100%", marginBottom:"40px"}}>
                                                        <p style={{margin: 0, whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis", width: "50%",}}>
                                                             Vaccine certificate
                                                        </p>
                                                        <label style={{display: "flex",alignItems: "center",   gap: "10px",marginLeft: "20px",width:"100%",border:"1px solid #D9D9D9"}}>
                                                 <div style={{backgroundColor: "#727271",color: "#FFFFFF",padding: "10px 20px",cursor: "pointer",fontSize: "16px",}}>
                                                             Choose File
                                                 </div>
                                                        <input type="file"style={{display: "none", }} name="vaccineCertificate"onChange={(e) => {handleChange(e);}}/>
                                                             <span style={{fontSize: "16px",color: "#727271",whiteSpace: "nowrap", overflow: "hidden",textOverflow: "ellipsis", maxWidth: "400px",}}>
                                                             {selectedFile}
                                                             </span>
                                                         </label>
                                                  </div>
                                                </>
                                            )}
                 {formData.kci === "Yes" && (
                                               <>
                                                <div style={{display: "flex",alignItems: "center", width: "100%",}}>
                                                    <p style={{margin: 0, whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis", width: "50%",}}>
                                                        KCI
                                                    </p>
                                         <label style={{display: "flex",alignItems: "center",gap: "10px",marginLeft: "20px",width:"100%",border:"1px solid #D9D9D9"}}>
                                                 <div style={{backgroundColor: "#727271",color: "#FFFFFF",padding: "10px 20px",cursor: "pointer",fontSize: "16px",}}>
                                                  Choose File
                                                 </div>
                                                 <input type="file"style={{display: "none", }} name="kciCertificate" onChange={(e) => {handleChange2(e);}}    />
                                                    <span style={{fontSize: "16px",color: "#727271",whiteSpace: "nowrap", overflow: "hidden",textOverflow: "ellipsis", maxWidth: "400px",}}>
                                                            {selectedFilekci}
                                                   </span>
                                         </label>
                                </div>

                             </>
                        )}
                <div style={{display: "flex",justifyContent: "center",width: "100%",}}>
                  <button className={styles.submitButton} onClick={handleSubmit}>
                  {apiProcessing.loader ? "Submitting..." : "Add Pet"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile_Post_Pets;
