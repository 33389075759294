import React from "react";
import { Card, CardBody, Image, Button } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import "./../../styles/CustomerStyles.css";
import CustomerNavbar from "../CustomerNavbar";
import * as Unicons from "@iconscout/react-unicons";
import da from "./../../../../assets/customer/services/vetHospital2.png";
import wPDF from "./../../../../assets/customer/services/Pw.pdf";

export default function DoctorAppointment() {
  const medicalServices = [
    "Veterinary Consultation",
    "Vaccinations",
    "Surgery (e.g., spaying, neutering, orthopedic surgeries)",
    "Dental Care (cleaning, extractions)",
    "Laboratory Testing (blood work, urinalysis)",
    "Radiology (X-rays, ultrasound)",
    "Microchipping (for pet identification)",
    "Chronic Disease Management (e.g., diabetes, arthritis)",
  ];

  const handleDownload = () => {
    const pdfUrl = wPDF;

    const link = document.createElement("a");
    link.href = pdfUrl;

    link.setAttribute("download", "file_name.pdf");

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <>
      <div className="CustomerDashboard">
        <div className="CustomerNavbar">
          <Card  width="100%">
            <CardBody>
              <CustomerNavbar />
            </CardBody>
          </Card>
        </div>
        <div className=" CustomerDashboardBody">
          <div className="WelcomeCard">
            <Card backgroundColor="#496989">
              <CardBody className="d-flex  align-items-center">
                <div className="col-lg-7 poppins-medium col-md-12 col-sm-12 col-xs-12 p-1">
                  <h3>Doctor Appointment</h3>
                  <p className="serviceDescription">
                    A Veterinarian is an animal healthcare professional who
                    diagnoses and treats injuries and illnesses in animals. They
                    use medical tools and diagnostic equipment like x-ray
                    machines and perform surgeries when necessary. Veterinarians
                    provide essential medical services for pets, similar to how
                    physicians care for humans. A Veterinarian is an animal
                    healthcare professional who diagnoses and treats injuries
                    and illnesses in animals. They use medical tools and
                    diagnostic equipment like x-ray machines and perform
                    surgeries when necessary. Veterinarians provide essential
                    medical services for pets, similar to how physicians care
                    for humans.
                  </p>
                  <h4>Medical Services</h4>
                  <ul>
                    {medicalServices.map((service, index) => (
                      <li key={index} className="serviceLi">
                        {service}
                      </li>
                    ))}
                  </ul>
                  <Button colorScheme="teal" size="md">
                    Book an appointment
                  </Button>
                </div>

                <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                  <Image src={da} className="rounded" />
                </div>
              </CardBody>
            </Card>
          </div>
          <br />
          <div className="InfoCard">
            <Card backgroundColor="lightblue">
              <CardBody>
                <Accordion allowToggle>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          className="sessionTitle"
                        >
                          General Checkup for Scooby
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <div className="d-flex justify-content-between p-1">
                        <p className="col-lg-9 upcomingEventCardClinicName">
                          <Unicons.UilBuilding className="d-inline mx-1" />
                          Clinic Name <br />
                          <Unicons.UilLocationPoint className="d-inline mx-1" />
                          Gachibowli, Hyderabad
                        </p>
                        <p className="col-lg-3">
                          <Unicons.UilCalender className="d-inline mx-1" />
                          06-04-2024 <br />
                          <Unicons.UilClock className="d-inline mx-1" />
                          06:00 PM{" "}
                        </p>
                      </div>
                      <button onClick={handleDownload}>Download PDF</button>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
