const BACKEND_URL = "https://dev-api.zaanvar.com/api/";
// const BACKEND_URL = "http://localhost:5000/api/";
export { BACKEND_URL };
export const formatDate = (dateString) => {
  if (!dateString) return "-";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
};
