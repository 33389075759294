import React, { useState } from "react";
import styles from "./Services.module.css";
import ServiceButton from "./ServiceButton";
import ServiceCard from "./ServiceCard";
import ArrowIconRight from "../icons/ArrowIconRight";
import ArrowIconLeft from "../icons/ArrowIconLeft";

const Services = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [direction, setDirection] = useState("next");

  const handleNext = () => {
    if (currentPage < 3) {
      setDirection("next");
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setDirection("prev");
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const getSlideClass = (pageIndex) => {
    if (currentPage === pageIndex) {
      return direction === "next" ? styles.slideInRight : styles.slideInLeft;
    } else {
      return direction === "next" ? styles.slideOutRight : styles.slideOutLeft;
    }
  };

  return (
    <section className={styles.services}>
      <h2 className={styles.sectionTitle}>Our Services</h2>
      <div style={{ position: "relative" }}>
        {/* Carousel Page 1 */}
        <div
          className={`${getSlideClass(0)}`}
          style={{ display: currentPage === 0 ? "block" : "none" }}
        >
          <div className={styles.servicesGrid}>
            <div className={styles.serviceCardBig}>
              <img
                src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F986009c7892848a4b2b84db6714d644e"
                className={styles.serviceImage}
              />
              <div className={styles.serviceCardBigTextBox}>
                <h1>Day Care</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  enim augue, congue a maximus at, porta non nisi.
                </p>
                <button>Read more</button>
              </div>
            </div>
            <div className={styles.serviceCardsGrid}>
              {/* Small cards */}
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F217ef0ddec6241d1b4b553a2429f4533"
                  className={styles.serviceImage}
                />
                <p>Grooming</p>
              </div>
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F15d105560f494d5e8a38e8ab66465b0d"
                  className={styles.serviceImage}
                />
                <p>Clinics</p>
              </div>
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F393f012c811441708970a151533a6664"
                  className={styles.serviceImage}
                />
                <p>Training</p>
              </div>
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2Fd30525376d8a4499aaa266bfdbf050e7"
                  className={styles.serviceImage}
                />
                <p>Mating</p>
              </div>
            </div>
          </div>
        </div>

        {/* Carousel Page 2 */}
        <div
          className={`${getSlideClass(0)}`}
          style={{ display: currentPage === 1 ? "block" : "none" }}
        >
          <div className={styles.servicesGrid}>
            <div className={styles.serviceCardBig}>
              <img
                src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F24ed9903dafb411bb4bf018597f90315"
                className={styles.serviceImage}
              />
              <div className={styles.serviceCardBigTextBox}>
                <h1>Found & Missing</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  enim augue, congue a maximus at, porta non nisi.
                </p>
                <button>Read more</button>
              </div>
            </div>
            <div className={styles.serviceCardsGrid}>
              {/* Small cards */}
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2Ff9d9c9231c1142bf9a4974918816235a"
                  className={styles.serviceImage}
                />
                <p>Pet Sitter / Walker</p>
              </div>
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F8fd1800ec8734122a8837f336958d2b4"
                  className={styles.serviceImage}
                />
                <p>Blood Bank</p>
              </div>
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F90699c25706144c5ae4b00c26415aa11"
                  className={styles.serviceImage}
                />
                <p>E-Report</p>
              </div>
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2Fe66b6d15d3a74c96b1ada92688f6555c"
                  className={styles.serviceImage}
                />
                <p>Nutrition Diet</p>
              </div>
            </div>
          </div>
        </div>

        {/* Carousel Page 3 */}
        <div
          className={`${getSlideClass(0)}`}
          style={{ display: currentPage === 2 ? "block" : "none" }}
        >
          <div className={styles.servicesGrid}>
            <div className={styles.serviceCardBig}>
              <img
                src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F80de40e202034a68945e007c37c9c973"
                className={styles.serviceImage}
              />
              <div className={styles.serviceCardBigTextBox}>
                <h1>Day Care</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  enim augue, congue a maximus at, porta non nisi.
                </p>
                <button>Read more</button>
              </div>
            </div>
            <div className={styles.serviceCardsGrid}>
              {/* Small cards */}
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F06f11bfe0f404eed8eb9028eac2d6f17"
                  className={styles.serviceImage}
                />
                <p>Pet Diary</p>
              </div>
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F7cfd3a95d7a84a82b1e1cd9d75adef74"
                  className={styles.serviceImage}
                />
                <p>Locations</p>
              </div>
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F7148771d010b40bfbb8324bd3c0909b7"
                  className={styles.serviceImage}
                />
                <p>Pet Breeds</p>
              </div>
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F07a76d6ce016488d8f8005571836b58d"
                  className={styles.serviceImage}
                />
                <p>Ride</p>
              </div>
            </div>
          </div>
        </div>

        {/* Carousel Page 4 */}
        <div
          className={`${getSlideClass(0)}`}
          style={{ display: currentPage === 3 ? "block" : "none" }}
        >
          <div className={styles.servicesGrid}>
            <div className={styles.serviceCardBig}>
              <img
                src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2Fec2566fabd024fc8ab024d85876a7a38"
                className={styles.serviceImage}
              />
              <div className={styles.serviceCardBigTextBox}>
                <h1>Pet & Animal NGOs</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  enim augue, congue a maximus at, porta non nisi.
                </p>
                <button>Read more</button>
              </div>
            </div>
            <div className={styles.serviceCardsGrid}>
              {/* Small cards */}
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F545f643b867746058f9c106da3fb990e"
                  className={styles.serviceImage}
                />
                <p>Events</p>
              </div>
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F8938e63ad5db4a4596712806f1402202"
                  className={styles.serviceImage}
                />
                <p>Cremation</p>
              </div>
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F545f643b867746058f9c106da3fb990e"
                  className={styles.serviceImage}
                />
                <p>Tinder</p>
              </div>
              <div className={styles.serviceCardSmall}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F8938e63ad5db4a4596712806f1402202"
                  className={styles.serviceImage}
                />
                <p>Insurance</p>
              </div>
            </div>
          </div>
        </div>

        {/* Carousel Navigation Buttons */}
        <button
          onClick={handlePrev}
          style={{
            backgroundColor: currentPage === 0 ? "#D9D9D9" : "#eb7c24",
            color: "white",
            border: "none",
            padding: "20px 20px",
            fontSize: "16px",
            cursor: "pointer",
            opacity: 1,
            position: "absolute",
            top: "45%",
            left: "5%",
          }}
        >
          <ArrowIconLeft
            style={{ height: "14px", width: "14px" }}
            currentPage={currentPage}
          />
        </button>
        <button
          onClick={handleNext}
          style={{
            backgroundColor: currentPage === 3 ? "#D9D9D9" : "#eb7c24",
            color: "white",
            border: "none",
            padding: "20px 20px",
            fontSize: "16px",
            cursor: "pointer",
            opacity: 1,
            position: "absolute",
            top: "45%",
            right: "5%",
          }}
        >
          <ArrowIconRight currentPage={currentPage} />
        </button>
      </div>
    </section>
  );
};

export default Services;
