import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../Login";
import Forgotpassword from "../ForgotPassword/Forgotpassword";
import Navbar from "../Navbar/Navbar";
import ContactUs from "../Contact";
import CustomerSignupin from "../CustomerSignupin";
import CustomerDashboard from "../../Pages/Customer/CustomerDashboard";
import Services from "../../Pages/Customer/Components/Services/Services";
import DoctorSignupin from "../DoctorSignupin";
import DoctorAppointment from "../../Pages/Customer/Components/Services/DoctorAppointment";
import PetGromming from "../../Pages/Customer/Components/Services/PetGromming";
import DayCareService from "../../Pages/Customer/Components/Services/DayCareService";
import Mating from "../../Pages/Customer/Components/Services/Mating";
import PetPedia from "../../Pages/Customer/Components/Services/PetPedia";
import LocationsForPets from "../../Pages/Customer/LocationsForPets";
import MyPets from "../../Pages/Customer/MyPets";
import PetDiary from "../../Pages/Customer/PetDiary";
import UserProfilePage from "../../Pages/Customer/UserProfilePage";
import ServiceProviderSignupin from "../ServiceProviderSignupin";
import Hero from "../Hero/Hero";
import AboutUs from "../About/AboutUs";
import BookaVet from "../../Pages/Services/BookaVet";
import GroomingServiceDashboard from "../../Pages/GroomingServices/GroomingServiceDashboard";
import TotalAppointmentsSP from "../../Pages/GroomingServices/TotalAppointmentsSP";
import InvoiceSearch from "../../Pages/GroomingServices/InvoiceSearch";
import TakeLeave from "../../Pages/GroomingServices/TakeLeave";
import DoctorDashboard from "../../Pages/Doctor/DoctorDashboard";
import MedicineInventory from "../../Pages/Doctor/MedicineInventory";
import Tinder from "../../Pages/Tinder/Tinder";
import PwdSuccess from "../ForgotPassword/PwdSuccess";
import Otp from "../ForgotPassword/Otp";
import ResetPassword from "../ForgotPassword/ResetPassword";
import LostAndFoundMyPost from "../../Components/LostAndFound/LostAndFoundMyPost/MyPost";
import LostAndFoundLandingPage from "../LostAndFound/LostAndFoundLandingPage/LostAndFoundLandingPage";
import LostAndFoundHomePage from "../LostAndFound/LostAndFoundHomePage/LostAndFoundHomePage";
import Edit_profile_main from "../Edit-Profile/Edit_profile_main";
import SocialMediaRoutes from "../SocialMedia/SocialMediaPages/SocialMediaRoutes";
import GroomingServices from "../Grooming/GroomingServices";
import Grooming from "../Grooming/Grooming";
import GroomingBookings from "../Grooming/GroomingBookings";
import PetDiaryCalendar from "../PetDiary/PetDiaryCalendar";
// import SocialMediaRoutes from '../SocialMedia/SocialMediaRoutes';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navbar />}>
        {/* <Route index element={<Home />} /> */}
      </Route>
      {/* <Route path="/about" element={<AboutUs />} /> */}
      <Route path="/contactUs" element={<ContactUs />} />
      {/* <Route path="/contact" element={<VerticalCarousel />} /> */}
      <Route path="/c_login" element={<CustomerSignupin />} />
      <Route path="/customerDashboard" element={<CustomerDashboard />} />
      <Route path="services" element={<Services />} />
      <Route path="/d_login" element={<DoctorSignupin />} />
      <Route path="/doctorAppointment" element={<DoctorAppointment />} />

      <Route path="/Grooming" element={<Grooming />} />
      <Route path="/Grooming/services" element={<GroomingServices />} />
      <Route path="/Grooming/bookings" element={<GroomingBookings />} />

      <Route path="/petGromming" element={<PetGromming />} />
      <Route path="/dayCare" element={<DayCareService />} />
      <Route path="/petMating" element={<Mating />} />
      <Route path="/petPedia" element={<PetPedia />} />
      <Route path="/locationsForPets" element={<LocationsForPets />} />
      <Route path="/myPets" element={<MyPets />} />
      <Route path="/petDiary" element={<PetDiary />} />
      <Route path="/userProfile" element={<UserProfilePage />} />
      <Route path="/sp_login" element={<ServiceProviderSignupin />} />
      <Route path="/hero" element={<Hero />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/book-a-vet" element={<BookaVet />} />
      <Route
        path="/servicesAgentDashboard"
        element={<GroomingServiceDashboard />}
      />
      <Route path="/totalAppointmentsSP" element={<TotalAppointmentsSP />} />
      <Route path="/searchInvoice" element={<InvoiceSearch />} />
      <Route path="/checkLeaves" element={<TakeLeave />} />
      <Route path="/doctorDashboard" element={<DoctorDashboard />} />
      <Route path="/medicineInventory" element={<MedicineInventory />} />
      <Route path="/tinder/*" element={<Tinder />} />
      <Route path="/login" element={<Login />} />
      <Route path="/Forgotpass" element={<Forgotpassword />} />
      <Route path="/otp" element={<Otp />} />
      <Route path="/passwordsuccess" element={<PwdSuccess />} />
      <Route path="/resetpwd" element={<ResetPassword />} />
      {/* <Route path="/lost-and-found" element={ <LostAndFoundRoutes />} /> */}
      <Route path="/lost-and-found" element={<LostAndFoundLandingPage />} />
      <Route
        path="/lost-and-found/HomePage"
        element={<LostAndFoundHomePage />}
      />
      <Route
        path="/lost-and-found/HomePage/myPost"
        element={<LostAndFoundMyPost />}
      />

      {/* <Route path="/social-media" element={<SocialMediaRoutes />} /> */}
      <Route path="/edit-profile" element={<Edit_profile_main />} />
      <Route path="/social-media" element={<SocialMediaRoutes />} />
      <Route path="/pet-diary-calendar" element={<PetDiaryCalendar />} />
    </Routes>
  );
};

export default AppRoutes;
