import React from "react";
import styles from "./ServiceFeatures.module.css";
import { useNavigate } from "react-router-dom";

const FeatureColumn = ({ features, onHover, onLeave }) => {
  const firstRow = features.slice(0, 5);
  const secondRow = features.slice(5);
  const navigate = useNavigate();

  return (
    <div className={styles.featureColumn}>
      <div className={styles.featureRow}>
        {firstRow.map((feature, index) => (
          <div
            key={index}
            className={styles.featureItem}
            onMouseEnter={() => onHover(feature.image)}
            onMouseLeave={onLeave}
            onClick={() => navigate(`/${feature.onclick}`)}
          style={{marginBottom:"5px",cursor:"pointer"}}>
            {feature.name}
          </div>
        ))}
      </div>
      {secondRow.length > 0 && (
        <div className={styles.featureRow}>
          {secondRow.map((feature, index) => (
            <div
              key={index}
              className={styles.featureItem}
              onMouseEnter={() => onHover(feature.image)}
              onMouseLeave={onLeave}
              onClick={() => navigate(`/${feature.onclick}`)}
              style={{marginBottom:"5px",cursor:"pointer"}} >
              {feature.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FeatureColumn;
