import React from "react";

const SearchSocialMedia = () => {
  return (
    <div style={{ backgroundColor: "white", height: "84vh" }}>
      SearchSocialMedia
    </div>
  );
};

export default SearchSocialMedia;
