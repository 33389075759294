import React, { useState, useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import styles from "./ui.module.css";
import namer from "color-namer";

const CustomInputElementColor = ({ question, width = "45%", placeholder, onChange, value }) => {
  const [selectedColors, setSelectedColors] = useState(value || []);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState("#fff");
  const pickerRef = useRef(null); 

  useEffect(() => {
    if (Array.isArray(value)) {
      setSelectedColors(value);
    }
  }, [value]);

  const handleColorChange = (selectedColor) => {
    const hexColor = selectedColor.hex; 
    const name = namer(hexColor).basic[0].name; 
    if (selectedColors.includes(name)) {
      setErrorMessage(`The color "${name}" has already been selected.`);
    } else {
      setErrorMessage("");
      const updatedColors = [...selectedColors, name];
      setSelectedColors(updatedColors);
      onChange(updatedColors);
    }
    setColor(hexColor);
  };

  const handleRemoveColor = (index) => {
    const updatedColors = selectedColors.filter((_, i) => i !== index);
    setSelectedColors(updatedColors);
    onChange(updatedColors);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.inputDiv}style={{flex: width === "94%" ? `1 1 ${width}` : "", width: width,padding: "20px 20px 23px 42px", borderBottom: "1px solid #F0F1F6", }} >
      <label htmlFor="colorInput"style={{ display: "block", marginBottom: "8px",color: "#61677F",fontFamily: "Poppins",fontSize: "16px", fontWeight: "500", }}>
        {question}
      </label>
      <div style={{ display: "flex", border: "1px solid #D9D9D9", padding: "5px", width: "100%", position: "relative",backgroundColor: "rgba(243, 243, 243, 0.50)",}} >
        <div style={{ flexWrap: "wrap", gap: "1px", display: "flex", width: "157px" }}>
          {selectedColors.length > 0 ? (
            selectedColors.map((color, index) => (
              <div key={index} className={styles.colorname}>
                {color}
                <div onClick={() => handleRemoveColor(index)} style={{ cursor: "pointer" }} >
                  x
                </div>
              </div>
            ))
          ) : (
            <div style={{  color: "#909AA3",fontFamily: "Poppins", fontSize: "16px",fontStyle: "normal", fontWeight: "400", lineHeight: "normal", marginTop: "7px", marginLeft: "10px",  }} >
              No colors
            </div>
          )}
        </div>

        <div style={{ width: "30%",height: "35px",justifyContent: "center",alignItems: "center",marginLeft: "auto", marginTop: "2px", position: "relative",}}
          onClick={() => setShowPicker(!showPicker)} 
          className={styles.colorInputWrapper}
        >
          <div style={{ width: "40px",height: "40px",backgroundColor: color,border: "1px solid #ccc",cursor: "pointer",visibility:"hidden"}}/>
        </div>
      </div>
      {showPicker && (
        <div ref={pickerRef} style={{ position: "absolute", zIndex: 2 ,top:"500px"}}>
          <SketchPicker color={color} onChange={handleColorChange} />
        </div>
      )}
      {errorMessage && (
        <div style={{ color: "red", marginTop: "10px" }} className={styles.errormsg}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default CustomInputElementColor;
