import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SocialMedia.css";
import SocialMediaImageView from "./SocialMediaImageView";
import { WebApimanager } from "../../WebApiManager";
import { BACKEND_URL } from "../../Utilities/Constant";
import { useRecoilState } from "recoil";
import { userInfo } from "../../Atoms";

import like from "../../assets/Social_Media_Icons/like.svg";
import comment from "../../assets/Social_Media_Icons/comment icon.svg";
import share from "../../assets/Social_Media_Icons/share.svg";
import NotificationSocialMedia from "./SocialMediaPages/NotificationSocialMedia";
import SocicalMediaSuggestions from "./SocicalMediaSuggestions";

const SocialMedia = ({ searchQuery, activeTab }) => {
  const webApi = new WebApimanager();
  const [currentUserInfo] = useRecoilState(userInfo);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [fileArrays, setFileArrays] = useState([]);
  const [activeIndices, setActiveIndices] = useState([]);

  const filteredData = socialMediaData.filter((post) =>
    post.userId?.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const fetchSocialMediaData = async () => {
    try {
      const response = await webApi.get("socialMedia/fetchAllData");
      setSocialMediaData(response.data.posts);
      console.log("social media main get call", response.data.posts);

      const posts = response.data.posts || [];
      const fileData = posts.map((post) => post.file || []);
      console.log(fileData, "kjvnfdkjhndfkb");

      setFileArrays(fileData);
    } catch (error) {
      console.error("Error fetching social media data:", error);
    }
  };

  const handlePrev = (postId) => {
    setActiveIndices((prev) => ({
      ...prev,
      [postId]:
        (prev[postId] > 0 ? prev[postId] : fileArrays[postId].length) - 1,
    }));
  };

  const handleNext = (postId) => {
    setActiveIndices((prev) => ({
      ...prev,
      [postId]: (prev[postId] + 1) % fileArrays[postId].length,
    }));
  };

  useEffect(() => {
    fetchSocialMediaData();
  }, []);

  const handleImageModal = (
    postId,
    userId,
    comments,
    file,
    followingStatus,
    timeStamp
  ) => {
    setSelectedImage(file[0]);
    setIsModalOpen(true);
    setSelectedPost({
      postId,
      userId,
      comments,
      file,
      followingStatus,
      timeStamp,
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const likeandUnLike = async (postId) => {
    if (!postId) {
      alert("Post not found!");
      return;
    }

    try {
      await webApi.put(`socialMedia/likeOrUnlike/${postId}`);

      fetchSocialMediaData();
    } catch (error) {
      console.error("Error toggling like/unlike:", error);
      alert("Failed to toggle like/unlike. Please try again.");
    }
  };

  const sharePost = async (postId) => {
    if (!postId) {
      alert("Post not found!");
      return;
    }

    try {
      await webApi.put(`socialMedia/share/${postId}`);

      fetchSocialMediaData();
    } catch (error) {
      console.error("Error toggling like/unlike:", error);
      alert("Failed to toggle like/unlike. Please try again.");
    }
  };

  const addFollower = async (userId) => {
    if (!userId) {
      alert("User not found!");
      return;
    }

    try {
      console.log("userId", userId);

      await webApi.put(`socialMedia/addFollower/${userId}`);
      fetchSocialMediaData();
    } catch (error) {
      console.error("Error following user:", error);
      alert("Failed to follow user. Please try again.");
    }
  };

  const removeFollower = async (userId) => {
    if (!userId) {
      alert("User not found!");
      return;
    }

    try {
      await webApi.delete(`socialMedia/unFollow/${userId}`);
      fetchSocialMediaData();
    } catch (error) {
      console.error("Error unfollowing user:", error);
      alert("Failed to unfollow user. Please try again.");
    }
  };

  const formatInstagramTime = (timeStamp) => {
    const currentTime = new Date();
    const postTime = new Date(timeStamp);
    const timeDifference = currentTime - postTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return "Just now";
    } else if (minutes < 60) {
      return `${minutes}m ago`;
    } else if (hours < 24) {
      return `${hours}h ago`;
    } else {
      return `${days}d ago`;
    }
  };

  const formatNumber = (num) => {
    if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M";
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + "K";
    }
    return num.toString();
  };

  return (
    <>
      <div className="" style={{ backgroundColor: "#F6F9FE" }}>
        <div className="socialMedia-flex-container">
          <div className="left-box" style={{ marginBottom: "20px" }}>
            {filteredData.map((post) => (
              <div key={post._id} className="socialmedia-box">
                <div className="socialmediaheader">
                  <div className="socialmedia-profile">
                    <img
                      src={`${BACKEND_URL}${
                        post?.userId?.profile || "/default-profile.png"
                      }`}
                      alt={`${post?.userId?.name || "User"}'s Profile`}
                      className="socialmedia-profile-image"
                    />
                    <div className="socialmedia-profile-info">
                      <p className="socialmedia-profile-name">
                        {post.userId?.name || "Anonymous"}
                      </p>
                      <p className="socialmedia-profile-date">
                        {formatInstagramTime(post.timeStamp)}
                      </p>
                    </div>
                  </div>
                  <div>
                    <button
                      className="socialmedia-follow-btn"
                      onClick={() => {
                        if (post.followingStatus === "You are following") {
                          removeFollower(post.userId._id);
                        } else {
                          addFollower(post.userId._id);
                        }
                      }}
                    >
                      {post.followingStatus === "You are following"
                        ? "Unfollow"
                        : "Follow"}
                    </button>
                  </div>
                </div>

                <div
                  className="socialmedia-dec"
                  style={{
                    wordWrap: "break-word",
                    lineHeight: "1.5",
                    marginBottom: "15px",
                    marginTop: "10px",
                  }}
                >
                  <span>
                    {post.caption ||
                      post.question ||
                      "No description available."}
                  </span>
                </div>

                <div>
                  <div>
                    {post.type === "Text Poll" ? (
                      <div className="poll-container">
                        {post.option && post.option.length > 0 ? (
                          post.option.map((opt, idx) => (
                            <div key={idx} className="poll-option">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <input
                                  type="radio"
                                  name={`poll-${post._id}`}
                                  id={`option-${idx}`}
                                  value={opt}
                                  style={{
                                    marginRight: "10px",
                                  }}
                                />
                                <label
                                  htmlFor={`option-${idx}`}
                                  style={{ flex: 1 }}
                                >
                                  {opt}
                                </label>
                                <span>60%</span>
                              </div>
                              <div
                                style={{
                                  height: "10px",
                                  backgroundColor: "#e0e0e0",
                                  borderRadius: "5px",
                                  overflow: "hidden",
                                }}
                              >
                                <div
                                  style={{
                                    width: "60%",
                                    height: "100%",
                                    backgroundColor: "#1FBFC2",
                                  }}
                                ></div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No options available for this poll.</p>
                        )}
                      </div>
                    ) : post.file && post.file.length > 0 ? (
                      <div
                        id={`carousel-${post._id}`}
                        className="carousel-home slide"
                        data-bs-ride="carousel"
                      >
                        <div className="carousel-inner">
                          {post.file.map((image, index) => (
                            <div
                              key={index}
                              className={`carousel-item ${
                                index === 0 ? "active" : ""
                              }`}
                            >
                              <img
                                src={`${BACKEND_URL}${image}`}
                                className="socialmedia-img"
                                alt={`Slide ${index + 1}`}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <p>No images available for this post.</p>
                    )}
                  </div>
                </div>

                <div className="socialmedia-bottom-icons">
                  <div className="socialmedia-left-icons">
                    <div
                      className="icon-with-text"
                      onClick={() => likeandUnLike(post._id)}
                    >
                      <div className="heart-icon-container">
                        <i
                          className={`fas fa-heart ${
                            post.likeStatus === "Yes" ? "active" : ""
                          }`}
                          style={{
                            color: post.likeStatus === "Yes" ? "red" : "gray",
                          }}
                        ></i>
                        {post.likeStatus === "Yes" && (
                          <span className="heart-animation"></span>
                        )}
                      </div>
                      <p>{formatNumber(post.likedBy?.length || 0)}</p>
                    </div>

                    <div
                      className="icon-with-text"
                      onClick={() =>
                        handleImageModal(
                          post._id,
                          post.userId,
                          post.comments,
                          post.file,
                          post.followingStatus,
                          post.timeStamp
                        )
                      }
                    >
                      <img
                        src={comment}
                        alt="comment"
                        // className="fas fa-heart"
                        style={{
                          color: post.isLiked ? "red" : "gray",
                        }}
                      />
                      {/* <i className="fas fa-comment"></i> */}
                      <p>{formatNumber(post.comments?.length || 0)}</p>
                    </div>
                    <div
                      className="icon-with-text"
                      onClick={() => sharePost(post._id)}
                    >
                      <img src={share} alt="share" className="fas fa-heart" />
                      {/* <i className="fas fa-share"></i> */}
                      <p>{formatNumber(post.sharedBy?.length || 0)}</p>
                    </div>
                  </div>

                  <div className="socialmedia-right-icon">
                    {/* <div className="icon-with-text">
                      <i className="fas fa-music"></i>
                      <p>1.3M</p>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="right-box">
            <SocicalMediaSuggestions />
          </div>
        </div>
      </div>

      <SocialMediaImageView
        isModalOpen={isModalOpen}
        handleClose={handleCloseModal}
        imageUrl={selectedImage}
        selectedPost={selectedPost}
        refetchData={fetchSocialMediaData}
      />
    </>
  );
};

export default SocialMedia;
