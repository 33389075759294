import React from "react";
import close from '../../../assets/images/clsoe-popup.png';
import cutepuppyimg1 from '../../../assets/images/cute Maltese dog puppy.png';
import cutedogimg2 from '../../../assets/images/dog-popup.png';


const Popup = ({ isOpen, onClose, onSelectOption }) => {
  if (!isOpen) return null;

  return (
    <div className="lost-and-found-post-popup-overlay">
      <div className="lost-and-found-post-popup-content">
        <button className="lost-and-found-post-popup-close" onClick={onClose}>
          <img src={close} alt="Close" />
        </button>
        <div className="lost-and-found-popup-hearder">
          <div className="lost-and-found-popup-select-lost">Select Lost / Found</div>
        </div>
        <div className="lost-and-found-popup-body">
          <div
            style={{ marginRight: "50px", marginBottom: "10px", cursor: "pointer" }}
            onClick={() => onSelectOption("Lost")}
          >
            <img src={cutepuppyimg1} style={{ paddingBottom: "10px" }} alt="Lost" />
            <div className="lost-and-found-pop-lost">Lost</div>
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => onSelectOption("Found")}
          >
            <img src={cutedogimg2} style={{ paddingBottom: "10px" }} alt="Found" />
            <div className="lost-and-found-pop-lost">Found</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
