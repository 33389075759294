import React from "react";
import { Card, CardBody, Image, Button } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import "./../../styles/CustomerStyles.css";
import CustomerNavbar from "../CustomerNavbar";
import * as Unicons from "@iconscout/react-unicons";
import mating from "./../../../../assets/customer/services/mating.jpg";
import wPDF from "./../../../../assets/customer/services/Pw.pdf";

export default function Mating() {
  const matingServices = [
    "Breed Selection Assistance",
    "Mating Process Supervision",
    "Pre-breeding Health Evaluations",
    "Genetic Testing",
    "Disease Screening",
    "Post-breeding Care and Support",
    "Nutritional Guidance for Pregnancy",
    "Prenatal Care",
    "Assistance During Delivery",
    "Newborn Care and Support",
    "Breeding Consultations",
    "Reproductive Health Counseling",
    "Fertility Testing",
    "Artificial Insemination",
    "Pregnancy Monitoring",
    "Complications Management",
    "Breeding Program Development",
    "Pedigree Analysis",
    "Fertility Treatments",
    "Reproductive Surgery",
  ];

  const handleDownload = () => {
    const pdfUrl = wPDF;

    const link = document.createElement("a");
    link.href = pdfUrl;

    link.setAttribute("download", "file_name.pdf");

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <>
      <div className="CustomerDashboard">
        <div className="CustomerNavbar">
          <Card width="100%">
            <CardBody>
              <CustomerNavbar />
            </CardBody>
          </Card>
        </div>
        <div className=" CustomerDashboardBody">
          <div className="WelcomeCard">
            <Card backgroundColor="#f0c5d9">
              <CardBody className="d-flex  align-items-center">
                <div className="col-lg-7 poppins-medium col-md-12 col-sm-12 col-xs-12 p-1">
                  <h3 style={{ color: "#5a9060" }}>Grooming Service</h3>
                  <p
                    className="serviceDescription"
                    style={{ color: "#5a9060" }}
                  >
                    Mating services for pets are specialized offerings
                    facilitating the reproductive process and ensuring the
                    health of animals and offspring. This service involves
                    coordinating breeding between compatible animals,
                    considering factors like breed standards, genetics, and
                    health. Supervision during mating ensures safety and
                    minimizes risks. Pre-breeding health evaluations assess
                    suitability, including genetic testing and disease
                    screenings. Post-breeding care supports pregnant animals and
                    offspring with nutrition guidance and prenatal care. These
                    services promote responsible breeding, prioritizing animal
                    welfare and ensuring successful outcomes for pet owners.
                  </p>
                  <h4 style={{ color: "#5a9060" }}>Services</h4>
                  <ul>
                    {matingServices.map((service, index) => (
                      <li
                        key={index}
                        className="serviceLi"
                        style={{ color: "#5a9060" }}
                      >
                        {service}
                      </li>
                    ))}
                  </ul>
                  <Button colorScheme="teal" size="md">
                    Book an appointment
                  </Button>
                </div>

                <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                  <Image src={mating} className="rounded" />
                </div>
              </CardBody>
            </Card>
          </div>
          <br />
          <div className="InfoCard">
            <Card backgroundColor="lightblue">
              <CardBody>
                <Accordion allowToggle>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          className="sessionTitle"
                        >
                          Bathing for Rocky
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <div className="d-flex justify-content-between p-1">
                        <p className="col-lg-9 upcomingEventCardClinicName">
                          <Unicons.UilBuilding className="d-inline mx-1" />
                          Service Center Name <br />
                          <Unicons.UilLocationPoint className="d-inline mx-1" />
                          Gachibowli, Hyderabad
                        </p>
                        <p className="col-lg-3">
                          <Unicons.UilCalender className="d-inline mx-1" />
                          06-04-2024 <br />
                          <Unicons.UilClock className="d-inline mx-1" />
                          06:00 PM{" "}
                        </p>
                      </div>
                      <button onClick={handleDownload}>Download PDF</button>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
