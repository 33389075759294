import React from "react";
import Header from "../../Components/Header/Header";
import "./PetDiary.css";
import { useNavigate } from "react-router-dom";

const PetDiary = () => {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate("/pet-diary-calendar");
  };

  const images = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fbaea14dabf7b4170849bc67d16e3bc93",
      name: "Dogs",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F605fd212365f4316bdca9f31fce11483",
      name: "Cats",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fbeea814aed4f4e72a0bb4c56fab461c7",
      name: "Others",
    },
  ];

  return (
    <>
      <div>
        <Header />
      </div>

      <div className="pet-daiary-background-container">
        <div className="pet-daiary-content">
          <span className="pet-daiary-heading">Diary</span>
          <span className="pet-daiary-subheading">
            Lorem ipsum dolor sit amet
          </span>
          <button
            className="pet-daiary-cta-button"
            onClick={handleGetStartedClick}
          >
            GET STARTED
          </button>
        </div>
      </div>

      <div>
        <div>
          <div className="pet-daiary-image-grid-container">
            {images.map((image, index) => (
              <div className="pet-daiary-image-item" key={index}>
                <img src={image.src} alt={image.name} />
                <p className="pet-daiary-image-name">{image.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PetDiary;
