import { Card, CardBody } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const InventoryDistribution = () => {
  const [options, setOptions] = useState(null);
  const [series, setSeries] = useState(null);

  useEffect(() => {
    const inventoryData = {
      series: [30, 20, 15, 10, 10, 5, 5, 5],
      labels: [
        "Tablets/Capsules",
        "Liquid Solutions/Syrups",
        "Topical Solutions/Sprays",
        "Powders",
        "Injections",
        "Ointments/Creams/Gels",
        "Drops",
        "Chewables",
      ],
    };

    setOptions({
      labels: inventoryData.labels,
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return `${val}%`;
        },
      },
      legend: {
        position: "right",
        horizontalAlign: "center",
      },
    });

    setSeries(inventoryData.series);
  }, []);

  return (
    <>
      <Card style={{ margin: "10px" }}>
        <CardBody>
          <h6>Inventory Distribution</h6>
          {options && series && (
            <ReactApexChart
              options={options}
              series={series}
              type="pie"
              width={550}
              height={250}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default InventoryDistribution;
