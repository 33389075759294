import React, { useState, useRef, useEffect } from "react";
import styles from "./UpdateDetailsPopup.module.css";
import {
  CrossIcon,
  FriendsIcon,
  LeftIcon,
  LoversIcon,
  RightIcon,
} from "../../../../assets/SVG";
import CustomCheckbox from "../../../UI/CustomCheckbox";
import CustomRadioButton from "../../../UI/CustomRadioButton";
import CustomTextareaComponent from "../../../UI/CustomTextAreaComponent";
import CustomInputElement from "../../../UI/CustomInputElement";
import CustomInputElementnumber from "../../../UI/CustomInputElementnumber";


import { WebApimanager } from "../../../../WebApiManager";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const questions = [
  {
    question: "Pedigree Certified",
    options: ["Yes", "No"],
    key: "pedigreeCertified",
  },
  { question: "Vaccinated", options: ["Yes", "No"], key: "vaccinated" },
  { question: "DNA Tested", options: ["Yes", "No"], key: "DNATested" },
  {
    question: "Breeding for the first time",
    options: ["Yes", "No"],
    key: "breedingForTheFirstTime",
  },
  {
    question: "Is the Pet microchipped",
    options: ["Yes", "No"],
    key: "isThePetMicroChipped",
  },
];

const questionsstep3 = [
  {
    question: "Good with Kids?",
    options: ["Yes", "No"],
    key: "isYourPetGoodWithKids",
  },
  {
    question: "Good with cats?",
    options: ["Yes", "No"],
    key: "isYourPetGoodWithCats",
  },
  {
    question: "Good with Dogs?",
    options: ["Yes", "No"],
    key: "isYourPetGoodWithDogs",
  },
  {
    question: "Potty Trained?",
    options: ["Yes", "No"],
    key: "isYourPetTrained",
  },
];

const questionsstep6 = [
  {
    question: "Have you consulted with veterinarian about breeding your pet?",
    options: ["Yes", "No"],
    key: "haveYouConsultedWithVeterinationAboutBreeding",
  },
  {
    question: "Do you have plan for caring for and placing puppies?",
    options: ["Yes", "No"],
    key: "doYouHavePlanForCaringAndPlacingPuppies",
  },
  {
    question: "Does your pet has any Health Issues? Please Describe?",
    options: ["Yes", "No"],
    key: "doesYourPetHasAnyHealthIssues",
  },
  {
    question:
      "Has your pet been tested for any breed-specific health conditions or genetic disorders?",
    options: ["Yes", "No"],
    key: "hasYourPetBeenTestedForAnyBreedSpecificHealthConditionsOrGeneticDisOrders",
  },
  {
    question:
      "Are you familiar with the breed standard and any genetic health issues associated with this breed?",
    options: ["Yes", "No"],
    key: "areYouFamiliarWithTheBreedStandardAndAnyGeneticHealthIssuesAssosiatedWithThisBreed",
  },
  {
    question: "Is your pet up-to-date on vaccinations and preventive care?",
    options: ["Yes", "No"],
    key: "isYourPetUpToDateOnVaccinationsAndPreventiveCare",
  },
  {
    question:
      "Are you willing to sign a breeding contract with the other pet owner?",
    options: ["Yes", "No"],
    key: "areYouWillingToSignABreedingContractWithTheOtherPetOwner",
  },
];

const UpdateDetailsPopup = ({
  isUpdatePopupOpen,
  setIsUpdatePopupOpen,
  selectedCardData,
}) => {
  console.log(selectedCardData, "selectedPetsssss");
  const popupRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(1);
  const webApi = new WebApimanager();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null); 
  const [age, setage] = useState(null);
  const [victoriaDetails, setVictoriaDetails] = useState("");
  const [instagramText, setInstagramText] = useState("");
  const [selectedAnswer4, setSelectedAnswer4] = useState("");
  const [breedingTerms, setBreedingTerms] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [years, setYears] = useState("");
  const [months, setMonths] = useState("");
  const [weight,setWeight]=useState("")
  const [studFees,setStudFees]=useState("")
  
  
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [images, setImages] = useState([]);
  const [fileNames, setFileNames] = useState([]);


  useEffect(() => {
    if (selectedCardData) {
      console.log("Pet data available22222:", selectedCardData);
    } else {
      console.log("Pet data is not available22222");
    }
    if (selectedCardData?.petNeed != null) {
      setSelectedOption(selectedCardData.petNeed);
      console.log("ccc", selectedCardData.petNeed);
    }
  }, [selectedCardData]);
  const navigateBack = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };
  const navigateNext = () => {
    if (currentStep < 7) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (isUpdatePopupOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => document.body.classList.remove("no-scroll");
  }, [isUpdatePopupOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsUpdatePopupOpen(false);
        setCurrentStep(1);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsUpdatePopupOpen]);

  useEffect(() => {
    webApi
      .get("petProfile/visiblePets")
      .then((response) => {
        const responseData = response.data;
        console.log(responseData, "z");
      })
      .catch((error) => {
        console.error("Error fetching My data:", error);
      });
  }, []);

  useEffect(() => {
    setSelectedOption(null);

    if (selectedCardData?.petNeed != null) {
      setSelectedOption(selectedCardData.petNeed);
      console.log("ccc", selectedCardData.petNeed);
    }
  }, [selectedCardData]);

  const handleSelection = (option) => {
    setSelectedOption(option);
  };
  const questions = [
    {
      question: "Pedigree Certified",
      options: selectedCardData?.pedigreeCertificate ? ["Yes"] : ["Yes", "No"],
    },
    {
      question: "Vaccinated",
      options: ["Yes", "No"],
    },
    {
      question: "DNA Tested",
      options: selectedCardData?.DNACertificate ? ["Yes"] : ["Yes", "No"],
    },
    {
      question: "Breeding for the first time",
      options: ["Yes", "No"],
    },
    {
      question: "Is the Pet microchipped",
      options: ["Yes", "No"],
    },
  ];
 
  useEffect(() => {
    if (selectedCardData?.typeOfBreed) {
      setSelectedOption2(selectedCardData.typeOfBreed);
    } else {
      setSelectedOption2(null);
    }
  }, [selectedCardData]);

  const handleCheckboxChange = (selectedOption) => {
    setSelectedOption2(selectedOption);
  };

  const [selectedAnswers, setSelectedAnswers] = useState(
    questions.reduce((acc, curr) => ({ ...acc, [curr.question]: null }), {})
  );

  useEffect(() => {
    if (selectedCardData) {
      setSelectedAnswers({
        "Pedigree Certified": selectedCardData.pedigreeCertificate
          ? "Yes"
          : "No",
        Vaccinated: selectedCardData.vaccinated ? "Yes" : "No",
        "DNA Tested": selectedCardData.DNATested ? "Yes" : "No",
        "Breeding for the first time": selectedCardData.breedingForTheFirstTime
          ? "Yes"
          : "No",
        "Is the Pet microchipped": selectedCardData.isThePetMicroChipped
          ? "Yes"
          : "No",
      });
    }
  }, [selectedCardData]);
 
  const handleRadioChange = (question, answer) => {
    setSelectedAnswers((prevState) => ({
      ...prevState,
      [question]: answer,
    }));
  };
  
  useEffect(() => {
    if (selectedCardData?.size) {
      setage(selectedCardData.size);
    } else {
      setage(null);
    }
  }, [selectedCardData]);
  const handleCheckboxChangestep3 = (selectedOption) => {
    setage(selectedOption);
  };

  const [selectedAnswersstep3, setSelectedAnswersstep3] = useState(
    questionsstep3.reduce(
      (acc, curr) => ({ ...acc, [curr.question]: null }),
      {}
    )
  );
  useEffect(() => {
    if (selectedCardData) {
      setSelectedAnswersstep3((prevState) => ({
        "Good with Kids?": selectedCardData.isYourPetGoodWithKids || null,
        "Good with cats?": selectedCardData.isYourPetGoodWithCats || null,
        "Good with Dogs?": selectedCardData.isYourPetGoodWithDogs || null,
        "Potty Trained?": selectedCardData.isYourPetTrained || null,
      }));
    }
  }, [selectedCardData]);

  const handleRadioChangestep3 = (question, answer) => {
    setSelectedAnswersstep3((prevState) => ({
      ...prevState,
      [question]: answer,
    }));
  };

  const handleInstagramInputChange = (inputValue) => {
    setInstagramText(inputValue);
  };
 
  const handleVictoriaInfoChange = (inputValue) => {
    setVictoriaDetails(inputValue);
  };

  useEffect(() => {
    if (selectedCardData) {
      setInstagramText(selectedCardData.instagramLink || "");
      setVictoriaDetails(selectedCardData.aboutYourPet || "");
    }
  }, [selectedCardData]);

  useEffect(() => {
    if (selectedCardData) {
      setSelectedAnswer4(selectedCardData.willingToTravel || "");
    }
  }, [selectedCardData]);

  const handleRadioChange4 = (answer) => {
    setSelectedAnswer4(answer);
  };


  const BreedingTerms = (inputValue) => {
    setBreedingTerms(inputValue);
  };
  const [accommodationResponsibility, setAccommodationResponsibility] =
    useState(null);
  const AccommodationResponsibility = (inputValue) => {
    setAccommodationResponsibility(inputValue);
  };

  useEffect(() => {
    if (selectedCardData) {
      setBreedingTerms(selectedCardData.breedingTerms || "");
      setAccommodationResponsibility(
        selectedCardData.accommadationResponsibility || ""
      );
    }
  }, [selectedCardData]);
  const [selectedAnswersstep6, setSelectedAnswersstep6] = useState(
    questionsstep6.reduce(
      (acc, curr) => ({ ...acc, [curr.question]: null }),
      {}
    )
  );

  useEffect(() => {
    if (selectedCardData) {
      setSelectedAnswersstep6((prevState) => ({
        "Have you consulted with veterinarian about breeding your pet?":
          selectedCardData.haveYouConsultedWithVeterinationAboutBreeding ||
          null,
        "Do you have plan for caring for and placing puppies?":
          selectedCardData.doYouHavePlanForCaringAndPlacingPuppies || null,
        "Does your pet has any Health Issues? Please Describe?":
          selectedCardData.doesYourPetHasAnyHealthIssues || null,
        "Has your pet been tested for any breed-specific health conditions or genetic disorders?":
          selectedCardData.hasYourPetBeenTestedForAnyBreedSpecificHealthConditionsOrGeneticDisOrders ||
          null,
        "Are you familiar with the breed standard and any genetic health issues associated with this breed?":
          selectedCardData.areYouFamiliarWithTheBreedStandardAndAnyGeneticHealthIssuesAssosiatedWithThisBreed ||
          null,
        "Is your pet up-to-date on vaccinations and preventive care?":
          selectedCardData.isYourPetUpToDateOnVaccinationsAndPreventiveCare ||
          null,
        "Are you willing to sign a breeding contract with the other pet owner?":
          selectedCardData.areYouWillingToSignABreedingContractWithTheOtherPetOwner ||
          null,
      }));
    }
  }, [selectedCardData]);

  const handleRadioChangestep6 = (question, answer) => {
    setSelectedAnswersstep6((prevState) => ({
      ...prevState,
      [question]: answer,
    }));
  };


  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    const newImages = files.map((file) => URL.createObjectURL(file));

    setImages((prevImages) => [...prevImages, ...newImages]);
    setFileNames((prevFileNames) => [...prevFileNames, ...files]);
  };

  const handleCancel = (index) => {
    setImages((prevImages) => {
      const updatedImages = prevImages.filter((_, i) => i !== index);
      console.log("Updated Images after cancel:", updatedImages);
      return updatedImages;
    });
  }; 
 

  

  useEffect(() => {
    setStudFees(selectedCardData?.studFee);
    setWeight(selectedCardData?.weight);
  
    if (selectedCardData?.petAge) { 
      const ageMatch = selectedCardData.petAge.match(/(\d+)\s*years?\s*(\d+)?\s*months?/);
  
      if (ageMatch) {
        const extractedYears = parseInt(ageMatch[1], 10); 
        const extractedMonths = parseInt(ageMatch[2] || "0", 10); 
          const updatedYears = extractedYears === 50 ? extractedYears + 1 : extractedYears; 
        setYears(updatedYears); 
        setMonths(extractedMonths); 
      }
    }
  }, [selectedCardData?.petAge, selectedCardData?.studFee, selectedCardData?.weight]);

  


  const handleSubmit = async () => {
    setLoading(true);
    const answersFromSelectedOptions = {
      vaccinated: selectedAnswers["Vaccinated"],
      pedigreeCertified: selectedAnswers["Pedigree Certified"],
      DNATested: selectedAnswers["DNA Tested"],
      breedingForTheFirstTime: selectedAnswers["Breeding for the first time"],
      isThePetMicroChipped: selectedAnswers["Is the Pet microchipped"],
    };
    const step3Answers = {
      isYourPetGoodWithKids: selectedAnswersstep3["Good with Kids?"],
      isYourPetGoodWithCats: selectedAnswersstep3["Good with cats?"],
      isYourPetGoodWithDogs: selectedAnswersstep3["Good with Dogs?"],
      isYourPetTrained: selectedAnswersstep3["Potty Trained?"],
    };
    const step6Answers = {
      haveYouConsultedWithVeterinationAboutBreeding:
        selectedAnswersstep6[
          "Have you consulted with veterinarian about breeding your pet?"
        ],
      doYouHavePlanForCaringAndPlacingPuppies:
        selectedAnswersstep6[
          "Do you have plan for caring for and placing puppies?"
        ],
      doesYourPetHasAnyHealthIssues:
        selectedAnswersstep6[
          "Does your pet has any Health Issues? Please Describe?"
        ],
      hasYourPetBeenTestedForAnyBreedSpecificHealthConditionsOrGeneticDisOrders:
        selectedAnswersstep6[
          "Has your pet been tested for any breed-specific health conditions or genetic disorders?"
        ],
      areYouFamiliarWithTheBreedStandardAndAnyGeneticHealthIssuesAssosiatedWithThisBreed:
        selectedAnswersstep6[
          "Are you familiar with the breed standard and any genetic health issues associated with this breed?"
        ],
      isYourPetUpToDateOnVaccinationsAndPreventiveCare:
        selectedAnswersstep6[
          "Is your pet up-to-date on vaccinations and preventive care?"
        ],
      areYouWillingToSignABreedingContractWithTheOtherPetOwner:
        selectedAnswersstep6[
          "Are you willing to sign a breeding contract with the other pet owner?"
        ],
    };

    const payload = {
      petNeed: selectedOption,
      size: age,
      typeOfBreed: selectedOption2,
      ...answersFromSelectedOptions,
      ...step3Answers,
      ...step6Answers,
      instagramLink: instagramText,
      aboutYourPet: victoriaDetails,
      willingToTravel: selectedAnswer4,
      breedingTerms: breedingTerms,
      accommadationResponsibility: accommodationResponsibility,
      weight:weight,
      studFee:studFees,
      petAge:`${years} years ${months} months`

    };
    const imgpayload = new FormData();

    fileNames.forEach((file) => {
      imgpayload.append("morePhotos", file);
    });
    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });
    try {
      const response = await webApi.put(
        `petProfile/update/${selectedCardData._id}`,
        payload
      );

      if (fileNames.length > 0) {
        const imageUploadResponse = await webApi.imagePut(
          `petProfile/updateMorePhotos/${selectedCardData._id}`,
          imgpayload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else if (response.status === 400) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
        }
        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }

      toast.success(" Successfully!");
      setIsUpdatePopupOpen(false);
      window.location.reload();
    } catch (err) {
      console.error("Error occurred:", err);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  const popupback = () => {
    setIsUpdatePopupOpen(false);
    setCurrentStep(1);
  };

  


  return (
    <>
      <ToastContainer></ToastContainer>
      {isUpdatePopupOpen && (
        <div className={styles.Background}>
          <div className={styles.popupContainer} ref={popupRef}>
            <div className={styles.header}>
              <p></p>
              <p style={{ margin: "0px" }}>Add New Pet</p>
              <div
                style={{ marginRight: "25px", cursor: "pointer" }}
                onClick={popupback}
              >
                <CrossIcon />
              </div>
            </div>
            <div className={styles.mainContainer}>
              <p>{`Step ${currentStep} of 7`}</p>
              <div className={styles.progressBarDiv}>
  {[...Array(7)].map((_, index) => (
    <div
      key={index}
      className={styles.progressBar}
      style={{
        backgroundColor: currentStep >= index + 1 ? "#1FBFC2" : "#A6ACB8",
      }}
    ></div>
  ))}
</div>
              <div className={styles.changingContainer}>
                {/* Step 1 */}
                {currentStep === 1 && (
                  <section className={styles.step1Container}>
                    <p>What do you want for your pet?</p>
                    <div
                      style={{
                        display: "flex",
                        gap: "50px",
                        textAlign: "center",
                      }}
                    >
                      {/* Friend Option */}
                      <div
                        onClick={() => handleSelection("Friend")}
                        style={{
                          cursor: "pointer",
                          border:
                            selectedOption === "Friend"
                              ? "1px solid #D9D9D9"
                              : "none",
                          borderRadius: "8px",
                          padding: "10px",
                        }}
                      >
                        <div className={styles.typeContainer}>
                          <FriendsIcon />
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: 400,
                              marginTop: "20px",
                              marginBottom: "0px",
                            }}
                          >
                            Friend
                          </p>
                        </div>
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: 400,
                            color: "#909AA3",
                          }}
                        >
                          Need a Playmate
                        </p>
                      </div>

                      {/* Lover Option */}
                      <div
                        onClick={() => handleSelection("Lover")}
                        style={{
                          cursor: "pointer",
                          border:
                            selectedOption === "Lover"
                              ? "1px solid #D9D9D9"
                              : "none",
                          borderRadius: "8px",
                          padding: "10px",
                        }}
                      >
                        <div className={styles.typeContainer}>
                          <LoversIcon />
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: 400,
                              marginTop: "20px",
                              marginBottom: "0px",
                            }}
                          >
                            Lover
                          </p>
                        </div>
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: 400,
                            color: "#909AA3",
                          }}
                        >
                          Need a Mating Partner
                        </p>
                      </div>
                    </div>
                  </section>
                )}
                {/* step 2 */}
                {currentStep === 2 && (
                  <section className={styles.step1Container}>
                    <CustomCheckbox
                      question={`What's the breed of ${selectedCardData.petName}?`}
                      options={["Pure breed", "Cross breed"]}
                      width="100%"
                      selectedOptions={selectedOption2}
                      onSelectionChange={handleCheckboxChange}
                      customstyle={{color:"#61677F",padding:"0px 0px 10px 0px"}}
                    />{" "}
                   <div style={{ display: "flex", flexWrap: "wrap" }}>
                           {questions.map(({ question, options }, index) => (
                             <div key={question}style={{  width: "50%",  display: "flex",  justifyContent: index % 2 === 0 ? "flex-end" : "flex-start",}}>
                                     <CustomRadioButton question={question}options={options}width="100%"value={selectedAnswers[question]}onChange={(answer) => handleRadioChange(question, answer)}             
                                       CustomRadioButtonchage={{borderBottom:"0px solid #F0F1F6"}} CustomRadio2={{ padding: "30px 0px 0px 0px",width:"100%" }}
                                     />
                             </div>
                            ))}
                    </div>

                  </section>
                )}




                {/* step 3 */}
                {currentStep === 3 && (
  <section className={styles.step1Container}>
    <CustomCheckbox
      question={`How big is ${selectedCardData.petName}`}
      options={["Small", "Medium", "Large", "Extra Large"]}
      width="100%"
      selectedOptions={age}
      onSelectionChange={handleCheckboxChangestep3}
      customstyle={{color:"#61677F",padding:"0px 0px 10px 0px"}}

    />
        <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ flex: "1 1 50%" }}>
        <CustomInputElementnumber question={`How Old is ${selectedCardData.petName}`} placeholder={"years"} CustomInputElementepdate={{width:"100%",padding:"0px 20px 0px 0px",borderBottom: "0px solid rgb(240, 241, 246)",fontSize:"14px",marginBottom:"13px"}}  value={years}
          onChange={setYears}
          maxLength={4}/>
      </div>
      <div style={{ flex: "1 1 50%" }}>
        <CustomInputElementnumber placeholder={"Months"} CustomInputElementepdate={{width:"100%",padding:"0px 20px 0px 0px",borderBottom: "0px solid rgb(240, 241, 246)",marginBottom:"13px"}} CustomInputElementmargin={{marginTop:"21px"}}   value={months}
          onChange={setMonths}
          minValue={0} // Minimum value for months
          maxValue={12}/>
      </div>
      <div style={{ flex: "1 1 50%" }}>
        <CustomInputElementnumber question="weight" placeholder={"0"} CustomInputElementepdate={{width:"100%",padding:"0px 20px 0px 0px",borderBottom: "0px solid rgb(240, 241, 246)"}}
        value={weight}
        onChange={setWeight}
        
        />
      </div>
      <div style={{ flex: "1 1 50%" }}>
        <CustomInputElementnumber question="Stud Fees" placeholder={"0"}  CustomInputElementepdate={{width:"100%",padding:"0px 20px 0px 0px",borderBottom: "0px solid rgb(240, 241, 246)"}}
                value={studFees}
                onChange={setStudFees}
        />
      </div>
    </div>

   {/* Radio button group */}
<div style={{ display: "flex", flexWrap: "wrap" }}>
  {questionsstep3.map(({ question, options }, index) => (
    <div
      key={question}
      style={{
        flex: "1 1 50%",
        padding: "10px",
        textAlign: index % 2 === 0 ? "left" : "right", 
      }}
    >
      <CustomRadioButton
        question={question}
        options={options}
        width="100%"
        value={selectedAnswersstep3[question]}
        onChange={(answer) => handleRadioChangestep3(question, answer)}
        CustomRadioButtonchage={{borderBottom:"0px solid #F0F1F6"}} 
        CustomRadio2={{ padding: "30px 0px 0px 0px",width:"100%" }}


      />
    </div>
  ))}
</div>

  </section>
)}










                {/* Step 4 */}
                {currentStep === 4 && (
                  <section className={styles.step1Container}>
                    <CustomTextareaComponent
                      question={`Instagram handle of ${selectedCardData.petName} (optional)`}
                      placeHolder="@instagram"
                      value={instagramText}
                      onInputChange={handleInstagramInputChange}
                      customStyles={{color:"#61677F",padding:"0px 0px 10px 0px"}}
                      customStylestext={{border:"1px solid rgb(217, 217, 217)",backgroundColor:"rgba(243, 243, 243, 0.5)",color:"#61677F"}}


                    />
                    <CustomTextareaComponent
                      question={`Tell us more about ${selectedCardData.petName}?`}
                      placeHolder="Your pet's temperament, character, and what are you looking for..."
                      rows={3}
                      value={victoriaDetails}
                      onInputChange={handleVictoriaInfoChange}
                      customStyles={{color:"#61677F",padding:"0px 0px 10px 0px"}}
                      customStylestext={{border:"1px solid rgb(217, 217, 217)",backgroundColor:"rgba(243, 243, 243, 0.5)",color:"#61677F"}}

                    />
                  </section>
                )}
                {/* Step 5 */}
                {currentStep === 5 && (
                  <section className={styles.step1Container}>
                    <CustomRadioButton
                      question={"Willingness to travel"}Radio
                      options={["Yes", "No"]}
                      width="50%"
                      value={selectedAnswer4}
                      onChange={handleRadioChange4} CustomRadio2={{ padding: "30px 0px 0px 0px",width:"100%" }}
                      CustomRadioButtonchage={{borderBottom:"0px solid #F0F1F6"}} 

                    />
                    <CustomTextareaComponent
                      question="Breeding Terms"
                      placeHolder="your pet temperament, character and what are you looking for...."
                      rows={3}
                      value={breedingTerms}
                      onInputChange={BreedingTerms}
                      customStyles={{color:"#61677F",padding:"0px 0px 10px 0px"}}
                      customStylestext={{border:"1px solid rgb(217, 217, 217)",backgroundColor:"rgba(243, 243, 243, 0.5)",color:"#61677F"}}
                    />
                    <CustomTextareaComponent
                      question="Accommodation Responsibility"
                      placeHolder="your pet temperament, character and what are you looking for...."
                      rows={3}
                      value={accommodationResponsibility}
                      onInputChange={AccommodationResponsibility}
                      customStyles={{color:"#61677F",padding:"0px 0px 10px 0px"}}
                      customStylestext={{border:"1px solid rgb(217, 217, 217)",backgroundColor:"rgba(243, 243, 243, 0.5)",color:"#61677F"}}
                    />
                  </section>
                )}
                {currentStep === 6 && (
                  <section className={styles.step1Container}>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {questionsstep6.map(({ question, options },index) => (
                           <div
                           key={question}
                           style={{
                             flex: "1 1 50%",
                             padding: "10px",
                             textAlign: index % 2 === 0 ? "left" : "right", 
                           }}
                         >
                        <CustomRadioButton
                          key={question}
                          question={question}
                          options={options}
                          width="50%"
                          value={selectedAnswersstep6[question]}
                          onChange={(answer) =>
                            handleRadioChangestep6(question, answer)
                          }
                          CustomRadioButtonchage={{borderBottom:"0px solid #F0F1F6"}} 
                          CustomRadio2={{ padding: "30px 0px 0px 0px",width:"100%" }}
                          customline={{lineHeight: "18px",paddingBottom:"15px"}}
                          
                        />
                        </div>
                      ))}
                    </div>
                  </section>
                )}
                {currentStep === 7 && (
                  <section className={styles.step1Container}>
                    <p>Add some photos of {selectedCardData.petName}</p>
                    <div style={{ display: "flex" }}>
                      <div className={styles.imagePreviewContainer}>
                        {images.map((image, index) => (
                          <div className={styles.imagePreview} key={index}>
                            <img
                              src={image}
                              alt={`Profile ${index}`}
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "50%",
                              }}
                            />
                            <button
                              className={styles.cancelButton}
                              onClick={() => handleCancel(index)}
                            >
                              X
                            </button>
                          </div>
                        ))}
                      </div>

                      <div style={{ display: "block" }}>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          id="file-input"
                          multiple
                        />

                        <div
                          className={styles.addButtonDiv}
                          onClick={() =>
                            document.getElementById("file-input").click()
                          }
                        >
                          <p>+</p>
                        </div>

                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: 500,
                            color: "#A6ACB8",
                          }}
                        >
                          Profile Photos
                        </p>
                      </div>
                    </div>
                  </section>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className={styles.navContainer}>
                  <div style={{ cursor: "pointer" }} onClick={navigateBack}>
                    {currentStep > 1 ? <LeftIcon /> : null}
                  </div>

                  {currentStep < 7 ? (
                    <div style={{ cursor: "pointer" }} onClick={navigateNext}>
                      <RightIcon />
                    </div>
                  ) : (
                    <button type="button"style={{  cursor: "pointer",  backgroundColor: "#F5790C",  color: "white",  border: "none",  borderRadius: "5px",  padding: "10px 20px",  marginTop: "10px",}}onClick={handleSubmit}>
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateDetailsPopup;
