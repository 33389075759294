import React, { useEffect, useState } from "react";
import "../Edit-Profile/profile.css";
import { FaPlus, FaMinus } from "react-icons/fa6"; // Import both icons
import { WebApimanager } from "../../WebApiManager";

const Profile_FAQ = () => {
  const webApi = new WebApimanager();
  const [faqData, setFaqData] = useState([]);
  const [openIndex, setOpenIndex] = useState(null); // Track the open FAQ index

  useEffect(() => {
    const fetchFaqData = async () => {
      try {
        const faqResponse = await webApi.get("users/userDetails");
        console.log("Response for FAQ data:", faqResponse.data.data.user.faq);
        setFaqData(faqResponse.data.data.user.faq || []); // Ensure default empty array
      } catch (error) {
        console.log("Server error:", error);
      }
    };

    fetchFaqData();
  }, []);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle open/close
  };

  return (
    <>
      <div className="faq-container">
        <div>
          <p className="faq-title">Frequently Asked Questions</p>
          <p className="faq-sub-title">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
            scelerisque pretium velit, eu luctus purus. Donec malesuada lorem
            eros, at porttitor urna semper vitae.
          </p>
        </div>

        <div className="faq-QsandAns">
          {faqData.length > 0 ? (
            faqData.map((faq, index) => (
              <div key={index} className="faq-item">
          
                <div
                  className="faq-question-container"
                  onClick={() => toggleAnswer(index)}
                >
                  <div className="faq-question">
                    <p>{faq.question}</p>
                  </div>
                  <button className="toggle-button">
                    {openIndex === index ? <FaMinus /> : <FaPlus />}
                  </button>
                </div>

                {openIndex === index && (
                  <div className="faq-answer-container">
                    <p className="faq-answer">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No FAQs available.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile_FAQ;
