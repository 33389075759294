import React, { useEffect } from "react";
import styles from "./ui.module.css";

const CustomCheckbox = ({ question, options, width, selectedOptions, onSelectionChange,customstyle }) => {
  const [selectedOption, setSelectedOption] = React.useState(null);

  useEffect(() => {
    if (selectedOptions) {
      setSelectedOption(selectedOptions); 
    }
  }, [selectedOptions]);

  const handleClick = (option) => {
    setSelectedOption(option);
    onSelectionChange(option); 
  };

  return (
    <div className={styles.checkboxDiv} style={{ width: width }}>
      <p style={{
        color:customstyle?.color,
        padding:customstyle?.padding,

      }}>{question}</p>
      <div style={{ display: "flex",        color:customstyle?.color,
 }}>
        {options.map((option) => {
          const isChecked = selectedOption === option; 
          return (
            <div key={option} style={{ display: "flex", alignItems: "center" }}>
              <div
                className={`${styles.checkBox} ${isChecked ? styles.checked : ""}`}
                style={{ marginRight: "15px", cursor: "pointer" }}
                onClick={() => handleClick(option)}
              >
                {isChecked && <span className={styles.checkmark}>✔</span>}
              </div>
              <p
                style={{
                  marginRight: "65px",
                  fontSize: "14px",
                  fontWeight: 500,
                  marginBottom: "0px",
                }}
              >
                {option}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomCheckbox;
