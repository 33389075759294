import React, { useState, useEffect } from "react";
import zaanvarlogo from "../../assets/images/ZAANVAR_FINAL LOGO.png";
import bgimg from "../../assets/images/Group 4.png";
import confrompng from "../../assets/images/Group.png"
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";


const PwdSuccess = () => {
  const navigate = useNavigate();
  
  const [email, setEmail] = useState("");
  const location = useLocation();
  console.log("email success page",email)

  return (
    <div className="ps-signmainHeader">
      <div className="fp-containerbody fp-containeronly">
        <div className="fp-container">
          <div className="ps-left-side">
            <div className="ps-zaanvar-icon">
              <img src={zaanvarlogo} style={{ width: "100px" }}></img>
            </div>

            <div>
              <form className="ps-login-form">
                <img src={confrompng} style={{width:"30px",marginLeft:"80px",marginTop:"70px",marginBottom:"40px",position:'relative',left:"25px"}}></img>
                
                <div className="ps-l1">
                <label>Password changed successfully!</label>

                 
                </div>
                <label className='ps-l2'>
                  Please Login to your email account again
                  </label>
               
               
                <button className="ps-login-button" style={{ marginTop: "20px",borderRadius:"5px",fontFamily:"Poppins" }}>
                LOGIN NOW
                </button>
              </form>
            </div>
          </div>

          <div className="ps-img">
            <img src={bgimg} className="fp-fixed-bg-image"></img>
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default PwdSuccess
