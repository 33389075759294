import { Card, CardBody } from "@chakra-ui/react";
import React from "react";
import Chart from "react-apexcharts";

const DailyVisitors = () => {
  const data = [
    { date: "Jan", visitors: 100 },
    { date: "Feb", visitors: 150 },
    { date: "Mar", visitors: 200 },
    { date: "Apr", visitors: 180 },
    { date: "May", visitors: 220 },
    { date: "Jun", visitors: 250 },
    { date: "Jul", visitors: 300 },
  ];

  const options = {
    chart: {
      id: "line-chart",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: data.map((day) => day.date),
    },
    yaxis: {
      title: {
        text: "Number of Visitors",
      },
    },
    colors: ["#497174"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
  };

  const series = [
    {
      name: "Visitors",
      data: data.map((day) => day.visitors),
    },
  ];

  return (
    <Card>
      <CardBody>
        <h4>Daily Visitors</h4>
        <Chart
          options={options}
          series={series}
          type="line"
          width="100%"
          height="300"
        />
      </CardBody>
    </Card>
  );
};

export default DailyVisitors;
