import React, { useState, useRef, useEffect } from 'react';
import styles from './PetInfoPopup.module.css';
import { CrossIcon, GreenTickIcon, PottyIcon, RedCrossIcon, VaccinatedIcon, WeightIcon, BackIconBlack, SendIcon, SentIcon, MenuIcon } from '../../../../assets/SVG';
import { ChatMenu } from '../../constants';
import { BACKEND_URL, formatDate } from "../../../../Utilities/Constant";


const PetInfoPopup = ({ ispetInfoPopupOpen, setIspetInfoPopupOpen,selectedPetInfo }) => {
    const popupRef = useRef(null);
    const [isChatScreenOpen, setIsChatscreenOpen] = useState(false)
    const selectedChat = ChatMenu[0]
    
    useEffect(() => {
      }, [selectedPetInfo]);
    
    useEffect(() => {
        if (ispetInfoPopupOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
        return () => document.body.classList.remove('no-scroll');
    }, [ispetInfoPopupOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIspetInfoPopupOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setIspetInfoPopupOpen]);

    return (
        <>
            {ispetInfoPopupOpen && (
                <div className={styles.Background}>
                    <div style={{position: 'relative'}}>
                        {isChatScreenOpen ? 
                            <div className={styles.popupContainer} ref={popupRef}>
                                <div className={styles.chat} style={{padding: '25px'}}>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '15px'}}>
                                        <div style={{cursor: 'pointer'}} onClick={() => setIsChatscreenOpen(false)}><BackIconBlack /></div>
                                            <div style={{display: 'flex', gap: '15px'}}>
                                                {selectedChat.image}
                                                <div>
                                                    <p className={styles.name}>{selectedChat.name}</p>
                                                    <p className={styles.message}>{selectedChat.message}</p>
                                                </div>
                                            </div>
                                    </div>
                                    <div style={{ cursor: 'pointer', position: 'relative' }}>
                                        <div>
                                            <MenuIcon />
                                        </div>
                                        
                                    </div>
                                </div>
                            <div className={styles.mainContainer1}>
                                {selectedChat.messages.map(message => {
                                    if (message.type === 'recieved') {
                                        return(
                                            <div className={styles.recievedMessageDiv}>
                                                {selectedChat.image}
                                                <div>
                                                    <div className={styles.recievedMessage}>
                                                        {message.content}
                                                    </div>
                                                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '5px'}}>
                                                        <p style = {{fontSize: '8px', fontWeight: 400, marginBottom: '0px'}}>{message.time}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className={styles.sentMessageDiv}>
                                                <div>
                                                    <div className={styles.sentMessage}>
                                                        {message.content}
                                                    </div>
                                                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '5px'}}>
                                                        <p style = {{fontSize: '8px', fontWeight: 400}}>{message.time}</p>
                                                    </div>
                                                </div>
                                                <SentIcon />
                                            </div>
                                        )
                                    }
                                })}
                                <div className={styles.inputElement}>
                                    <input placeholder='Send Message' />
                                    <div style={{cursor: 'pointer'}}><SendIcon /></div>
                                </div>
                            </div>
                            </div> :
                        
                        <div className={styles.popupContainer} ref={popupRef}>
                            <div>
                                <img src={`${BACKEND_URL}${selectedPetInfo?.petImage}`}  />
                            </div>
                            <div className={styles.mainContainer}>
                                <h1>{selectedPetInfo?.petName}</h1>
                                <p>{selectedPetInfo?.breed}, {selectedPetInfo?.petAge}, {selectedPetInfo?.petGender}</p>
                                {selectedPetInfo?.studFee && (
                                    <p>  Stud Fee: <span style={{ color: '#F5790C', fontSize: '16px', fontWeight: 600 }}>{selectedPetInfo.studFee}/- </span>
                                     </p>
                                    )}
                                <div className={styles.line}></div>
                                <div className={styles.featuresDiv}>
                                    <div style = {{display: 'flex'}}>
                                        <WeightIcon />
                                        <p style = {{marginLeft: '10px', marginRight: '50px'}}>{selectedPetInfo?.weight} kgs</p>
                                        {selectedPetInfo?.vaccinated ? <VaccinatedIcon /> : <RedCrossIcon />}

                                        <p style = {{marginLeft: '10px', marginRight: '50px'}}>Vaccinated</p>
                                        {selectedPetInfo?.isYourPetTrained ?  <PottyIcon /> : <RedCrossIcon />}
                                        <p style = {{marginLeft: '10px', marginRight: '50px'}}>{selectedPetInfo?.petName} Trained</p>                                    
                                    </div>
                                    <div style = {{display: 'flex'}}>
                                    {selectedPetInfo?.isYourPetGoodWithKids ? <GreenTickIcon /> : <RedCrossIcon />}
                                        <p style = {{marginLeft: '10px', marginRight: '30px'}}>Good with Kids</p>
                                        {selectedPetInfo?.isYourPetGoodWithDogs ? <GreenTickIcon /> : <RedCrossIcon />}
                                        <p style = {{marginLeft: '10px', marginRight: '30px'}}>Good with Dogs</p>
                                        {selectedPetInfo?.isYourPetGoodWithCats ? <GreenTickIcon /> : <RedCrossIcon />}
                                        <p style = {{marginLeft: '10px', marginRight: '30px'}}> Good With Cats</p>                                    
                                    </div>
                                </div>
                                <p>He is nice and he will get temperament at seeing other animals</p>
                                <div className={styles.line}></div>
                                <p style= {{color: '#121212', fontWeight: 500}}>Owner Details:</p>
                                <div style = {{display: 'flex', gap: '25px', marginBottom: '15px'}}>
                                    <img src = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png' className={styles.profilepic} />
                                    <div style={{padding: '8px'}}>
                                        <p style= {{color: '#121212', fontWeight: 500}}>Rahul Repala</p>
                                        <p> Hyderabad</p>
                                        <button className={styles.verifiedDiv}>Phone Verified</button>
                                        <button className={styles.verifiedDiv}>Email Verified</button>
                                    </div>
                                </div>
                                <div className={styles.line}></div>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <p>Published: {formatDate(selectedPetInfo?.createdDate)}</p>
                                    <p>Last Active: 59 sec ago</p>
                                </div>
                                <div className={styles.line}></div>
                            </div>
                            <button className = {styles.messageButton} onClick={() => setIsChatscreenOpen(true)}> 
                                Message
                            </button>
                            <div className={styles.crossButton} onClick={() => setIspetInfoPopupOpen(false)}>
                                <CrossIcon />
                            </div>
                        </div>}
                    </div>
                </div>
            )}
        </>
    );
};

export default PetInfoPopup;
