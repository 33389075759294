import React, { useRef } from 'react';
import styles from './OptionsViewPopup.module.css';
import { CrossIcon, GreenTickIcon, PottyIcon, RedCrossIcon, VaccinatedIcon, WeightIcon } from '../../../../assets/SVG';
import { BACKEND_URL } from "../../../../Utilities/Constant";


const OptionsViewPopup = ({ onClose,petData }) => {
  const popupRef = useRef(null);
  return (
    <div className={styles.Background}>
      <div style={{ position: 'relative' }}>
        <div className={styles.popupContainer} ref={popupRef}>
          <div>
            <img src={`${BACKEND_URL}${petData.petImage}`} a alt="Victoria" style={{  borderTopLeftRadius: "0px",
     borderTopRightRadius: "0px"}}/>
          </div>
          <div className={styles.mainContainer}>
            <h1>{petData.petName}</h1>
            <p>{petData.breed}, {petData.petAge}, {petData.petGender}</p>
            <p>Stud Fee: <span style={{ color: '#F5790C', fontSize: '16px', fontWeight: 600 }}>15,000/-</span></p>
            <div className={styles.line}></div>
            <div className={styles.featuresDiv} style={{marginBottom:"15px"}}>
              <div style={{ display: 'flex' }}>
                <WeightIcon />
                <p style={{ marginLeft: '10px', marginRight: '50px' }}>{petData.weight} kgs</p>
                {!petData.vaccinated || petData.vaccinated === "No" ? <RedCrossIcon /> : <VaccinatedIcon />}
                <p style={{ marginLeft: '10px', marginRight: '50px' }}>Vaccinated</p>
                {!petData.isYourPetTrained||petData.isYourPetTrained==="No"?<RedCrossIcon />:<PottyIcon />}
                <p style={{ marginLeft: '10px', marginRight: '50px' }}>Potty Trained</p>
              </div>
              <div style={{ display: 'flex' }}>
              {!petData.isYourPetGoodWithKids||petData.isYourPetGoodWithKids==="No"?<RedCrossIcon />: <GreenTickIcon />}
                <p style={{ marginLeft: '10px', marginRight: '15px' }}>Good with Kids</p>
                {!petData.isYourPetGoodWithDogs||petData.isYourPetGoodWithDogs==="No"?<RedCrossIcon />: <GreenTickIcon />}
                <p style={{ marginLeft: '10px', marginRight: '15px' }}>Good with Dogs</p>
                {!petData.isYourPetGoodWithCats||petData.isYourPetGoodWithCats==="No"?<RedCrossIcon />: <GreenTickIcon />}
                <p style={{ marginLeft: '10px', marginRight: '15px' }}>Not Good With Cats</p>
              </div>
            </div>
            <p>He is nice and he will get temperament at seeing other animals</p>
          </div>
          <div className={styles.crossButton} onClick={onClose}>
            <CrossIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionsViewPopup;
