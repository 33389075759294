import React from "react"
import styles from './ui.module.css'

const CustomInputElement = ({question, width = '45%', backgroundColor = 'rgba(243, 243, 243, 0.50)', placeholder,onChange,value},CustomInputElement1,CustomInputElementAddPet) => {
    
    return(
        <div className={styles.inputDiv}  style = {{ flex: width === '94%' ? `1 1 ${width}` : '',width: width,padding:"0px 0px 23px 0px",
        borderBottom: CustomInputElement1?.borderBottom || "",
        gap:"0px",}}>
            <p style={{
                color:"#000000",
                marginBottom:"30px"
            }}>{question}</p>
            <input type="text" style = {{backgroundColor: backgroundColor,border:"1px solid #D9D9D9",boxShadow: "none", }} placeholder={placeholder}         value={value} 
        onChange={(e) => onChange(e)} className="input" />            
        </div>
    )
}

export default CustomInputElement