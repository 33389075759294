import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import "./../../styles/GroomingDashboardStyles.css";

const GroomingDashboardTable = () => {
  const appointments = [
    {
      date: "2024-04-25",
      time: "09:00 AM",
      service: "Haircut",
      customer: "John Doe",
    },
    {
      date: "2024-04-26",
      time: "10:30 AM",
      service: "Nail Trimming",
      customer: "Jane Smith",
    },
    {
      date: "2024-04-25",
      time: "09:00 AM",
      service: "Haircut",
      customer: "John Doe",
    },

    {
      date: "2024-04-26",
      time: "10:30 AM",
      service: "Nail Trimming",
      customer: "Jane Smith",
    },
    {
      date: "2024-04-25",
      time: "09:00 AM",
      service: "Haircut",
      customer: "John Doe",
    },

    {
      date: "2024-04-26",
      time: "10:30 AM",
      service: "Nail Trimming",
      customer: "Jane Smith",
    },
    {
      date: "2024-04-25",
      time: "09:00 AM",
      service: "Haircut",
      customer: "John Doe",
    },

    {
      date: "2024-04-26",
      time: "10:30 AM",
      service: "Nail Trimming",
      customer: "Jane Smith",
    },
  ];

  return (
    <div className="mt-1">
      <h3>Today Appointments</h3>
      <TableContainer
        style={{ maxHeight: "175px", overflowY: "scroll" }}
        className="tableScrollStyle"
      >
        <Table variant="striped" colorScheme="darkgreen">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Time</Th>
              <Th>Service</Th>
              <Th>Customer</Th>
            </Tr>
          </Thead>
          <Tbody>
            {appointments.map((appointment, index) => (
              <Tr
                key={index}
                bg={index % 2 === 0 ? "#005555" : "white"}
                color={index % 2 === 0 ? "white" : "#005555"}
              >
                <Td>{appointment.date}</Td>
                <Td>{appointment.time}</Td>
                <Td>{appointment.service}</Td>
                <Td>{appointment.customer}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default GroomingDashboardTable;
