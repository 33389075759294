import React from "react";
import {
  Image,
  Flex,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Wrap,
  WrapItem,
  Avatar,
} from "@chakra-ui/react";
import logo from "./../../../../assets/images/ZAANVAR_FINAL LOGO.png";

const DoctorNavbar = () => {
  return (
    <Flex
      p={4}
      // bgGradient="linear(to-r, teal.500, teal.400, blue.500)"
      // color="white"
      alignItems="center"
      justifyContent="space-between"
      boxShadow="md"
      borderRadius="xl"
      px={6}
      py={4}
      mx={4}
      my={8}
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        borderRadius: "xl",
        background:
          "linear-gradient(to right, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.2) 5%, rgba(0,0,0,0.2) 100%)",
        zIndex: -1,
      }}
    >
      <Box className="d-flex">
        <Image src={logo} alt="Logo" boxSize="80px" />
        <h3>Doctor</h3>
      </Box>

      <Menu>
        <MenuButton as={Box} className="d-flex">
          <Wrap>
            <WrapItem>
              <Avatar name="Dan Abrahmov" src="https://bit.ly/dan-abramov" />
            </WrapItem>
          </Wrap>
        </MenuButton>
        <MenuList
          bg="white"
          color="black"
          borderRadius="lg"
          boxShadow="lg"
          zIndex={500}
        >
          <MenuItem>My Profile</MenuItem>
          <MenuItem>Logout</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default DoctorNavbar;
