import React, { useState } from "react";
import popupimg1 from "../../../assets/images/popup-img.png";
import popupimg2 from "../../../assets/images/popup-img2.png";
import popupimg3 from "../../../assets/images/gallary.png";
import close from "../../../assets/images/clsoe-popup.png";
import { WebApimanager } from "../../../WebApiManager";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


import "./MyPost.css";
import { color } from "framer-motion";

const FoundPopup = ({ isOpen, onClose }) => {
  const webApi = new WebApimanager();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const [formData, setFormData] = useState({
    petType: "",
    breed: "",
    petGender: "",

    email: "",
    mobileNumber: "",
    emergencyMobileNumber: "",
    petName: "",
    age: "",

    identification: "",
    lostAddress: "",
    addressToReturn: "",
    dateOfLastSeen: "",
    petImage: null,
    priceMoney: "",
    color:"",
  });

  const [breedOptions, setBreedOptions] = useState([]);

  const getDogTypes = async () => {
    try {
      const dogResponse = await webApi.get("dogAndCat/dogTypes");
      if (dogResponse.status === 200) {
        const breed = dogResponse.data.data.map((breed) => breed.name);
        setBreedOptions(breed);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const getcatTypes = async () => {
    try {
      const dogResponse = await webApi.get("dogAndCat/catTypes");

      if (dogResponse.status === 200) {
        const breed = dogResponse.data.data.map((breed) => breed.name);
        setBreedOptions(breed);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [name]: value };

      if (name === "petType") {
        if (value === "Dog") {
          const dogTypesList = getDogTypes().catch((error) =>
            console.error("Error fetching dog breeds:", error)
          );
        } else if (value === "Cat") {
          const catTypesList = getcatTypes();
        } else {
          setBreedOptions([]);
        }
      }

      return updatedFormData;
    });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, petImage: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      petAge,
      petName,
      petType,
      breed,
      addressToReturn,
      email,
      mobileNumber,
      emergencyMobileNumber,
      identification,
      petGender,
      priceMoney,
      lostAddress,
      dateOfLastSeen,
      color,
    } = formData;

    const payload = {
      age: petAge,
      petName: petName,
      petStatus: "Lost",
      petType: petType,
      breed: breed,
      dateOfLastSeen: dateOfLastSeen,
      email: email,
      mobileNumber: mobileNumber,
      emergencyMobileNumber: emergencyMobileNumber,
     
      addressToReturn: addressToReturn,
      petGender: petGender,
      identification: identification,
      priceMoney: priceMoney,
      lostAddress: lostAddress,
      color:color,
    };
    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });
    const payloadimg = {
      petImage: formData.petImage,
    };

    try {
      const response = await webApi.post("lostAndFound/lost", payload);
      const petId = response.data.data.lostPet._id;

      if (formData.petImage) {
        const imageUploadResponse = await webApi.imagePut(
          `lostAndFound/updatePetImageInLost/${petId}`,
          payloadimg
        );
      } else if (response.status === 400) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
          console.log("Response data:", response.data.message);
        }
        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }
      if (response.data.status === "success") {
        toast("Pet Lost Successfully");
        setFormData({
          age: "",
          petName: "",
          petStatus: "Lost",
          petType: "",
          color: "green",
          breed: "",
          dateofLastSeen: "",
          email: "",
          mobileNumber: "",
          emergencyMobileNumber: "",
          currentPetStatus: "Rehomed",
          addressToReturn: "",
          gender: "",
          identification: "",
          priceMoney: "",
          lostAddress: "",
          color:"",
        });
        window.location.reload();
      }
    } catch (err) {
      console.error("Error occurred:", err);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;
  return (
    <>
      <ToastContainer />
    <section>
      <div className="lost-and-found-Lost-popup-overlay">
        <div className="i">
          <div className="lost-and-found-popup-rightimg">
            <img src={popupimg1} alt="close" />
          </div>
          <div className="lost-and-found-popup-middle-text">
            Find a Report
            <div style={{ marginTop: "5px" }}>Lost Pets</div>
          </div>
          <div className="lost-and-found-popup-leftimg">
            {" "}
            <img src={popupimg2} alt="close" />{" "}
          </div>
        </div>

        <div className="lost-and-found-Lost-popup-content">
          <div className="lost-and-found-found-popup-hearder">
            <div className="lost-and-found-popup-select-lost">
              {" "}
              <button
                className="lost-and-found-post-popup-close"
                onClick={onClose}
              >
                <img src={close} alt="Close" />
              </button>
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            style={{
              paddingBottom: "40px",
              paddingRight: "30px",
              paddingTop: "50px",
              paddingLeft: "50px",
            }}
          >
            <table
              style={{
                overflowY: "scroll",
                width: "100%",
                tableLayout: "fixed",
                paddingTop: "50px",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{ width: "50%", textAlign: "left" }}
                    className="lost-and-found-td"
                  >
                    Name of the Pet{" "}
                  </td>

                  <td
                    style={{ width: "50%", textAlign: "left" }}
                    className="lost-and-found-td"
                  >
                    Pet Type
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="text"
                      name="petName"
                      value={formData.petName}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      placeholder="Enter Breed Name"
                      required
                    />
                  </td>
                  <td
                    style={{
                      paddingRight: "20px",
                      paddingTop: "8px",
                      paddingBottom: "20px",
                    }}
                  >
                    <select
                      name="petType"
                      value={formData.petType}
                      onChange={handleChange}
                      required
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                        padding:"0px 0px 0px 10px" 

                      }}
                    >
                      <option value="">Select Type</option>
                      <option value="Dog">Dog</option>
                      <option value="Cat">Cat</option>
                      <option value="other">Other</option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td className="lost-and-found-td">Breed Name</td>

                  <td className="lost-and-found-td">Gender</td>
                </tr>
                <tr>
                  <td
                    style={{
                      paddingRight: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    {formData.petType === "Dog" ||
                    formData.petType === "Cat" ? (
                      <select
                        name="breed"
                        value={formData.breed}
                        required
                        onChange={handleChange}
                        style={{
                          height: "30px",
                          backgroundColor: "#F3F3F380",
                          border: "1px solid #D9D9D9",
                          padding:"0px 0px 0px 10px" 

                        }}
                      >
                        <option value="">Select Breed</option>
                        {breedOptions.map((breed) => (
                          <option key={breed} value={breed}>
                            {breed}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        name="breed"
                        // value={formData.breedName}
                        onChange={handleChange}
                        placeholder="Enter Breed Name"
                        style={{
                          height: "30px",
                          backgroundColor: "#F3F3F380",
                          border: "1px solid #D9D9D9",
                        }}
                      />
                    )}
                  </td>
                  <td
                    style={{
                      paddingRight: "20px",
                      paddingTop: "8px",
                      paddingBottom: "20px",
                    }}
                  >
                    <select
                      name="petGender"
                      value={formData.petGender}
                      onChange={handleChange}
                      required
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                        padding:"0px 0px 0px 10px" 

                      }}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="lost-and-found-td" style={{ padding: "8px" }}>
                    Age
                  </td>

                  <td className="lost-and-found-td" style={{ padding: "8px" }}>
                    Date of Last Seen
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="text"
                      name="age"
                      value={formData.age}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="date"
                      name="dateOfLastSeen"
                      value={formData.dateOfLastSeen}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                </tr>

                <tr>
                  <td className="lost-and-found-td" style={{ padding: "8px" }}>
                    Mobile Number:
                  </td>

                  <td className="lost-and-found-td" style={{ padding: "8px" }}>
                    Emergency Mobile Number:
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="text"
                      name="mobileNumber"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="text"
                      name="emergencyMobileNumber"
                      value={formData.emergencyMobileNumber}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td className="lost-and-found-td" style={{ padding: "8px" }}>
                    Address to Return
                  </td>

                  <td className="lost-and-found-td" style={{ padding: "8px" }}>
                    Price Money
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="text"
                      name="addressToReturn"
                      value={formData.addressToReturn}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="text"
                      name="priceMoney"
                      value={formData.priceMoney}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td className="lost-and-found-td" style={{ padding: "8px" }}>
                    Identification:
                  </td>
                  <td className="lost-and-found-td" style={{ padding: "8px" }}>
                    Color
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="text"
                      name="identification"
                      value={formData.identification}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                  <input
                      type="text"
                      name="color"
                      value={formData.color}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td className="lost-and-found-td" style={{ padding: "8px" }}>
                  Lost Address
                  </td>
                  
                </tr>
                <tr>
                  <td colSpan="3" style={{ paddingRight: "20px", paddingBottom: "20px", }}>
                  
                     <input
                      type="text"
                      name="lostAddress"
                      value={formData.lostAddress}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                  
                </tr>
                <tr>
                  <td className="lost-and-found-td" style={{ padding: "8px" }}>
                    Upload Picture of the Pet:
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ display: "flex" }}>
                      <input
                        type="file"
                        name="petImage"
                        onChange={handleFileChange}
                        style={{
                          width: "385px",
                          height: "80px",
                          backgroundColor: "#F3F3F3",
                          border: "1px solid #D9D9D9",
                          position: "relative",
                          backgroundImage: `url(${popupimg3})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          cursor: "pointer",
                        }}
                        id="fileInput"
                        required
                      />
                    </div>
                  </td>
                  <td>
                    {/* Display the selected file's name */}
                    {formData.petImage ? (
                      <div style={{ display: "flex" }}>
                        <p
                          style={{
                            display: "flex",
                            fontStyle: "normal",

                            color: "#727271",
                            fontSize: "12px",
                            lineHeight: "normal",
                            fontWeight: "400",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                          }}
                        >
                          Selected File: {formData.petImage.name}
                        </p>
                        {formData.petImage.type.startsWith("image/") && (
                          <img
                            src={URL.createObjectURL(formData.petImage)}
                            alt="Selected"
                            style={{
                              width: "100px",
                              height: "90px",
                              marginLeft: "10px",
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          lineHeight: "normal",
                          fontWeight: "400",
                        }}
                      >
                        No file selected
                      </p>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      className="lost-and-found-popup-button2"
                    >
                      Submit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </section>
    </>
  );
};

export default FoundPopup;
