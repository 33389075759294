import React from 'react'
import styles from "./GroomingBookings.module.css"
import Header from '../Header/Header'

const GroomingBookings = () => {
  return (
    <>
    <Header/>
    <main className={styles.GBMainContainer}>
        <section className={styles.GBMainContentContainer}>
            <h4>Select Your Pets</h4>
            <div className={styles.GBSelectedPetsContainer}>
                <div className={styles.GBSelectedPetsCardContainer}>
                    <div className={styles.GBSelectedPetsCard}>
                        <div className={styles.GBSelectedPetsCardImgContainer}>
                            <img src="" alt="" />
                        </div>
                        <div className={styles.GBSelectedPetsCardDetailsContainer}>
                            <h6>Simba</h6>
                            <p>Hyderabad Male 2Years</p>
                        </div>
                    </div>
                    <div className={styles.GBSelectedPetsCard}>
                        <div className={styles.GBSelectedPetsCardImgContainer}>
                            <img src="" alt="" />
                        </div>
                        <div className={styles.GBSelectedPetsCardDetailsContainer}>
                            <h6>Simba</h6>
                            <p>Hyderabad Male 2Years</p>
                        </div>
                    </div>
                    <div className={styles.GBSelectedPetsCard}>
                        <div className={styles.GBSelectedPetsCardImgContainer}>
                            <img src="" alt="pic" />
                        </div>
                        <div className={styles.GBSelectedPetsCardDetailsContainer}>
                            <h6>Simmba</h6>
                            <p>Hyderabad Male 2Years</p>
                        </div>
                    </div>
                </div>
                <div className={styles.GBHomeContainer}>
                    <select >
                        <option value="Home">Home</option>
                    </select>
                </div>
            </div>
            <div className={styles.GBUnderline}></div>
            <div className={styles.MainCalenderContainer}>
                {/* for calender */}
            </div>
            <div>
                {/* for checkbox */}
            </div>
        </section>
    </main>
    </>
  )
}

export default GroomingBookings