import React, { useEffect, useState } from "react";
import "./Login.css";
import bgimg from "../assets/images/cat-love-being-affectionate-towards-each-other 1.png";
import zaanvarlogo from "../assets/images/ZAANVAR_FINAL LOGO.png";
import { Checkbox } from "@chakra-ui/react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { WebApimanager } from "../WebApiManager";
import * as Icon from "react-bootstrap-icons";
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtToken, userInfo } from "../Atoms";
import { ThreeDots } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  let webApi = new WebApimanager();
  const [userlogin, setUserlogin] = useState(true);
  const navigate = useNavigate(); 

  const [loginDetails, setLoginDetails] = useState({
    name: "",
    email: "",
    passWord: "",
  });
  // State for toggling password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showsiginPassword, setsiginShowPassword] = useState(false);
  const [accessToken, setAccessToken] = useRecoilState(jwtToken);
  const setUserInfoData = useSetRecoilState(userInfo);
    // const location = useLocation();
    // const from = location.state?.from || '/';
  const [details, setDetails] = useState({
    email: "",
    passWord: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    passWord: "",
  });
  console.log("email", details.email);

  const signuppage = () => {
    setUserlogin(false);
    setErrors({ err: "", pwd: "", error: "", passWord: "" });
  };
  const loginpage = () => {
    setUserlogin(true);
    setErrors({ err: "", pwd: "", error: "", passWord: "" });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({ ...prev, [name]: value }));

    if (name === "email") {
      setErrors((prev) => ({
        ...prev,
        email: validateEmail(value) ? "" : "Invalid email format",
      }));
    } else if (name === "passWord") {
      setErrors((prev) => ({
        ...prev,
        passWord: validatePassword(value)
          ? ""
          : "Password needs 1 uppercase, 1 lowercase, 1 number, and 8+ characters.",
      }));
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginDetails((prev) => ({ ...prev, [name]: value }));

    if (name === "email") {
      setErrors((prev) => ({
        ...prev,
        err: validateEmail(value) ? "" : "Invalid email format",
      }));
    } else if (name === "passWord") {
      setErrors((prev) => ({
        ...prev,
        pwd: validatePassword(value)
          ? ""
          : "Password needs 1 uppercase, 1 lowercase, 1 number, and 8+ characters.",
      }));
    }
  };

  const handleSignUpForm = async (e) => {
    e.preventDefault();
    let formErrors = {
      name: "",
      email: "",
      passWord: "",
    };
    if (!loginDetails.name) {
      formErrors.name = "Name is required.";
    }
    if (!loginDetails.email) {
      formErrors.email = "Email is required.";
    } else if (!validateEmail(loginDetails.email)) {
      formErrors.email = "Please enter a valid email.";
    }
    if (!loginDetails.passWord) {
      formErrors.passWord = "Password is required.";
    } else if (!validatePassword(loginDetails.passWord)) {
      formErrors.passWord =
        "Password needs 1 uppercase, 1 lowercase, 1 number, and 8+ characters.";
    }


    if (formErrors.name || formErrors.email || formErrors.passWord) {
      setErrors(formErrors); 
      return; 
    }
    try {
      const response = await webApi.post("users", loginDetails);
      toast.success("Logged in Successfully!");
    } catch (error) {
      toast.error("invalid credentials");

      console.log(error.message);
     
    }
  };
  const location = useLocation(); 
  const handleLoginSubmit = async (e) => {
    e.preventDefault();


    if (!details.email || !validateEmail(details.email)) {
      setErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address",
      }));
      return; 
    }

    if (!details.passWord || !validatePassword(details.passWord)) {
      setErrors((prev) => ({
        ...prev,
        passWord:
          "Password needs 1 uppercase, 1 lowercase, 1 number, and 8+ characters.",
      }));
      return;
    }
    try {
      webApi
        .postwithouttoken("users/login", details)
        .then((response) => {
          const data = response.data;
          console.log("Server Response:", response);
          console.log("Data:", data);
        })
        .catch((error) => {
          console.log("error", error);
        });
      const response = await webApi.post("users/login", details);
      setAccessToken(response.data.token);
      setUserInfoData(response.data.user);
      toast.success("Logged in Successfully!");
      
      // navigate("/", { state: { email: details.email } });
    
      const from = location.state?.from || "/";
      navigate(from, { replace: true });
    } catch (error) {
      console.log(error.message);
      toast.error("Invalid Credentials!");
    }
  };
  const forgotpassword = () => {
    navigate("/forgotpass");
  };
  const [isImageLoading, setIsImageLoading] = useState(true);


  return (
    <>
          <ToastContainer />
      {userlogin ? (
        <div className="login-signmainHeader">
          <div className="login-containerbody login-containeronly">
            <div className="login-container">
              <div className="login-left-side">
                <div className="login-zaanvar-icon">
                  <img src={zaanvarlogo} style={{ width: "100px" }}></img>
                </div>
                <div className="login-welcome">
                  <p style={{ fontSize: "24px", fontWeight: "500",marginBottom:"16px" }}>
                    Welcome Back!{" "}
                  </p>
                </div>
                <div className="login-l1">
                  <label>
                    The faster you fill up, the faster you get bookings
                  </label>
                </div>
                <div>
                  <form className="login-form" onSubmit={handleLoginSubmit}>
                    <p
                      style={{
                        marginBottom: "6px",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        textAlign: "left",
                      }}
                    >
                      Email
                    </p>
                    <input
                      type="text"
                      className="login-field"
                      name="email"
                      placeholder="Enter your email"
                      onChange={handleLoginChange}
                    />
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                    <p
                      style={{
                        marginBottom: "6px",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        textAlign: "left",
                        marginTop: "5px",
                      }}
                    >
                      Password
                    </p>

                    <div style={{ position: "relative" }}>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="login-field"
                        name="passWord"
                        placeholder="Enter your password here"
                        onChange={handleLoginChange}
                      />
                      <div
                        className="pwd-eye"
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {showPassword ? <Icon.Eye /> : <Icon.EyeSlash />}
                      </div>
                      {errors.passWord && (
                        <span className="error">{errors.passWord}</span>
                      )}
                    </div>

                    <label style={{ display: "flex", marginTop: "10px" }}>
                      <input
                        type="checkbox"
                        name="remember"
                        style={{
                          width: "13px",
                          height: "13px",
                          display: "flex",
                          marginBottom: "10px",
                        }}
                      />

                      <label
                        style={{
                          fontSize: "11px",
                          marginTop: "-2px",
                          marginLeft: "5px",
                        }}
                      >
                        Remember me{" "}
                      </label>
                      <label
                        style={{
                          marginLeft: "126px",
                          fontSize: "11px",
                          marginTop: "-2px",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        className="login-forgotpass"
                        onClick={forgotpassword}
                      >
                        Forgot Password
                      </label>
                    </label>

                    <button className="login-button">SIGN IN</button>
                  </form>
                  <div className="login-bottom">
                    <label style={{ display: "flex", textAlign: "center" }}>
                      Don’t have an account?
                      <p
                        style={{
                          fontSize: "10px",
                          margin: "0 5px",
                          color: "blue",
                        }}
                        onClick={signuppage}
                      >
                        Sign up
                      </p>
                    </label>
                  </div>
                </div>
              </div>

              <div className="login-column2">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fba49d91683734bbda19e95744089a935"
                  className="fixed-bg-image"
                  alt="Background"
                ></img>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="login-signmainHeader">
          <div className="login-containerbody login-containeronly">
            <div className="login-container">
              <div className="login-left-side">
                <div className="login-zaanvar-icon">
                  <img src={zaanvarlogo} style={{ width: "100px" }}></img>
                </div>
                <div className="signin-welcome">
                  <p style={{ fontSize: "20px" }}>Create your account </p>
                </div>

                <div>
                  <form className="login-form" onSubmit={handleSignUpForm}>
                    <p
                      style={{
                        marginBottom: "6px",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        textAlign: "left",
                      }}
                    >
                      Name
                    </p>
                    <input
                      type="text"
                      className="login-field"
                      placeholder="Enter your Name"
                      name="name"
                      value={loginDetails.name}
                      onChange={handleChange}
                    ></input>
                    {errors.name && (
                      <span className="error">{errors.name}</span>
                    )}
                    <p
                      style={{
                        marginBottom: "6px",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        textAlign: "left",
                      }}
                    >
                      Email
                    </p>
                    <input
                      type="email"
                      className="login-field"
                      placeholder="Enter your email"
                      name="email"
                      value={loginDetails.email}
                      onChange={handleChange}
                    ></input>
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}{" "}
                    {/* Display error for email */}
                    <p
                      style={{
                        marginBottom: "6px",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        textAlign: "left",
                      }}
                    >
                      Password
                    </p>
                    <div style={{ position: "relative" }}>
                      <input
                        type={showsiginPassword ? "text" : "password"}
                        className="login-field"
                        placeholder="Enter your password here"
                        name="passWord"
                        value={loginDetails.passWord}
                        onChange={handleChange}
                      ></input>
                      <div
                        style={{
                          justifyItems: "flex-end",
                          marginTop: "-24",
                          marginBottom: "10px",
                          marginRight: "10px",
                          cursor: "pointer",
                          marginTop: "-24px",
                        }}
                        onClick={() => setsiginShowPassword((prev) => !prev)} // Toggle password visibility
                      >
                        {showsiginPassword ? <Icon.Eye /> : <Icon.EyeSlash />}
                      </div>
                    </div>
                    {errors.passWord && (
                      <span className="error">{errors.passWord}</span>
                    )}
                    <label style={{ display: "flex" }}>
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        name="remember"
                        style={{
                          width: "15px",
                          display: "flex",
                          marginBottom: "10px",
                          marginTop: "17px",
                        }}
                      />
                      <label
                        style={{
                          fontSize: "11px",
                          marginTop: "17px",
                          marginLeft: "5px",
                        }}
                      >
                        I agree to all Term, Privacy Policy and Fees
                      </label>
                    </label>
                    <button className="login-button">SIGN IN</button>
                  </form>
                  <div className="signin-bottom">
                    <label
                      style={{
                        display: "flex",
                        textAlign: "center",
                        marginTop: "-25px",
                      }}
                    >
                      Already have an account?
                      <p
                        style={{
                          fontSize: "10px",
                          margin: "0 5px",
                          color: "blue",
                        }}
                        onClick={loginpage}
                      >
                        Sign in
                      </p>
                    </label>
                  </div>
                </div>
              </div>

              <div className="login-column2">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fba49d91683734bbda19e95744089a935"
                  className="fixed-bg-image"
                  alt="Background"
                ></img>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
