import React, { useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import "./../../styles/GroomingAppointmentPageStyles.css";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const InvoiceGenerator = () => {
  const [customerName, setCustomerName] = useState("");
  const [totalAmount, setTotalAmount] = useState("");

  const [showPDF, setShowPDF] = useState(false);

  const handleSave = () => {
    setShowPDF(true);
  };

  const handleDownload = () => {
    setShowPDF(false);
  };

  return (
    <VStack spacing={8} alignItems="flex-start">
      {!showPDF ? (
        <FormControl>
          <FormLabel>Customer Name</FormLabel>
          <Input
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
          <FormLabel>Total Amount</FormLabel>
          <Input
            type="number"
            value={totalAmount}
            onChange={(e) => setTotalAmount(e.target.value)}
          />
          <Button colorScheme="blue" onClick={handleSave}>
            Save
          </Button>
        </FormControl>
      ) : (
        <PDFViewer width="1000" height="600" className="checking">
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <Text>Invoice</Text>
                  <Text>Customer Name: {customerName}</Text>
                  <Text>Total Amount: {totalAmount}</Text>
                </div>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )}
      {showPDF && (
        <Button colorScheme="green" onClick={handleDownload}>
          Download PDF
        </Button>
      )}
    </VStack>
  );
};

export default InvoiceGenerator;
