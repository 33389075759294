import React from "react";
import DoctorNavbar from "./Components/DoctorDashboard/DoctorNavbar";
import DoctorSideNavbar from "./Components/DoctorDashboard/DoctorSideNavbar";
import DoctorDashboardBody from "./Components/DoctorDashboard/DoctorDashboardBody";

export default function DoctorDashboard() {
  return (
    <>
      <div className="h-100">
        <DoctorNavbar />
        <div className="d-flex">
          <DoctorSideNavbar />
          <div className="d-flex flex-column">
            <DoctorDashboardBody />
          </div>
        </div>
      </div>
    </>
  );
}
