import React from "react";
import { Card, CardBody, Image, Button } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import "./../../styles/CustomerStyles.css";
import CustomerNavbar from "../CustomerNavbar";
import * as Unicons from "@iconscout/react-unicons";
import pg from "./../../../../assets/customer/services/petGrooming.png";
import wPDF from "./../../../../assets/customer/services/Pw.pdf";

export default function PetGromming() {
  const groomingServices = [
    "Bathing",
    "Hair Trimming and Styling",
    "Nail Clipping",
    "Ear Cleaning",
    "Brushing and De-shedding",
    "Teeth Brushing",
    "Skin and Coat Conditioning",
    "Flea and Tick Treatment",
    "Anal Gland Expression",
    "Specialty Treatments",
  ];

  const handleDownload = () => {
    const pdfUrl = wPDF;

    const link = document.createElement("a");
    link.href = pdfUrl;

    link.setAttribute("download", "file_name.pdf");

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <>
      <div className="CustomerDashboard">
        <div className="CustomerNavbar">
          <Card  width="100%">
            <CardBody>
              <CustomerNavbar />
            </CardBody>
          </Card>
        </div>
        <div className=" CustomerDashboardBody">
          <div className="WelcomeCard">
            <Card backgroundColor="#496989">
              <CardBody className="d-flex  align-items-center">
                <div className="col-lg-7 poppins-medium col-md-12 col-sm-12 col-xs-12 p-1">
                  <h3>Grooming Service</h3>
                  <p className="serviceDescription">
                    Pet grooming is a specialized service focused on the
                    hygiene, appearance, and overall well-being of our animal
                    companions. It encompasses a range of tasks including
                    bathing, brushing, trimming fur, nail clipping, and ear
                    cleaning. Beyond simply enhancing their appearance, grooming
                    plays a crucial role in maintaining the health of pets.
                    Regular grooming helps prevent matting, reduces shedding,
                    and keeps the skin and coat healthy. Additionally, grooming
                    sessions provide opportunities for early detection of health
                    issues such as skin infections or parasites. Ultimately, pet
                    grooming is not just about aesthetics; it's about ensuring
                    the comfort, health, and happiness of our furry friends,
                    enriching their lives and deepening the bond between pets
                    and their owners.
                  </p>
                  <h4>Services</h4>
                  <ul>
                    {groomingServices.map((service, index) => (
                      <li key={index} className="serviceLi">
                        {service}
                      </li>
                    ))}
                  </ul>
                  <Button colorScheme="teal" size="md">
                    Book an appointment
                  </Button>
                </div>

                <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                  <Image src={pg} className="rounded" />
                </div>
              </CardBody>
            </Card>
          </div>
          <br />
          <div className="InfoCard">
            <Card backgroundColor="lightblue">
              <CardBody>
                <Accordion allowToggle>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          className="sessionTitle"
                        >
                          Bathing for Rocky
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <div className="d-flex justify-content-between p-1">
                        <p className="col-lg-9 upcomingEventCardClinicName">
                          <Unicons.UilBuilding className="d-inline mx-1" />
                          Service Center Name <br />
                          <Unicons.UilLocationPoint className="d-inline mx-1" />
                          Gachibowli, Hyderabad
                        </p>
                        <p className="col-lg-3">
                          <Unicons.UilCalender className="d-inline mx-1" />
                          06-04-2024 <br />
                          <Unicons.UilClock className="d-inline mx-1" />
                          06:00 PM{" "}
                        </p>
                      </div>
                      <button onClick={handleDownload}>Download PDF</button>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
