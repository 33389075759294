
import React, { useState } from "react";
import styles from "./GroomingServices.module.css"
import { CrossIcon } from "../../assets/SVG";
import DogCard from "./GroomingImages/DogCard.png"
import ownerpic from "./GroomingImages/ownerpic.png"
import { MdLocationPin } from "react-icons/md";

const GroomingCardDetails = ({ isOpen, onClose, pet, onMessageClick }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.petdetailspopupoverlay}>
      <div className={styles.petDetailsPopupcontent}>
        <div className={styles.crossButton} onClick={onClose}>
          <CrossIcon />
        </div>
        <div className={styles.petDetaildsPopupHearder}>
          <img
            // src={pet.petImage ? `${BACKEND_URL}${pet.petImage}` : img}
            src={DogCard}
            alt="Pet"
            style={{ width: "100%", height: "50%" }}
          />
        </div>
        <div className={styles.petDetailsPopupContentText}>
          <div>
            <h2 className={styles.petDetailsPetName}>Pet</h2>

            <div style={{ display: "flex", width: "100%" ,paddingLeft:"50px"}}>
            <MdLocationPin style={{fontSize:"16px",color:"#727272"}}/>
              <p style={{color:"#727272"}}>Hyderabad</p>
        
            </div>
            
          </div>
          <div className={styles.petDetailsunderLine}></div>
          <div className={styles.petDetailsDetails}>
            <div className={styles.petDetailsDetailstext}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt,
              facere unde sed suscipit a, temporibus voluptas explicabo cumque
              aspernatur obcaecati natus est autem doloribus dolor ipsam animi
              consectetur, voluptatem aliquam!
            </div>
          </div>
          <div className={styles.petDetailsunderLine}></div>
          <div className={styles.petOwnerDetails}>Details</div>
          <div style={{ display: "flex" }} className={styles.petOwnerprofile}>
            <img src={ownerpic} style={{ marginBottom: "10px" }}></img>
            <div style={{ marginLeft: "10px", marginTop: "5px" }}>
              <p className={styles.petDetailProfileName}>
                Name
              </p>
              <p className={styles.petDetailProfileloction}>Hyderabad</p>
              <p className={styles.petDetailProfileMobile}>
                Mobile No.: 111111111
              </p>
            </div>
          </div>
          <div className={styles.petDetailsunderLine}></div>
          <div className={styles.petDetailflexContainer}>
            <p className={styles.petDetailFoundDate}>
              Date of Upload: 02 Oct 2024
            </p>
            <p className={styles.petDetailTime}>Last Active: 59 sec ago</p>
          </div>
          <div className={styles.petDetailbutton} onClick={onMessageClick}>
            Call
          </div>

          <div></div>
        </div>
      </div>
    </div>
  );
};



export default GroomingCardDetails;