import React, { useState, useEffect } from "react";
 import "./MypostCards.css";
import img1 from "../../../assets/images/Mask group.svg";
import saveIcon from "../../../assets/images/Vector.png";
import notsaveIcon from "../../../assets/images/notsave.png";
import { ChatIcon, LikeIcon, LocationIcon } from "../../../assets/SVG";
import { WebApimanager } from "../../../WebApiManager";
import { BACKEND_URL, formatDate } from "../../../Utilities/Constant";
import { ThreeDots } from 'react-loader-spinner';


const MypostCards = ({ filters, onCardClick, onChatClick, showMyPosts }) => {
  const webApi = new WebApimanager();
  const [petsData, setPetsData] = useState([]);
  const [saveStates, setSaveStates] = useState({});
  const [imageLoading, setImageLoading] = useState({}); 

  useEffect(() => {
    webApi
      .get("lostAndFound/myPosts")
      .then((response) => {
        const responseData = response.data;

        const foundPets = responseData.data.foundPets || [];
        const lostPets = responseData.data.lostPets || [];

        const allPets = [...foundPets, ...lostPets];

        setPetsData(allPets);
      })
      .catch((error) => {
        console.error("Error fetching My Posts data:", error);
        setPetsData([]);
      });
  }, [showMyPosts]);

  const handleIconClick = (id) => {
    setSaveStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleImageLoad = (petId) => {
    setImageLoading((prev) => ({ ...prev, [petId]: false }));
  };

  const handleImageError = (petId) => {
    setImageLoading((prev) => ({ ...prev, [petId]: false }));
  };

  console.log("peta all detials", petsData);

  return (
    <div style={{ display: "flex" }}>
      <section className="petProfileCard">
        {petsData.map((pet) => {
          return (
            <div key={pet._id} className="petProfileCard3">
              <div style={{ position: "relative" }}>
                {imageLoading[pet._id] && (
                  <ThreeDots
                    color="#1bc1e9"
                    height={50}
                    width={50}
                    ariaLabel="loading"
                  />
                )}
                <img
                  src={pet.petImage ? `${BACKEND_URL}${pet.petImage}` : img1}
                  alt={pet.petName}
                  onClick={() => {
                    onCardClick(pet);
                  }}
                  onLoad={() => handleImageLoad(pet._id)} 
                  onError={() => handleImageError(pet._id)} 
                  style={{ cursor: "pointer", width: "100%", height: "160px" }}
                />
              </div>

              <div style={{ margin: "15px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "6px",
                  }}
                >
                  <h1>{pet.petName}</h1>
                  <div style={{ display: "flex", gap: "15px" }}>
                  </div>
                </div>

                <div
  style={{
    display: "flex",
    justifyContent: "space-between", // Ensures content is at the start and end
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%", // Ensure the container spans full width
  }}
  id="lost-and-found-petId2"
>
  <div
    style={{
      width: "215px",  
      whiteSpace: "nowrap", 
      overflow: "hidden", 
      textOverflow: "ellipsis",
    }}
  >
    <span>Pet ID :</span> {pet.petId}
  </div>
  <div 
    style={{ 
      width: "130px",
      textAlign: "right",
    }}
  >
    |   Gender : {pet.petGender}
  </div>
</div>
 

                <div style={{ display: "flex", gap: "5px" }}>
                  <div
                    style={{
                      marginTop: "2px",
                      marginLeft: "5px",
                      marginRight: "2px",
                    }}
                  >
                    <LocationIcon />
                  </div>
                  <p>
                    {pet.foundLocation ? pet.foundLocation : pet.lostAddress}
                  </p>
                </div>
                <div>
                  <div style={{ display: "flex" }}>
                    <p
                      id={
                        pet.petStatus === "Lost"
                          ? "button-lost"
                          : pet.petStatus === "Found"
                          ? "button-found"
                          : "button-reunited"
                      }
                    >
                      {pet.petStatus}
                    </p>

                    {pet.petStatus !== "Found" &&
                      pet.petStatus !== "Reunited" && 
                      pet.petStatus !== "Deceased" &&
                      pet.petStatus !== "Surrendered" &&(
                        <p
                          id={
                            pet.petStatus === "Lost"
                              ? "lost-and-found-reward"
                              : "lost-and-found-reward2"
                          }
                        >
                          Reward: ₹ {pet.priceMoney}
                        </p>
                      )}
                  </div>
                  <div id="lost-and-found-line">
                    <p id="lost-and-found-lost-date">
                      {pet.foundDate
                        ? `Found Date: ${formatDate(pet.foundDate)}`
                        : `Last Date: ${formatDate(pet.dateOfLastSeen)}`}
                    </p>
                    <p id="lost-and-found-post-date">
                      Post Date : {formatDate(pet.createdDate)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {petsData.length === 0 && (
         <div
         style={{
           display: "flex",
           justifyContent:"end",  
           alignItems: "center",      
           height: "100vh",
           textAlign:"center",
           alignContent:"center",
           position:"relative",
           left:"50%",
           top:"-20%"

         }}
       >
        
         <ThreeDots
           color="#1bc1e9"
           height={100}
           width={100}
           ariaLabel="loading"
         />
       </div>
        )}
      </section>
    </div>
  );
};

export default MypostCards;
