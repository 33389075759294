import React, { useState } from "react";
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
} from "@syncfusion/ej2-react-schedule";
import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import axios from "axios"; // For making POST requests
import "@syncfusion/ej2-react-calendars/styles/material.css"; // DatePicker styles
import "@syncfusion/ej2-base/styles/material.css";

function AdvancedScheduler() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newEventTitle, setNewEventTitle] = useState("");
  const [events, setEvents] = useState([
    {
      Id: 1,
      Subject: "Meeting",
      StartTime: new Date(2023, 11, 9, 10, 0),
      EndTime: new Date(2023, 11, 9, 11, 30),
      IsAllDay: false,
    },
    {
      Id: 2,
      Subject: "Conference",
      StartTime: new Date(2023, 11, 10, 12, 0),
      EndTime: new Date(2023, 11, 10, 13, 0),
      IsAllDay: false,
    },
  ]);

  const eventSettings = {
    dataSource: events,
    allowDragAndDrop: true,
    allowResizing: true,
  };

  const handleDateSelect = (e) => {
    setSelectedDate(e.value);
    setIsModalOpen(true); // Open modal when date is selected
  };

  const handleAddEvent = () => {
    if (newEventTitle && selectedDate) {
      setEvents((prevEvents) => [
        ...prevEvents,
        {
          Id: prevEvents.length + 1,
          Subject: newEventTitle,
          StartTime: selectedDate,
          EndTime: new Date(selectedDate.getTime() + 3600000), // Add 1 hour
          IsAllDay: true,
        },
      ]);
      setIsModalOpen(false); // Close modal
      setNewEventTitle(""); // Reset title input
    }
  };

  const onPopupOpen = (args) => {
    // Check if the popup is for adding a new event
    if (args.type === "QuickInfo") {
      args.cancel = true; // Prevent default quick popup
      setSelectedDate(args.data.StartTime); // Set the selected date
      setIsModalOpen(true); // Open the custom modal
    }
  };

  return (
    <div className="scheduler-container" style={{ marginTop: "50px" }}>
      <div style={{ display: "flex" }}>
        {/* Left-side calendar */}
        <div
          className="left-calendar"
          style={{
            width: "20%",
            padding: "10px",
            borderRight: "1px solid #e0e0e0",
            boxShadow: "1px 0px 5px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ textAlign: "center", marginBottom: "20px" }}>
            Calendar
          </h4>
          <CalendarComponent
            value={selectedDate}
            change={handleDateSelect}
            min={new Date(2023, 0, 1)}
            max={new Date(2023, 11, 31)}
            isMultiSelection={false}
          />
        </div>

        {/* Scheduler */}
        <div style={{ width: "80%", paddingLeft: "20px" }}>
          <ScheduleComponent
            height="650px"
            selectedDate={selectedDate}
            eventSettings={eventSettings}
            popupOpen={onPopupOpen}
          >
            <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
          </ScheduleComponent>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            zIndex: 1000,
          }}
        >
          <h4>Add Event Title</h4>
          <input
            type="text"
            value={newEventTitle}
            onChange={(e) => setNewEventTitle(e.target.value)}
            placeholder="Enter event title"
            style={{
              marginBottom: "10px",
              padding: "10px",
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              onClick={handleAddEvent}
              style={{
                padding: "10px 20px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Add Event
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              style={{
                padding: "10px 20px",
                backgroundColor: "#f44336",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdvancedScheduler;
