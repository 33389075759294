import React, { useState } from 'react';
import "../Edit-Profile/profile.css";
import Header from '../Header/Header';
import { FaHome, FaStar, FaUser, FaCog, FaQuestionCircle, FaHeadset, FaSignOutAlt } from 'react-icons/fa';
import Profile_Home from './Profile_Home';
import Profile_Favorites from './Profile_Favorites';
import Profile_Register from './Profile_Register';
import Profile_FAQ from './Profile_FAQ';
import Profile_Settings from './Profile_Settings';
import Profile_Support from './Profile_Support';




const Edit_profile_main = () => {
    const [activeTab, setActiveTab] = useState('home');

    const renderContent = () => {
        switch (activeTab) {
            case 'home':
                return <Profile_Home/>;
            case 'profile_favorites':
                return <Profile_Favorites />;
            case 'register':
                return <Profile_Register />;
            case 'settings':
                return <Profile_Settings />;
            case 'faq':
                return <Profile_FAQ />;
            case 'support':
                return <Profile_Support />;
            default:
                return <Profile_Home />;
        }
    };

    return (
        <>
            <div>
                <Header />
            </div>

            <div className='profile-main'>
                <div className='profile-left-side'>
                    <div className='sidebar'>
                        <div className={`sidebar-item ${activeTab === 'home' ? 'active' : ''}`} onClick={() => setActiveTab('home')}>
                            <FaHome className='sidebar-icon' />
                            <span className='sidebar-text'>Home</span>
                        </div>
                        <div className={`sidebar-item ${activeTab === 'profile_favorites' ? 'active' : ''}`} onClick={() => setActiveTab('profile_favorites')}>
                            <FaStar className='sidebar-icon' />
                            <span className='sidebar-text'>Favorites</span>
                        </div>
                        <div className={`sidebar-item ${activeTab === 'register' ? 'active' : ''}`} onClick={() => setActiveTab('register')}>
                            <FaUser className='sidebar-icon' />
                            <span className='sidebar-text'>Register your Zaanvar</span>
                        </div>
                        <div  className={`sidebar-item ${activeTab === 'settings' ? 'active' : ''}`} onClick={() => setActiveTab('settings')}>
                            <FaCog className='sidebar-icon' />
                            <span className='sidebar-text'>Settings</span>
                        </div>
                        <div  className={`sidebar-item ${activeTab === 'faq' ? 'active' : ''}`} onClick={() => setActiveTab('faq')}>
                            <FaQuestionCircle className='sidebar-icon' />
                            <span className='sidebar-text'>FAQ</span>
                        </div>
                        <div  className={`sidebar-item ${activeTab === 'support' ? 'active' : ''}`} onClick={() => setActiveTab('support')}>
                            <FaHeadset className='sidebar-icon' />
                            <span className='sidebar-text'>Support</span>
                        </div>
                    </div>
                    <button className='logout-btn'>
                        <FaSignOutAlt className='logout-icon' /> Log Out
                    </button>
                </div>

                <div className='profile-right-side'>
                    {renderContent()} 
                </div>
            </div>
        </>
    );
}

export default Edit_profile_main;
