import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const AppointmentDistribution = () => {
  const [options, setOptions] = useState(null);
  const [series, setSeries] = useState(null);

  useEffect(() => {
    const appointmentDistributionData = {
      series: [50, 30, 20],
      labels: ["Haircut", "Nail Trimming", "Bathing"],
    };

    setOptions({
      labels: appointmentDistributionData.labels,
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex];
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
      },
    });

    setSeries(appointmentDistributionData.series);
  }, []);

  return (
    <>
      <h6>Appointment Distribution</h6>
      {options && series && (
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          width={250}
          height={250}
        />
      )}
    </>
  );
};

export default AppointmentDistribution;
