import React from "react";

const SocialMediaPoll = () => {
  return (
    <>
      <div>
        <div className="socialmedia-poll-image-text-row">
          <img
            src="https://via.placeholder.com/150"
            alt="Example Image"
            className="socialmedia-poll-image"
          />
          <div className="socialmedia-poll-text">
            <span className="socialmedia-poll-heading">Manju Priya</span>
            <span className="socialmedia-poll-date">27 days ago</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMediaPoll;
