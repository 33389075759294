import React, { useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Image,
  useToast,
  Grid,
} from "@chakra-ui/react";
import * as Unicons from "@iconscout/react-unicons";

const UserProfilePage = () => {
  const [userProfile, setUserProfile] = useState({
    name: "John Doe",
    email: "john@example.com",
    phoneNumber: "123-456-7890",
    bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    address: "123 Main St, City, Country",
    age: 30,
    gender: "Male",
    occupation: "Developer",
    interests: "Reading, Hiking, Coding",
    education: "Bachelor's Degree",
    language: "English",
    nationality: "American",
    relationshipStatus: "Single",
    favoriteFood: "Pizza",
    favoriteMovie: "Inception",
    profilePicture: "https://via.placeholder.com/150",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
  const toast = useToast();

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    toast({
      title: "Profile Updated",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    if (selectedProfilePicture) {
      setUserProfile((prevProfile) => ({
        ...prevProfile,
        profilePicture: URL.createObjectURL(selectedProfilePicture),
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedProfilePicture(file);
    }
  };

  return (
    <Flex direction="column" alignItems="center" mt={8}>
      <Box w="100%" maxW="800px" p={4}>
        <Flex direction="column" alignItems="center" mb={6}>
          <label htmlFor="profile-picture-input">
            <Image
              src={userProfile.profilePicture}
              alt="Profile Picture"
              borderRadius="full"
              boxSize="150px"
              cursor="pointer"
              mb={4}
              border={"2px solid black"}
            />
          </label>
          <input
            id="profile-picture-input"
            type="file"
            accept="image/*"
            onChange={handleProfilePictureChange}
            style={{ display: "none" }}
          />
          {!isEditing ? (
            <Unicons.UilEdit
              className="d-inline mx-1"
              onClick={handleEditClick}
              mb={2}
            />
          ) : (
            <Unicons.UilSave
              className="d-inline mx-1"
              onClick={handleSaveClick}
              mb={2}
            />
          )}
        </Flex>
        <Grid
          templateColumns={{ base: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr" }}
          gap={6}
        >
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Name</FormLabel>
            <Input
              name="name"
              value={userProfile.name}
              onChange={handleInputChange}
              isDisabled={!isEditing}
            />
          </FormControl>
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Email</FormLabel>
            <Input name="email" value={userProfile.email} isDisabled={true} />
          </FormControl>
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Phone Number</FormLabel>
            <Input
              name="phoneNumber"
              value={userProfile.phoneNumber}
              isDisabled={true}
            />
          </FormControl>
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Bio</FormLabel>
            <Textarea
              name="bio"
              value={userProfile.bio}
              onChange={handleInputChange}
              isDisabled={!isEditing}
            />
          </FormControl>
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Address</FormLabel>
            <Input
              name="address"
              value={userProfile.address}
              onChange={handleInputChange}
              isDisabled={!isEditing}
            />
          </FormControl>
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Age</FormLabel>
            <Input
              type="number"
              name="age"
              value={userProfile.age}
              onChange={handleInputChange}
              isDisabled={!isEditing}
            />
          </FormControl>
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Gender</FormLabel>
            <Input
              name="gender"
              value={userProfile.gender}
              onChange={handleInputChange}
              isDisabled={!isEditing}
            />
          </FormControl>
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Occupation</FormLabel>
            <Input
              name="occupation"
              value={userProfile.occupation}
              onChange={handleInputChange}
              isDisabled={!isEditing}
            />
          </FormControl>
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Interests</FormLabel>
            <Input
              name="interests"
              value={userProfile.interests}
              onChange={handleInputChange}
              isDisabled={!isEditing}
            />
          </FormControl>
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Education</FormLabel>
            <Input
              name="education"
              value={userProfile.education}
              onChange={handleInputChange}
              isDisabled={!isEditing}
            />
          </FormControl>
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Language</FormLabel>
            <Input
              name="language"
              value={userProfile.language}
              onChange={handleInputChange}
              isDisabled={!isEditing}
            />
          </FormControl>
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Nationality</FormLabel>
            <Input
              name="nationality"
              value={userProfile.nationality}
              onChange={handleInputChange}
              isDisabled={!isEditing}
            />
          </FormControl>
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Relationship Status</FormLabel>
            <Input
              name="relationshipStatus"
              value={userProfile.relationshipStatus}
              onChange={handleInputChange}
              isDisabled={!isEditing}
            />
          </FormControl>
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Favorite Food</FormLabel>
            <Input
              name="favoriteFood"
              value={userProfile.favoriteFood}
              onChange={handleInputChange}
              isDisabled={!isEditing}
            />
          </FormControl>
          <FormControl gridColumn={{ base: "1", sm: "span 2", md: "span 1" }}>
            <FormLabel>Favorite Movie</FormLabel>
            <Input
              name="favoriteMovie"
              value={userProfile.favoriteMovie}
              onChange={handleInputChange}
              isDisabled={!isEditing}
            />
          </FormControl>
        </Grid>
      </Box>
    </Flex>
  );
};

export default UserProfilePage;
